import { IMapper } from '../../../../models/i-mapper';

export class UploadFileMapper implements IMapper {
  cellId = '';
  file = '';
  fileName = '';

  fillFromJson(_json: any): string {
    return 'File uploaded succesfully';
  }

  fillToJson(): string {
    return JSON.stringify({
      cellId: this.cellId,
      file: this.file,
      fileName: this.fileName,
    });
  }
}

export class RetrieveFileMapper implements IMapper {
  presignedUrl = '';

  fillFromJson(json: string): string {
    this.presignedUrl = json;
    return json;
  }

  fillToJson(): string {
    return '';
  }
}
