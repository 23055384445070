import { Injectable } from '@angular/core';
import { HierarchyTreeModel } from '../models/hierarchy-tree-model';
import { NetworkService } from 'src/app/core/net-utils/network.service';

@Injectable({
  providedIn: 'root',
})
export class HierarchyTreeService {
  networkService!: NetworkService;

  setNetworkService(networkService: NetworkService): void {
    this.networkService = networkService;
  }

  async getHierarchyData(offset: number): Promise<HierarchyTreeModel[]> {
    const result: HierarchyTreeModel[] = [];
    for (let i = 0; i < 20; i++) {
      result.push({
        id: `node-${offset + i}`,
        code: `CODE-${offset * i}`,
        selected: false,
        children: [
          {
            id: `node-${offset + i}-1`, 
            code: `CODE-${offset * i}`,
            selected: false,
          },
          {
            id: `node-${offset + i}-2`,
            code: `CODE-${offset * i}`,
            selected: false,
          },
        ],
      });
    }
    await new Promise((resolve) => setTimeout(resolve, 1000));
    return result;
  }
}
