import { IMapper } from '../../../models/i-mapper';
import { CodesListModel } from '../models/codes-model';

export class CodesListMapper implements IMapper {
  codesList: CodesListModel[] = [];
  fillFromJson(json: any): void {
    json.forEach((classList: any) => {
      this.codesList.push(classList);
    });
  }
  fillToJson(): string {
    return '';
  }
}

export class CodesCountMapper implements IMapper {
  tableDataCount = 0;
  fillFromJson(json: any): void {
    this.tableDataCount = json;
  }
  fillToJson(): string {
    return '';
  }
}

export class CodesDeleteMapper implements IMapper {
  codeID = '';
  value: any[] = [];

  fillFromJson(json: any): void {
    this.value.push(json);
  }

  fillToJson(): string {
    return JSON.stringify({
      codeID: this.codeID,
    });
  }
}

export class CodeEditMapper implements IMapper {
  codeID = '';
  code = '';
  description = '';
  definition = '';
  databaseId = '';
  unitId = '';
  value: any[] = [];

  fillFromJson(json: any): void {
    this.value.push(json);
  }

  fillToJson(): string {
    return JSON.stringify({
      codeID: this.codeID,
      code: this.code,
      description: this.description,
      definition: this.definition,
      databaseId: this.databaseId,
      unitId: this.unitId,
    });
  }
}

export class CodeCreateMapper implements IMapper {
  code = '';
  description = '';
  definition = '';
  databaseId = '';
  unitId = '';
  collectionId = '';
  value: any[] = [];

  fillFromJson(json: any): void {
    this.value.push(json);
  }

  fillToJson(): string {
    return JSON.stringify({
      code: this.code,
      description: this.description,
      definition: this.definition,
      databaseId: this.databaseId !== '' ? this.databaseId : undefined,
      unitId: this.unitId !== '' ? this.unitId : undefined,
      collectionId: this.collectionId !== '' ? this.collectionId : undefined,
    });
  }
}
