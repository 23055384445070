import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-autocomplete-select',
  templateUrl: './autocomplete-select.component.html',
  styleUrl: './autocomplete-select.component.scss',
})
export class AutocompleteSelectComponent {
  @Input()
  label!: string;
  @Input()
  multiple = false;
  @Input()
  inputFormControl!: FormControl;
  @Input()
  autocompleteFormControl!: FormControl;
  @Input()
  optionsData!: any;
  @Input()
  uniqueField!: string;
  @Input()
  visualizedField!: string;
  @Input()
  visualizedOptionFields!: string[];
  @Input()
  singleValue = false;
  @ViewChild('inputElement')
  inputElement!: ElementRef;

  onOpenedChange(isOpened: boolean): void {
    if (isOpened) {
      this.inputElement.nativeElement.focus();
    }
  }

  displayFn(value: any): string {
    if (!value) {
      return '';
    }
    const foundOption = this.optionsData.find(
      (option: any) => option[this.uniqueField] === value
    );
    return foundOption ? foundOption[this.visualizedField] || '' : '';
  }

  displayMultipleFn(value: any): string {
    let result = '';
    let index = 0;
    for (const v of value) {
      index++ === 0
        ? (result = `${this.displayFn(v)}`)
        : (result = `${result}, ${this.displayFn(v)}`);
    }
    return result;
  }

  visuallyFilter(option: any): boolean {
    const values = [];
    for (const visualizedOptionField of this.visualizedOptionFields) {
      values.push(option[visualizedOptionField]?.toLowerCase());
    }
    const input = (this.autocompleteFormControl.value || '').toLowerCase();
    return input === '' || values.some((value) => value.includes(input));
  }

  removeFilter(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    // this.regionsFormControl.setValue([], { emitEvent: false });
    // this.countriesFormControl.setValue([]);
    // this.countriesFromSelectedRegions = [];
  }
}
