import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';

@Directive({
  selector: '[appFormatNumberInput]',
  standalone: true,
})
export class FormatNumberInputDirective {
  @Input() numDigits = 2;
  @Output() dataChanged = new EventEmitter<number>();

  private el: any;
  private previousValue?: number;

  constructor(private elementRef: ElementRef) {
    this.el = this.elementRef.nativeElement;
  }

  @HostListener('focus', ['$event.target.value'])
  onFocus(): void {
    this.el.value = this.parse(this.el.value);
  }

  @HostListener('blur', ['$event.target.value'])
  onBlur(value: any): void {
    if (value !== this.previousValue) {
      this.dataChanged.emit(value);
      this.previousValue = value;
    }
    setTimeout(() => {
      this.el.value = this.transform(this.el.value);
    });
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.previousValue = this.el.value;
      this.el.value = this.transform(this.el.value);
    });
  }

  transform(value: any): string {
    if (!isNaN(value)) {
      if (value) {
        const cleanValue = value.toString().replace(/,/g, '');
        const fixedValue = parseFloat(cleanValue).toFixed(this.numDigits);
        const parts = fixedValue.split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return parts.join('.');
      } else {
        return '';
      }
    } else {
      return this.previousValue?.toString() ?? '';
    }
  }

  parse(value: any): string {
    if (value) {
      return value
        .toString()
        .replace(/,|\.00$/g, '')
        .trim();
    }
    return '';
  }
}
