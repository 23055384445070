import { Injectable } from '@angular/core';
import { NetworkService } from '../../../core/net-utils/network.service';
import { environment } from '../../../../environments/environment';
import { DownloadListMapper } from '../mappers/download-list-mapper';
import { DownloadListModel } from '../models/download-list-model';
import { PresignedUrlMapper } from '../mappers/presigned-url-mapper';

@Injectable({
  providedIn: 'root',
})
export class DownloadListService {
  baseUrl = `${environment.backend.endpoint}/download-module`;
  networkService?: NetworkService;
  setNetworkService(networkService: NetworkService): void {
    this.networkService = networkService;
  }

  async getDownloadList(): Promise<DownloadListModel[]> {
    const downloadListMapper = new DownloadListMapper();
    await this.networkService?.get(
      `${this.baseUrl}/list-downloads`,
      downloadListMapper
    );
    return downloadListMapper.downloadList;
  }

  async getPresignedUrl(fileName: string): Promise<string> {
    const presignedUrlMapper = new PresignedUrlMapper();
    await this.networkService?.get(
      `${this.baseUrl}/presigned-url`,
      presignedUrlMapper,
      { fileName }
    );
    return presignedUrlMapper.presignedUrl;
  }
}
