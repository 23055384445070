import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { ProviderService } from '../provider.service';
import { UserRoles } from '../../models/i-user';

@Injectable({
  providedIn: 'root',
})
export class RoleGuardService implements CanActivate {
  constructor(
    private router: Router,
    private providerService: ProviderService
  ) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    const path = state.url;
    const userRole = (await this.providerService.authService.getUser()).role;
    if (userRole === UserRoles.ADMIN) {
      return true;
    }
    if (userRole === UserRoles.INTERNAL) {
      if (
        path === '/app/datasets/country-view' ||
        path === '/app/datasets/household-view' ||
        path === '/app/home'
      ) {
        return true;
      } else {
        this.router.navigate(['app/home']);
        return false;
      }
    }
    if (userRole === UserRoles.EXTERNAL) {
      if (path === '/app/ict-prices' || path === '/app/home') {
        return true;
      } else {
        this.router.navigate(['app/home']);
        return false;
      }
    }
    return false;
  }
}
