import { Component } from '@angular/core';
import { SurveyModel } from '../survey-table/models/survey-model';
import { CountrySurveyModel } from '../survey-country-table/models/country-survey-model';

export enum CountryValidationSteps {
  selectSurvey,
  selectCountry,
  showValidationTable,
}

@Component({
  selector: 'app-country-validation',
  templateUrl: './country-validation.component.html',
  styleUrl: './country-validation.component.scss',
})
export class CountryValidationComponent {
  protected readonly CountryValidationSteps = CountryValidationSteps;
  currentStep: CountryValidationSteps = CountryValidationSteps.selectSurvey;
  selectedSurvey?: SurveyModel;
  selectedCountry?: CountrySurveyModel;

  selectSurvey(survey: SurveyModel): void {
    this.selectedSurvey = survey;
    this.currentStep = CountryValidationSteps.selectCountry;
  }

  selectCountry(country: CountrySurveyModel): void {
    this.selectedCountry = country;
    this.currentStep = CountryValidationSteps.showValidationTable;
  }

  goBackTo(step: CountryValidationSteps): void {
    this.currentStep = step;
  }
}
