import { IMapper } from '../../../models/i-mapper';
import { DatabasesListModel } from '../models/databases-model';

export class DatabasesListMapper implements IMapper {
  databasesList: DatabasesListModel[] = [];
  fillFromJson(json: any): void {
    json.forEach((classList: any) => {
      this.databasesList.push(classList);
    });
  }
  fillToJson(): string {
    return '';
  }
}
