<div class="form-container {{styleType}}" [formGroup]="filterFormGroup">
  @if(styleType === 'filters-bar') {
  <div class="mat-drop-down">
    <mat-label>{{'FILTERS.REGION_LABEL' | translate}}</mat-label>
    <mat-select [formControl]="regionsFormControl"
      (selectionChange)="filterFormGroup.controls.autocompleteRegion.setValue('')"
      (openedChange)="onOpenedChange($event)" multiple>
      <mat-select-trigger>
        <span class="label" [matTooltip]="displayMultipleFnRegions(regionsFormControl.value)">
          {{ displayFnRegions(regionsFormControl.value?.[0]) || '' }}
          @if ((regionsFormControl.value?.length || 0) > 1) {
          <span class="example-additional-selection">
            (+{{ (regionsFormControl.value?.length || 0) - 1 }} {{ regionsFormControl.value?.length === 2 ?
            ('FILTERS.OTHER' | translate) : ('FILTERS.OTHERS' | translate) }}
            )
          </span>
          }
        </span>
        @if ((regionsFormControl.value?.length || 0) > 0) {
        <i (click)="removeRegionFilter($event)" class="fa fa-x clear-filter"
          matTooltip="{{'FILTERS.CLEAR_FILTERS' | translate}}"></i>
        }
      </mat-select-trigger>
      <input matInput type="text" formControlName="autocompleteRegion" class="autocomplete-input"
        (keydown)="$event.stopPropagation()" #regionInputElement>
      @if (filterFormGroup.controls.autocompleteRegion.value === '') {
      <span class="autocomplete-placeholder {{styleType}}">{{ 'FILTERS.AUTOCOMPLETE_PLACEHOLDER' | translate }}</span>
      }
      <div class="select-deselect-all-container">
        <button color="primary" mat-raised-button type="button" class="select-deselect-buttons"
          (click)="deselectAllOptions('regions')">
          {{ 'FILTERS.DESELECT_ALL' | translate }}
        </button>
        <button color="primary" mat-raised-button type="button" class="select-deselect-buttons"
          (click)="selectAllOptions('regions')">
          {{ 'FILTERS.SELECT_ALL' | translate }}
        </button>
      </div>
      @for (region of regions; track region) {
      <mat-option [ngClass]="visuallyFilterRegions(region) ? '' : 'hidden'" [value]="region.regionID">
        {{ region.regionCode }} - {{ region.regionName }}
      </mat-option>
      }
    </mat-select>
  </div>
  <div class="mat-drop-down">
    <mat-label>{{'FILTERS.COUNTRY_LABEL' | translate}}</mat-label>
    <mat-select [formControl]="countriesFormControl"
      (selectionChange)="filterFormGroup.controls.autocompleteCountry.setValue('')"
      (openedChange)="onOpenedChange($event)" multiple>
      <mat-select-trigger>
        <span class="label" [matTooltip]="displayMultipleFnCountries(countriesFormControl.value)">
          {{ displayFnCountries(countriesFormControl.value?.[0]) || '' }}
          @if ((countriesFormControl.value?.length || 0) > 1) {
          <span class="example-additional-selection">
            (+{{ (countriesFormControl.value?.length || 0) - 1 }} {{ countriesFormControl.value?.length === 2 ?
            ('FILTERS.OTHER' | translate) : ('FILTERS.OTHERS' | translate) }}
            )
          </span>
          }
        </span>
        @if ((countriesFormControl.value?.length || 0) > 0) {
        <i (click)="removeCountryFilter($event)" class="fa fa-x clear-filter"
          matTooltip="{{'FILTERS.CLEAR_FILTERS' | translate}}"></i>
        }
      </mat-select-trigger>
      <input matInput type="text" formControlName="autocompleteCountry" class="autocomplete-input"
        (keydown)="$event.stopPropagation()" #inputElement>
      @if (filterFormGroup.controls.autocompleteCountry.value === '') {
      <span class="autocomplete-placeholder {{styleType}}">{{ 'FILTERS.AUTOCOMPLETE_PLACEHOLDER' | translate }}</span>
      }
      <div class="select-deselect-all-container">
        <button color="primary" mat-raised-button type="button" class="select-deselect-buttons"
          (click)="deselectAllOptions('countries')">
          {{ 'FILTERS.DESELECT_ALL' | translate }}
        </button>
        <button color="primary" mat-raised-button type="button" class="select-deselect-buttons"
          (click)="selectAllOptions('countries')">
          {{ 'FILTERS.SELECT_ALL' | translate }}
        </button>
      </div>
      @for (country of countriesFromSelectedRegions; track country) {
      <mat-option [ngClass]="visuallyFilterCountries(country) ? '' : 'hidden'" [value]="country">
        {{ country.isoCode }} - {{ country.shortName }}
      </mat-option>
      }
    </mat-select>
  </div>
  } @else {
  <mat-form-field appearance="fill">
    <mat-label>{{'FILTERS.REGION_LABEL' | translate}}</mat-label>
    <mat-select [formControl]="regionsFormControl"
      (selectionChange)="filterFormGroup.controls.autocompleteRegion.setValue('')"
      (openedChange)="onOpenedChange($event)" multiple>
      <mat-select-trigger>
        <span class="label" [matTooltip]="displayMultipleFnRegions(regionsFormControl.value)">
          {{ displayFnRegions(regionsFormControl.value?.[0]) || '' }}
          @if ((regionsFormControl.value?.length || 0) > 1) {
          <span class="example-additional-selection">
            (+{{ (regionsFormControl.value?.length || 0) - 1 }} {{ regionsFormControl.value?.length === 2 ?
            ('FILTERS.OTHER' | translate) : ('FILTERS.OTHERS' | translate) }}
            )
          </span>
          }
        </span>
        @if ((regionsFormControl.value?.length || 0) > 0) {
        <i (click)="removeRegionFilter($event)" class="fa fa-x clear-filter"
          matTooltip="{{'FILTERS.CLEAR_FILTERS' | translate}}"></i>
        }
      </mat-select-trigger>
      <input matInput type="text" formControlName="autocompleteRegion" class="autocomplete-input"
        (keydown)="$event.stopPropagation()" #regionInputElement>
      @if (filterFormGroup.controls.autocompleteRegion.value === '') {
      <span class="autocomplete-placeholder {{styleType}}">{{ 'FILTERS.AUTOCOMPLETE_PLACEHOLDER' | translate }}</span>
      }
      <div class="select-deselect-all-container">
        <button color="primary" mat-raised-button type="button" class="select-deselect-buttons"
          (click)="deselectAllOptions('regions')">
          {{ 'FILTERS.DESELECT_ALL' | translate }}
        </button>
        <button color="primary" mat-raised-button type="button" class="select-deselect-buttons"
          (click)="selectAllOptions('regions')">
          {{ 'FILTERS.SELECT_ALL' | translate }}
        </button>
      </div>
      @for (region of regions; track region) {
      <mat-option [ngClass]="visuallyFilterRegions(region) ? '' : 'hidden'" [value]="region.regionID">
        {{ region.regionCode }} - {{ region.regionName }}
      </mat-option>
      }
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>{{'FILTERS.COUNTRY_LABEL' | translate}}</mat-label>
    <mat-select [formControl]="countriesFormControl"
      (selectionChange)="filterFormGroup.controls.autocompleteCountry.setValue('')"
      (openedChange)="onOpenedChange($event)" multiple>
      <mat-select-trigger>
        <span class="label" [matTooltip]="displayMultipleFnCountries(countriesFormControl.value)">
          {{ displayFnCountries(countriesFormControl.value?.[0]) || '' }}
          @if ((countriesFormControl.value?.length || 0) > 1) {
          <span class="example-additional-selection">
            (+{{ (countriesFormControl.value?.length || 0) - 1 }} {{ countriesFormControl.value?.length === 2 ?
            ('FILTERS.OTHER' | translate) : ('FILTERS.OTHERS' | translate) }}
            )
          </span>
          }
        </span>
        @if ((countriesFormControl.value?.length || 0) > 0) {
        <i (click)="removeCountryFilter($event)" class="fa fa-x clear-filter"
          matTooltip="{{'FILTERS.CLEAR_FILTERS' | translate}}"></i>
        }
      </mat-select-trigger>
      <input matInput type="text" formControlName="autocompleteCountry" class="autocomplete-input"
        (keydown)="$event.stopPropagation()" #inputElement>
      @if (filterFormGroup.controls.autocompleteCountry.value === '') {
      <span class="autocomplete-placeholder {{styleType}}">{{ 'FILTERS.AUTOCOMPLETE_PLACEHOLDER' | translate }}</span>
      }
      <div class="select-deselect-all-container">
        <button color="primary" mat-raised-button type="button" class="select-deselect-buttons"
          (click)="deselectAllOptions('countries')">
          {{ 'FILTERS.DESELECT_ALL' | translate }}
        </button>
        <button color="primary" mat-raised-button type="button" class="select-deselect-buttons"
          (click)="selectAllOptions('countries')">
          {{ 'FILTERS.SELECT_ALL' | translate }}
        </button>
      </div>
      @for (country of countriesFromSelectedRegions; track country) {
      <mat-option [ngClass]="visuallyFilterCountries(country) ? '' : 'hidden'" [value]="country">
        {{ country.isoCode }} - {{ country.shortName }}
      </mat-option>
      }
    </mat-select>
  </mat-form-field>
  }
</div>