<div class="container">
  @if (loading) {
    <app-loader></app-loader>
  }
  @if (!detailOpen) {
    <div class="filters-class">
      <ng-container>
        <div class="filters-bar" [formGroup]="codeFiltersFormGroup">
          <div class="first-column">
            <span class="mat-drop-down">
              <mat-label>Code: </mat-label>
              <input matInput type="text" formControlName="code">
            </span>
            <span class="mat-drop-down">
              <mat-label>Description: </mat-label>
              <input matInput type="text" formControlName="description">
            </span>
            <span class="mat-drop-down">
              <mat-label>Unit: </mat-label>
              <mat-form-field>
                <mat-select class="multiple" id="css-fix-1" #cssfix1 [formControl]="codeFiltersFormGroup.controls.unit"
                            (openedChange)="onOpenedUnitChange($event)" multiple >
                  <mat-select-trigger>
                    <span class="label" [matTooltip]="displayMultipleUnitsFn(codeFiltersFormGroup.controls.unit.value)">
                      {{displayUnitFn(codeFiltersFormGroup.controls.unit.value?.[0]) || ''}}
                      @if ((codeFiltersFormGroup.controls.unit.value?.length || 0) > 1) {
                        <span class="example-additional-selection">
                          (+{{(codeFiltersFormGroup.controls.unit.value?.length || 0) - 1}} {{codeFiltersFormGroup.controls.unit.value?.length === 2 ? 'other' : 'others'}})
                        </span>
                      }
                    </span>
                    @if ((codeFiltersFormGroup.controls.unit.value?.length || 0) > 0) {
                      <i (click)="removeUnitFilter($event)" class="fa fa-x clear-filter"
                         [matTooltip]="'Clear filter'"></i>
                    }
                  </mat-select-trigger>
                  <input matInput type="text" formControlName="autocompleteUnit"
                         class="autocomplete-input"
                         (keydown)="$event.stopPropagation()"
                         #unitInputElement>
                  @if (codeFiltersFormGroup.controls.autocompleteUnit.value === '') {
                    <span class="autocomplete-placeholder">Search...</span>
                  }
                  @for (unit of unitsList; track unit) {
                    <mat-option [ngClass]="visuallyFilterUnits(unit) ? '' : 'hidden'" [value]="unit.unitID">
                      {{ unit.unitDescription }}
                    </mat-option>
                  }
                </mat-select>
              </mat-form-field>
            </span>
            <span class="mat-drop-down">
              <mat-label>Database: </mat-label>
              <mat-form-field>
                <mat-select class="multiple" id="css-fix-2" #cssfix2 [formControl]="codeFiltersFormGroup.controls.database"
                            (openedChange)="onOpenedDatabaseChange($event)" multiple >
                  <mat-select-trigger>
                    <span class="label" [matTooltip]="displayMultipleDatabasesFn(codeFiltersFormGroup.controls.database.value)">
                      {{displayDatabaseFn(codeFiltersFormGroup.controls.database.value?.[0]) || ''}}
                      @if ((codeFiltersFormGroup.controls.database.value?.length || 0) > 1) {
                        <span class="example-additional-selection">
                          (+{{(codeFiltersFormGroup.controls.database.value?.length || 0) - 1}} {{codeFiltersFormGroup.controls.database.value?.length === 2 ? 'other' : 'others'}})
                        </span>
                      }
                    </span>
                    @if ((codeFiltersFormGroup.controls.database.value?.length || 0) > 0) {
                      <i (click)="removeDatabaseFilter($event)" class="fa fa-x clear-filter"
                         [matTooltip]="'Clear filter'"></i>
                    }
                  </mat-select-trigger>
                  <input matInput type="text" formControlName="autocompleteDatabase"
                         class="autocomplete-input"
                         (keydown)="$event.stopPropagation()"
                         #databaseInputElement>
                  @if (codeFiltersFormGroup.controls.autocompleteDatabase.value === '') {
                    <span class="autocomplete-placeholder">Search...</span>
                  }
                  @for (db of databasesList; track db) {
                    <mat-option [ngClass]="visuallyFilterDatabases(db) ? '' : 'hidden'" [value]="db.databaseID">
                      {{ db.databaseName }}
                    </mat-option>
                  }
                </mat-select>
              </mat-form-field>
            </span>
            <span class="mat-drop-down last-item">
            <div class="button-container">
              <button mat-raised-button color="primary" type="button" class="create-codes-button"
                      onclick="this.blur()" (click)="openCreateCodeDialog(createCodeDialog)">
                <span>Create Code</span>
                <i class="fa fa-plus"></i>
              </button>
            </div>
          </span>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="table-holder mat-elevation-z8" tabindex="0">
      <table mat-table matSort [dataSource]="tableDataSource">
        <ng-container matColumnDef="code">
          <th mat-header-cell *matHeaderCellDef appResizeColumn>
            <div mat-sort-header>Code</div>
          </th>
          <td mat-cell *matCellDef="let element" (click)="openDetail(element.codeID)">
            <span class="row-line">{{ element.code }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef appResizeColumn>
            <div mat-sort-header>Description</div>
          </th>
          <td mat-cell *matCellDef="let element" (click)="openDetail(element.codeID)">
            <span class="row-line">{{ element.description }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="unit">
          <th mat-header-cell *matHeaderCellDef appResizeColumn>
            <div mat-sort-header>Unit</div>
          </th>
          <td mat-cell *matCellDef="let element" (click)="openDetail(element.codeID)">
            <span class="row-line">{{ element.unit || '-' }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="database">
          <th mat-header-cell *matHeaderCellDef appResizeColumn>
            <div mat-sort-header>Database</div>
          </th>
          <td mat-cell *matCellDef="let element" (click)="openDetail(element.regionID)">
            <span class="row-line">{{ element.database || '-' }}</span>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="mat-header-row"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="table-row"></tr>
      </table>
    </div>
    <div class="paginator">
      <mat-paginator class="mat-elevation-z8 hide-ripple"
                     [length]="tableDataCount"
                     [pageSizeOptions]="[25, 50, 100, 150]">
      </mat-paginator>
    </div>
    @if (showTableError && !loading) {
      <div class="no-data-found">No data found</div>
    }
  } @else if (detailOpen) {
    <div class="code-details-container">
      <mat-card class="code-details-table">
        <mat-card-header>{{ selectedCode?.code }} - Details</mat-card-header>
        <app-code-detail [code]="selectedCode"
                         [units]="unitsList"
                         [databases]="databasesList"
                         [collections]="collectionsList">
        </app-code-detail>
      </mat-card>
      <div class="action-buttons dual-button" style="width: 100%">
        <button mat-raised-button color="secondary" style="width: 200px"
                onclick="this.blur()" (click)="goBack()">Back</button>
        <button mat-raised-button color="primary" style="width: 200px"
                onclick="this.blur()" (click)="openEditCodeDialog(editCodeDialog)">
          <i class="fa fa-pencil" style="padding-left: 0"></i>
          Edit
        </button>
<!--        <button mat-raised-button color="primary" style="width: 200px"-->
<!--                onclick="this.blur()" (click)="openDeleteCodeDialog(deleteCodeDialog)">-->
<!--          <i class="fa fa-trash" style="padding-left: 0"></i>-->
<!--          Delete-->
<!--        </button>-->
      </div>
    </div>
  }
</div>

<ng-template #editCodeDialog>
  <div class="container-dialog-select-columns">
    <h2 mat-dialog-title class="mat-dialog-title">Edit code</h2>
    <mat-dialog-content [formGroup]="editCodeFormGroup">
      <mat-form-field appearance="fill">
        <mat-label>Code</mat-label>
        <input matInput type="text" formControlName="code">
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Description</mat-label>
        <input matInput type="text" formControlName="description">
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Definition</mat-label>
        <input matInput type="text" formControlName="definition">
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Unit: </mat-label>
        <mat-select [formControl]="editCodeFormGroup.controls.unit"
                    (openedChange)="onOpenedUnitEditChange($event)">
          <mat-select-trigger>
            <span class="label">
              {{displayUnitFn(editCodeFormGroup.controls.unit.value) || ''}}
            </span>
            @if (editCodeFormGroup.controls.unit.value !== '') {
              <i (click)="removeUnitEditFilter($event)" class="fa fa-x clear-filter clear-filter-new"
                 [matTooltip]="'Clear filter'"></i>
            }
          </mat-select-trigger>
          <input matInput type="text" formControlName="autocompleteUnit"
                 class="autocomplete-input"
                 (keydown)="$event.stopPropagation()"
                 #unitEditInputElement>
          @if (editCodeFormGroup.controls.autocompleteUnit.value === '') {
            <span class="autocomplete-placeholder">Search...</span>
          }
          @for (unit of unitsList; track unit) {
            <mat-option [ngClass]="visuallyFilterEditUnits(unit) ? '' : 'hidden'" [value]="unit.unitID">
              {{ unit.unitDescription }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Database: </mat-label>
        <mat-select [formControl]="editCodeFormGroup.controls.database"
                    (openedChange)="onOpenedDatabaseEditChange($event)">
          <mat-select-trigger>
            <span class="label">
              {{displayDatabaseFn(editCodeFormGroup.controls.database.value) || ''}}
            </span>
            @if (editCodeFormGroup.controls.database.value !== '') {
              <i (click)="removeDatabaseEditFilter($event)" class="fa fa-x clear-filter clear-filter-new"
                 [matTooltip]="'Clear filter'"></i>
            }
          </mat-select-trigger>
          <input matInput type="text" formControlName="autocompleteDatabase"
                 class="autocomplete-input"
                 (keydown)="$event.stopPropagation()"
                 #databaseEditInputElement>
          @if (editCodeFormGroup.controls.autocompleteDatabase.value === '') {
            <span class="autocomplete-placeholder">Search...</span>
          }
          @for (db of databasesList; track db) {
            <mat-option [ngClass]="visuallyFilterEditDatabases(db) ? '' : 'hidden'" [value]="db.databaseID">
              {{ db.databaseDescription }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
<!--      <mat-form-field appearance="fill">-->
<!--        <mat-label>Basecode</mat-label>-->
<!--        <input matInput type="text" formControlName="baseCode">-->
<!--      </mat-form-field>-->
    </mat-dialog-content>
  </div>
  <mat-dialog-actions>
    <button mat-raised-button mat-dialog-close >{{ 'DIALOG.CANCEL' | translate }}</button>
    <button mat-raised-button color="primary" style="margin-left: auto"
            (click)="editCode()">
      Edit
    </button>
  </mat-dialog-actions>
</ng-template>

<ng-template #deleteCodeDialog>
  <div class="container-dialog-select-columns">
    <mat-dialog-content>
      Are you sure you want to delete this code?
    </mat-dialog-content>
  </div>
  <mat-dialog-actions>
    <button mat-raised-button mat-dialog-close>{{ 'DIALOG.CANCEL' | translate }}</button>
    <button class="confirm-deletion-button" mat-raised-button
            (click)="deleteCode()">
      <i class="fa fa-trash"></i>
      Delete
    </button>
  </mat-dialog-actions>
</ng-template>


<ng-template #createCodeDialog>
  <div class="container-dialog-select-columns">
    <h2 mat-dialog-title class="mat-dialog-title">Insert Code details</h2>
    <mat-dialog-content [formGroup]="createCodeFormGroup">
      <mat-form-field appearance="fill">
        <mat-label>Code</mat-label>
        <input matInput type="text" formControlName="code">
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Description</mat-label>
        <input matInput type="text" formControlName="description">
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Definition</mat-label>
        <input matInput type="text" formControlName="definition">
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Database</mat-label>
        <mat-select formControlName="database">
          <mat-option *ngFor="let db of databasesList" [value]="db.databaseID">
            {{ db.databaseName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Unit</mat-label>
        <mat-select formControlName="unit">
          <mat-option *ngFor="let unit of unitsList" [value]="unit.unitID">
            {{ unit.unitDescription }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Collection</mat-label>
        <mat-select formControlName="collection">
          <mat-option *ngFor="let collection of collectionsList" [value]="collection.collectionID">
            {{ collection.collectionName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button mat-raised-button mat-dialog-close (click)="reset()">{{ 'DIALOG.CANCEL' | translate }}</button>
      <button class="select-columns-button" mat-raised-button color="primary"
              (click)="createCode()" [disabled]="!createCodeFormGroup.valid">
        Create
      </button>
    </mat-dialog-actions>
  </div>
</ng-template>
