<div class="download-page-container">

  @if(selectedDownloadViewType != undefined) {
    <button class="back-button" mat-raised-button (click)="selectView(undefined)">
      <i class="fa fa-arrow-left"></i>
      <span>Back</span>
    </button>
  }

  @if (!selectedDownloadViewType) {
    <div class="select-view-container">
      <mat-card-title class="action-message">Select View</mat-card-title>
      <div class="select-view-cards-container">
        <mat-card (click)="selectView(eDownloadViewType.DownloadList)">
          <i class="fa-solid fa-list"></i>
          <mat-card-title>Download list</mat-card-title>
        </mat-card>
        <mat-card (click)="selectView(eDownloadViewType.CountryView)">
          <i class="fa fa-earth-america"></i>
          <mat-card-title>Country View</mat-card-title>
        </mat-card>
        <mat-card (click)="selectView(eDownloadViewType.HouseholdView)">
          <i class="fa fa-house-chimney-window"></i>
          <mat-card-title>Household View</mat-card-title>
        </mat-card>
      </div>
    </div>
  }

  @if (selectedDownloadViewType === eDownloadViewType.CountryView) {
    <app-country-view-download></app-country-view-download>
  }
  @else if(selectedDownloadViewType === eDownloadViewType.HouseholdView) {
    <mat-card>
      <div style="text-align: center; margin: 10px 10px">Under costruction...</div>
    </mat-card>
  } @else if(selectedDownloadViewType === eDownloadViewType.DownloadList) {
    <app-download-list></app-download-list>
  }

</div>
