import { IMapper } from '../../../models/i-mapper';
import { DownloadListModel } from '../models/download-list-model';

export class DownloadListMapper implements IMapper {
  downloadList: DownloadListModel[] = [];
  fillFromJson(json: any): void {
    this.downloadList = [];
    json.forEach((download: any) => {
      this.downloadList.push({
        createdAt: download.createdAt,
        filePath: download.filePath,
        status: download.status,
        errorMessage: download.errorMessage,
      });
    });
  }
  fillToJson(): string {
    return '';
  }
}
