@if (loading) {
<app-loader></app-loader>
}
<div class="validation-module-container">
  <div class="left-side">
    <app-hierarchy-tree-view (codeSelectionChanged)="setSelectedCodes($event)"></app-hierarchy-tree-view>
  </div>
  <div class="right-side">
    <div class="filters-class">
      <div class="filters-bar" [formGroup]="filterFormGroup">
        <div class="first-column">
          <div class="mat-drop-down">
            <mat-label>{{ 'VALIDATION_MODULE_TABLE.PERCENTAGE_CHANGE' | translate }}</mat-label>
            <input class="short-number-input" (input)="enforceMinMax($event)" type="number" min="0" max="200"
              formControlName="percentageChange">
          </div>
          <div class="mat-drop-down">
            <mat-label>{{ 'VALIDATION_MODULE_TABLE.YEAR_FROM' | translate }}</mat-label>
            <input matInput type="number" formControlName="yearFrom">
          </div>
          <div class="mat-drop-down">
            <mat-label>{{ 'VALIDATION_MODULE_TABLE.YEAR_TO' | translate }}</mat-label>
            <input matInput type="number" formControlName="yearTo">
          </div>
          <div class="mat-drop-down">
            <app-region-country-filter (countriesChanged)="setSelectedCountries($event)"
              styleType="filters-bar"></app-region-country-filter>
          </div>
        </div>
      </div>
    </div>
    <div class="table-holder mat-elevation-z8" tabindex="0">
      <table mat-table matSort [dataSource]="dataSource">
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)">
            </mat-checkbox>
          </td>
        </ng-container>
        <ng-container matColumnDef="codeName">
          <th mat-header-cell *matHeaderCellDef appResizeColumn>
            <div mat-sort-header>{{ 'VALIDATION_MODULE_TABLE.CODE_NAME_HEADER' | translate }}</div>
          </th>
          <td mat-cell *matCellDef="let element">
            <span class="row-line">
              {{ element.codeName }}
            </span>
          </td>
        </ng-container>
        @for (year of yearsToDisplay; track year) {
        <ng-container [matColumnDef]="year">
          <th mat-header-cell *matHeaderCellDef appResizeColumn>
            <div>{{ year }}</div>
          </th>
          <td mat-cell *matCellDef="let element" class="input-cell">
            <div class="input-container">
              <input matInput type="text" appFormatNumberInput class="form-control" [value]="element.values[year]"
                (dataChanged)="setCellValue(element, year, $event)" />
            </div>
          </td>
        </ng-container>
        }
        @for (year of yearsToDisplay; track year) {
        <ng-container [matColumnDef]="year + ' %'">
          <th mat-header-cell *matHeaderCellDef appResizeColumn>
            <div>{{ year + ' %' }}</div>
          </th>
          <td mat-cell *matCellDef="let element" class="input-cell">
            <span class="row-line">
              {{ element.percentages[year] }}
            </span>
          </td>
        </ng-container>
        }
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="mat-header-row"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="table-row"></tr>

        <ng-container matColumnDef="countryHeader">
          <td mat-cell *matCellDef="let country">
            <div class="country-row-cell">
              @if(country.expanded) {
              <mat-icon>expand_less</mat-icon>
              } @else {
              <mat-icon>expand_more</mat-icon>
              }
              <span> {{country.countryName}} </span>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <td mat-cell colspan="999" *matCellDef="let country" class="actions-td">
            @if(country.toStart) {
              <button mat-raised-button color="primary" class="start-survey-button">{{
              'VALIDATION_MODULE_TABLE.START_SURVEY' | translate }}</button> 
            } @else {
              <button mat-raised-button color="primary" class="complete-survey-button">{{
              'VALIDATION_MODULE_TABLE.COMPLETE_SURVEY' | translate }}</button>
            }
          </td>
        </ng-container>

        <tr mat-row *matRowDef="let row; columns: ['countryHeader', 'action']; when isCountry;"
          (click)="groupHeaderClick(row)"> </tr>
      </table>
    </div>
    <div class="paginator">
      <button mat-raised-button color="primary">{{ 'VALIDATION_MODULE_TABLE.UPLOAD_DATA' | translate }}</button>
      <mat-paginator class="hide-ripple" [length]="tableCount" [pageSizeOptions]="[50, 100, 150]">
      </mat-paginator>
    </div>
  </div>
</div>