import { Component } from '@angular/core';
import { SurveyModel } from '../survey-table/models/survey-model';

export enum ValidationModuleSteps {
  selectSurvey,
  showValidationTable,
}

@Component({
  selector: 'app-validation-module',
  templateUrl: './validation-module.component.html',
  styleUrls: ['./validation-module.component.scss'],
})
export class ValidationModuleComponent {
  protected readonly ValidationModuleSteps = ValidationModuleSteps;
  currentStep: ValidationModuleSteps = ValidationModuleSteps.selectSurvey;
  selectedSurvey?: SurveyModel;

  selectSurvey(survey: SurveyModel): void {
    this.selectedSurvey = survey;
    this.currentStep = ValidationModuleSteps.showValidationTable;
  }

  goBackTo(step: ValidationModuleSteps): void {
    this.currentStep = step;
  }
}
