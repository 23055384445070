import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ProviderService } from 'src/app/core/provider.service';
import { HierarchyTreeModel } from './models/hierarchy-tree-model';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { FormControl, FormGroup } from '@angular/forms';
import { AntiMemLeak } from 'src/app/core/form-utils/anti-mem-leak/anti-mem-leak';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { MatTree } from '@angular/material/tree';

@Component({
  selector: 'app-hierarchy-tree-view',
  templateUrl: './hierarchy-tree-view.component.html',
  styleUrls: ['./hierarchy-tree-view.component.scss'],
})
export class HierarchyTreeViewComponent extends AntiMemLeak implements OnInit {
  @Output() codeSelectionChanged: EventEmitter<string[]> = new EventEmitter<
    string[]
  >();

  @ViewChild(MatTree) tree!: MatTree<any, any>;

  dataSource?: any = [];
  loading = true;
  currentOffset = 0;

  formGroup = new FormGroup({
    codeFilter: new FormControl([] as string[]),
    code: new FormControl([] as string[]),
  });

  childrenAccessor = (node: HierarchyTreeModel): HierarchyTreeModel[] =>
    node.children ?? [];

  hasChild = (_: number, node: HierarchyTreeModel): boolean =>
    !!node.children && node.children.length > 0;

  constructor(private providerService: ProviderService) {
    super();
  }

  ngOnInit(): void {
    this.setDataSource();
    this.formGroup.controls.code.valueChanges
      .pipe(distinctUntilChanged(), debounceTime(500))
      .subscribe((value) => {
        if (value) this.codeSelectionChanged.emit(value);
      });
  }

  private async setDataSource(): Promise<void> {
    this.loading = true;
    try {
      this.dataSource.push(
        ...(await this.providerService.hierarchyTreeViewService.getHierarchyData(
          this.currentOffset
        ))
      );
      this.tree.renderNodeChanges(this.dataSource);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      this.loading = false;
    }
  }

  onNearEndScroll(): void {
    this.currentOffset += 1;
    this.setDataSource();
  }

  changeSelection(
    hierarchy: HierarchyTreeModel,
    $event: MatCheckboxChange
  ): void {
    hierarchy.selected = $event.checked;
    if (hierarchy.children) {
      hierarchy.children.forEach((child) => {
        child.selected = $event.checked;
      });
    }
    this.updateIndeterminate();
  }

  updateIndeterminate(): void {
    this.dataSource.forEach((hierarchy: HierarchyTreeModel) => {
      if (hierarchy.children) {
        const allChildrenSelected = hierarchy.children.every(
          (child) => child.selected
        );
        const someChildrenSelected = hierarchy.children.some(
          (child) => child.selected
        );
        hierarchy.selected = allChildrenSelected
          ? true
          : someChildrenSelected
          ? 'indeterminate'
          : false;
      }
    });
    this.formGroup.controls.code.setValue(
      this.getSelectedCodes(this.dataSource)
    );
  }

  private getSelectedCodes(hierarchies: HierarchyTreeModel[]): string[] {
    const selectedCodes: string[] = [];

    hierarchies.forEach((hierarchy) => {
      if (
        hierarchy.selected === true &&
        (!hierarchy.children || hierarchy.children.length === 0)
      ) {
        selectedCodes.push(hierarchy.code);
      }

      if (hierarchy.children) {
        selectedCodes.push(...this.getSelectedCodes(hierarchy.children));
      }
    });

    return selectedCodes;
  }
}
