<div class="validation-module-container">
  @if (currentStep === ValidationModuleSteps.selectSurvey) {
    <div class="select-container">
      <span class="step-label">{{ 'VALIDATION_MODULE.SELECT_SURVEY' | translate }}</span>
      <app-survey-table class="step-selection" (surveySelected)="this.selectSurvey($event)"></app-survey-table>
    </div>
  } @else if (currentStep === ValidationModuleSteps.showValidationTable) {
    <div class="select-container">
      <div class="back-button-container">
        <button mat-button mat-raised-button color="primary" (click)="goBackTo(ValidationModuleSteps.selectSurvey)"><i
          class="fa-solid fa-arrow-left"></i> {{ 'VALIDATION_MODULE.BACK' | translate }}
        </button>
      </div>
      <span
        class="step-label">{{ 'VALIDATION_MODULE.SURVEY' | translate }} {{ selectedSurvey?.surveyName }}</span>
        <app-validation-module-table class="step-selection" [selectedSurvey]="selectedSurvey!"></app-validation-module-table>
    </div>
  }
</div>
