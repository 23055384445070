import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatIconButton } from '@angular/material/button';

@Component({
  selector: 'app-custom-download-snackbar',
  standalone: true,
  imports: [TranslateModule, RouterLink, MatIconButton],
  templateUrl: './custom-download-snackbar.component.html',
  styleUrl: './custom-download-snackbar.component.scss',
})
export class CustomDownloadSnackbarComponent {
  constructor(public snackBar: MatSnackBar) {}

  dismissSnackbar(): void {
    this.snackBar.dismiss();
  }
}
