import { Injectable } from '@angular/core';
import { Amplify, Auth } from 'aws-amplify';
import { environment } from '../../../environments/environment';
import { IUser, UserRoles } from '../../models/i-user';
import { IAuthService } from '../../core/auth-utils/i-auth-service';

@Injectable({
  providedIn: 'root',
})
export class CognitoService extends IAuthService {
  private user = {} as IUser;
  constructor() {
    const config: any = {};
    config['Auth'] = environment.cognito;
    Amplify.configure(config);

    super();
  }

  signUp(user: IUser): Promise<any> {
    return Auth.signUp({
      username: user.name,
      password: user.password,
      attributes: {
        email: user.email,
      },
    });
  }

  confirmSignUp(user: IUser): Promise<any> {
    return Auth.confirmSignUp(user.email, user.code);
  }

  signIn(user: IUser): Promise<any> {
    return Auth.signIn(user.name || user.email, user.password).then(() => {
      this.authenticationSubject.next(true);
    });
  }

  signOut(): Promise<any> {
    return Auth.signOut().then(() => {
      this.authenticationSubject.next(false);
    });
  }

  isAuthenticated(): Promise<boolean> {
    if (this.authenticationSubject.value) {
      this.getUser().then((user: IUser) => {
        this.user = user;
      });
      return Promise.resolve(true);
    } else {
      this.user = {} as IUser;
      return this.getUser()
        .then((user: any) => {
          return !!user;
        })
        .catch(() => false);
    }
  }

  async getUser(): Promise<any> {
    this.user = {} as IUser;
    this.user = await Auth.currentUserInfo();
    const authenticatedUser = await Auth.currentAuthenticatedUser();
    const groups =
      authenticatedUser.signInUserSession.accessToken.payload['cognito:groups'];
    this.user.role = UserRoles.EXTERNAL;
    groups.forEach((group: string) => {
      if (
        group === environment.cognito.admingGroup &&
        (this.user.role === UserRoles.INTERNAL ||
          this.user.role === UserRoles.EXTERNAL)
      ) {
        this.user.role = UserRoles.ADMIN;
      } else if (
        group === environment.cognito.internalGroup &&
        this.user.role === UserRoles.EXTERNAL
      ) {
        this.user.role = UserRoles.INTERNAL;
      } else {
        this.user.role = UserRoles.EXTERNAL;
      }
    });
    return this.user;
  }

  async currentAuthenticatedUser(): Promise<any> {
    return Auth.currentAuthenticatedUser();
  }

  updateUser(user: IUser): Promise<any> {
    return Auth.currentUserPoolUser().then((cognitoUser: any) =>
      Auth.updateUserAttributes(cognitoUser, user)
    );
  }
}
