import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { SurveyModel } from '../survey-table/models/survey-model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { FormControl, FormGroup } from '@angular/forms';
import moment from 'moment';
import { AntiMemLeak } from 'src/app/core/form-utils/anti-mem-leak/anti-mem-leak';
import { debounceTime, distinctUntilChanged, startWith } from 'rxjs';
import { ProviderService } from 'src/app/core/provider.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import {
  ValidationModuleTableCodeData,
  ValidationModuleTableData,
} from './models/validation-module-table-model';
import { ValidationModuleModel } from './models/validation-module-model';
import { CountriesListsModel } from '../update-country-table/models/country-filters-model';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'app-validation-module-table',
  templateUrl: './validation-module-table.component.html',
  styleUrls: ['./validation-module-table.component.scss'],
})
// eslint-disable-next-line prettier/prettier
export class ValidationModuleTableComponent extends AntiMemLeak implements AfterViewInit {
  @Input({ required: true }) selectedSurvey!: SurveyModel;
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false })
  sort!: MatSort;

  loading = true;
  tableCount = 0;
  selectedCountries: string[] = [];
  selectedCodes: string[] = [];
  dataSource = new MatTableDataSource<
    ValidationModuleTableData | ValidationModuleTableCodeData
  >();
  displayedColumns?: string[];

  filterFormGroup = new FormGroup({
    percentageChange: new FormControl(50),
    yearFrom: new FormControl(moment().year() - 2),
    yearTo: new FormControl(moment().year()),
  });
  yearsToDisplay: string[] = [];
  selection = new SelectionModel<ValidationModuleTableCodeData>(true, []);
  expandedElement: ValidationModuleTableData | null = null;

  constructor(
    private providerService: ProviderService,
    private snackBar: MatSnackBar
  ) {
    super();
  }

  ngAfterViewInit(): void {
    this.subscriptions.add(
      this.filterFormGroup.controls.yearFrom.valueChanges
        .pipe(distinctUntilChanged(), debounceTime(500))
        .subscribe((_value) => {
          this.paginator.pageIndex = 0;
          this.setDisplayColumns();
          this.getTableData();
        })
    );
    this.subscriptions.add(
      this.filterFormGroup.controls.yearTo.valueChanges
        .pipe(distinctUntilChanged(), debounceTime(500))
        .subscribe((_value) => {
          this.paginator.pageIndex = 0;
          this.setDisplayColumns();
          this.getTableData();
        })
    );
    this.subscriptions.add(
      this.paginator.page.pipe(startWith(null)).subscribe((value) => {
        if (value) {
          this.getTableData();
        }
      })
    );
    this.setDisplayColumns();
    this.getTableData();
  }

  groupHeaderClick(item: any): void {
    item.expanded = !item.expanded;
    this.dataSource.filter = Date.now().toString();
  }

  setSelectedCodes(codes: string[]): void {
    this.selectedCodes = codes;
    this.getTableData();
  }

  setSelectedCountries(countries: CountriesListsModel[]): void {
    this.selectedCountries = countries.map((value) => value.isoCode);
    this.getTableData();
  }

  enforceMinMax(el: any): void {
    const srcElement = el.srcElement;
    if (srcElement.value != '') {
      if (parseInt(srcElement.value) < parseInt(srcElement.min)) {
        srcElement.value = srcElement.min;
      }
      if (parseInt(srcElement.value) > parseInt(srcElement.max)) {
        srcElement.value = srcElement.max;
      }
    }
  }

  setCellValue(
    element: ValidationModuleTableCodeData,
    year: string,
    $event: number
  ): void {
    if (element.dataIds[year]) {
      this.updateCellValue(element.dataIds[year] ?? '', $event);
    }
    element.values[year] = $event;
  }

  private async updateCellValue(cellID: string, value: number): Promise<void> {
    try {
      await this.providerService.validationModuleService.updateValidationModuleCell(
        cellID,
        value
      );
    } catch (e) {
      console.error(e);
      this.snackBar.open(
        'An error occured while updating the cell value',
        'X',
        {
          duration: 3000,
          panelClass: ['error-snackbar'],
        }
      );
    }
  }

  private setDisplayColumns(): void {
    const yearFrom = this.filterFormGroup.controls.yearFrom.value ?? 0;
    const yearTo = this.filterFormGroup.controls.yearTo.value ?? 0;

    this.yearsToDisplay = Array.from(
      { length: yearTo - yearFrom + 1 },
      (_, i) => (yearFrom + i).toString()
    );

    this.displayedColumns = [
      'select',
      'codeName',
      ...this.yearsToDisplay,
      ...this.yearsToDisplay.map((value) => value + ' %'),
    ];
  }

  private async getTableData(): Promise<void> {
    this.loading = true;
    try {
      const backendData =
        await this.providerService.validationModuleService.getValidationModuleTableListAndCount(
          this.selectedSurvey.surveyID,
          this.selectedCountries,
          this.selectedCodes,
          this.filterFormGroup.controls.yearFrom.value ?? moment().year() - 2,
          this.filterFormGroup.controls.yearTo.value ?? moment().year(),
          this.sort && this.sort.direction.toUpperCase() !== ''
            ? this.sort.active ?? 'code'
            : 'code',
          this.sort && this.sort.direction.toUpperCase() !== ''
            ? this.sort.direction.toUpperCase()
            : 'ASC',
          this.paginator.pageIndex,
          this.paginator.pageSize
        );
      const elaboratedData = this.convertToValidationModuleTableData(
        backendData.data
      );
      this.dataSource.data = elaboratedData;
      this.dataSource.filterPredicate = this.customFilterPredicate.bind(this);
      this.tableCount = backendData.total;
    } catch (e) {
      console.error(e);
      this.snackBar.open(
        'An error occured while retrieving the country list',
        'X',
        {
          duration: 3000,
          panelClass: ['error-snackbar'],
        }
      );
    } finally {
      this.loading = false;
    }
  }

  customFilterPredicate(data: any, _filter: string): boolean {
    if (data.countryISO) {
      return true;
    } else if (data.father.expanded) {
      return true;
    } else {
      return false;
    }
  }

  convertToValidationModuleTableData(
    response: ValidationModuleModel[]
  ): (ValidationModuleTableData | ValidationModuleTableCodeData)[] {
    const tableData: (
      | ValidationModuleTableData
      | ValidationModuleTableCodeData
    )[] = [];

    for (const data of response) {
      const country = {
        countryId: data.countryId,
        countryName: data.countryName,
        countryISO: data.countryISO,
        toStart: data.toStart,
        expanded: true,
      };
      tableData.push(country);
      for (const code of data.codes) {
        const values: { [key: string]: number | null } = {};
        const percentages: { [key: string]: number | null } = {};
        const dataIds: { [key: string]: string | null } = {};

        this.yearsToDisplay.forEach((yearKey) => {
          const yearData = code.years.find(
            (value) => value.year === Number(yearKey)
          );
          values[yearKey] = yearData?.value ? parseFloat(yearData.value) : null;
          percentages[yearKey] = yearData?.percentageChange
            ? parseFloat(yearData.percentageChange)
            : null;
          dataIds[yearKey] = yearData?.dataId ? yearData?.dataId : null;
        });

        tableData.push({
          father: country,
          countryId: data.countryId,
          codeid: code.codeId,
          codeName: code.codeName,
          values,
          percentages,
          dataIds,
        });
      }
    }

    return tableData;
  }

  isCountry(index: number, item: any): boolean {
    return item.countryISO;
  }
}
