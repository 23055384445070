import { Component, OnInit, ViewChild } from '@angular/core';
import { AntiMemLeak } from '../../core/form-utils/anti-mem-leak/anti-mem-leak';
import { ProviderService } from '../../core/provider.service';
import { MatTableDataSource } from '@angular/material/table';
import { DownloadListModel } from './models/download-list-model';
import { MatSnackBar } from '@angular/material/snack-bar';
import moment from 'moment';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-download-list',
  templateUrl: './download-list.component.html',
  styleUrl: './download-list.component.scss',
})
export class DownloadListComponent extends AntiMemLeak implements OnInit {
  dataSource: MatTableDataSource<DownloadListModel> =
    new MatTableDataSource<DownloadListModel>([]);
  displayedColumns = ['createdAt', 'filePath', 'status', 'downloadFile'];
  loading = true;
  @ViewChild(MatSort, { static: false })
  set sort(value: MatSort) {
    if (this.dataSource) {
      this.dataSource.sort = value;
    }
  }

  constructor(
    private providerService: ProviderService,
    private snackBar: MatSnackBar
  ) {
    super();
  }

  ngOnInit(): void {
    this.getTableData();
  }

  async getTableData(): Promise<void> {
    this.loading = true;
    try {
      this.dataSource.data =
        await this.providerService.downloadListService.getDownloadList();
    } catch (e: any) {
      console.error(e);
      if (e.includes('404')) {
        this.dataSource.data = [];
      } else {
        this.snackBar.open(
          'An error occured while retrieving the download list',
          'X',
          {
            duration: 3000,
            panelClass: ['error-snackbar'],
          }
        );
      }
    } finally {
      this.loading = false;
    }
  }

  async downloadFile(filePath: string): Promise<void> {
    this.loading = true;
    try {
      const presignedUrl =
        await this.providerService.downloadListService.getPresignedUrl(
          filePath
        );
      const link = document.createElement('a');
      link.href = presignedUrl;
      link.setAttribute(
        'download',
        filePath.startsWith('RawDownload')
          ? 'raw-download.csv'
          : 'years-as-columns.csv'
      );
      link.click();
      link.remove();
    } finally {
      this.loading = false;
    }
  }

  getFormattedDate(createdAt: number): string {
    return moment.unix(createdAt).format('DD-MMM-YYYY HH:mm:ss');
  }
}
