import { Injectable } from '@angular/core';
import { NetworkService } from '../../../../core/net-utils/network.service';
import { environment } from '../../../../../environments/environment';
import {
  RetrieveFileMapper,
  UploadFileMapper,
} from '../mapper/upload-file-mapper';

@Injectable({
  providedIn: 'root',
})
export class ManageDataService {
  private networkService!: NetworkService;
  baseUrlCountry = `${environment.backend.endpoint}/country-update`;
  baseUrlHousehold = `${environment.backend.endpoint}/household`;

  setNetworkService(networkService: NetworkService): void {
    this.networkService = networkService;
  }

  async uploadFile(
    cellID: string,
    file: string,
    fileName: string,
    table: string
  ): Promise<void> {
    const uploadFileMapper = new UploadFileMapper();
    uploadFileMapper.cellId = cellID;
    uploadFileMapper.file = file;
    uploadFileMapper.fileName = fileName;
    if (table === 'country-view') {
      await this.networkService?.post(
        `${this.baseUrlCountry}/upload-file`,
        uploadFileMapper,
        uploadFileMapper
      );
    } else if (table === 'household-view') {
      await this.networkService?.post(
        `${this.baseUrlHousehold}/upload-file`,
        uploadFileMapper,
        uploadFileMapper
      );
    }
  }

  async retrieveFile(cellId: string, table: string): Promise<any> {
    const retrieveMapper = new RetrieveFileMapper();
    if (table === 'country-view') {
      const response = await this.networkService?.get(
        `${this.baseUrlCountry}/retrieve-file`,
        retrieveMapper,
        {
          cellId,
        }
      );
      return response;
    } else if (table === 'household-view') {
      const response = await this.networkService?.get(
        `${this.baseUrlHousehold}/retrieve-file`,
        retrieveMapper,
        {
          cellId,
        }
      );
      return response;
    }
  }
}
