import { IMapper } from '../../../models/i-mapper';
import { UnitsListModel } from '../models/units-model';

export class UnitsListMapper implements IMapper {
  unitsList: UnitsListModel[] = [];
  fillFromJson(json: any): void {
    json.forEach((classList: any) => {
      this.unitsList.push(classList);
    });
  }
  fillToJson(): string {
    return '';
  }
}
