import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Directive({
  selector: '[appScrollNearEnd]',
  standalone: true,
})
export class ScrollNearEndDirective {
  @Input() thresholdPercent = 0.8;
  @Input() waitCheck = false;
  @Output() nearEnd = new EventEmitter<void>();
  private timeout: any;
  private isWaiting = false;

  constructor(private el: ElementRef) {
    this.el.nativeElement.addEventListener('scroll', this.onScroll.bind(this));
  }

  onScroll(event: any): void {
    if (this.waitCheck) {
      this.isWaiting = true;
      if (!this.timeout) {
        const threshold =
          (this.thresholdPercent * 100 * event.target.scrollHeight) / 100;
        const current = event.target.scrollTop + event.target.clientHeight;
        if (current > threshold) {
          this.nearEnd.emit();
          this.timeout = setTimeout(() => {
            this.isWaiting = false;
            clearTimeout(this.timeout);
            this.timeout = null;
          }, 1000);
        }
      } else if (!this.isWaiting) {
        const threshold =
          (this.thresholdPercent * 100 * event.target.scrollHeight) / 100;
        const current = event.target.scrollTop + event.target.clientHeight;
        if (current > threshold) {
          this.nearEnd.emit();
        }
      }
    } else {
      const threshold =
        (this.thresholdPercent * 100 * event.target.scrollHeight) / 100;
      const current = event.target.scrollTop + event.target.clientHeight;
      if (current > threshold) {
        this.nearEnd.emit();
      }
    }
  }
}
