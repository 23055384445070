@if(loading) {
<app-loader></app-loader>
}
<div [formGroup]="formGroup" class="autocomplete-form">
  <input formControlName="codeFilter" placeholder="{{'HIERARCHY_TREE.AUTOCOMPLETE_PLACEHOLDER' | translate}}" />
</div>
<div class="mat-tree-container" appScrollNearEnd [waitCheck]="true" [thresholdPercent]="0.95" (nearEnd)="onNearEndScroll()">
  <mat-tree #tree [dataSource]="dataSource" [childrenAccessor]="childrenAccessor">
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
      <button mat-icon-button disabled></button>
      <mat-checkbox [checked]="node.selected === true" (change)="changeSelection(node, $event)"></mat-checkbox>
      {{node.code}}
    </mat-tree-node>
    <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding matTreeNodeToggle
      [cdkTreeNodeTypeaheadLabel]="node.code">
      <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.code">
        <mat-icon class="mat-icon-rtl-mirror">
          {{tree.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
        </mat-icon>
      </button>
      <mat-checkbox (click)="$event.stopPropagation()" [checked]="node.selected === true"
        [indeterminate]="node.selected === 'indeterminate'" (change)="changeSelection(node, $event)"></mat-checkbox>
      {{node.code}}
    </mat-tree-node>
  </mat-tree>
</div>