<div class="import">
  <input #fileInput hidden type="file" onclick="this.value=null" (change)="onFileSelected($event)" class="file-upload">
  <button mat-raised-button [disabled]="!cell || uploading" (click)="selectFile(fileInput)">
    @if (!uploading) {
      <span>Import</span>
    } @else {
      <i class="fa-sharp-duotone fa-solid fa-spinner-third spinning-animation"></i>
    }
  </button>
  <div matTooltip="No file found for the selected data point" [matTooltipDisabled]="!cell || downloading || disabled || cellInfo?.hasFile">
    <button mat-raised-button [disabled]="!cell || downloading || disabled || !cellInfo?.hasFile" (click)="retrieveFile()">
      @if (downloading || disabled) {
        <i class="fa-sharp-duotone fa-solid fa-spinner-third spinning-animation"></i>
      } @else {
        <span>Retrieve</span>
      }
    </button>
  </div>
</div>
