<div class="code-detail-section">
  Code definition
</div>

<div class="code-detail-section-content">
  <div class="code-detail">
    <div class="code-detail-name">Code:</div>
    <div class="code-detail-value">{{code?.code}}</div>
  </div>
  <div class="code-detail">
    <div class="code-detail-name">Description:</div>
    <div class="code-detail-value">{{code?.description}}</div>
  </div>
  <div class="code-detail">
    <div class="code-detail-name">Definition:</div>
    <div class="code-detail-value">{{code?.definition || '-'}}</div>
  </div>
</div>

<div class="code-detail-section top-border">
  Code references
</div>

<div class="code-detail-section-content">
  <div class="code-detail">
    <div class="code-detail-name">Database:</div>
    <div class="code-detail-value">{{databaseName || '-'}}</div>
  </div>
  <div class="code-detail">
    <div class="code-detail-name">Unit:</div>
    <div class="code-detail-value">{{unitDescription || '-'}}</div>
  </div>
  <div class="code-detail">
    <div class="code-detail-name">Collection:</div>
    <div class="code-detail-value">{{collectionName || '-'}}</div>
  </div>
  <div class="code-detail">
    <div class="code-detail-name">Basecode:</div>
    <div class="code-detail-value">{{code?.baseCode || '-'}}</div>
  </div>
  <div class="code-detail">
    <div class="code-detail-name">Description:</div>
    <div class="code-detail-value">{{code?.baseCodeDescription || '-'}}</div>
  </div>
</div>
