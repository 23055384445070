import { Injectable } from '@angular/core';
import { IAuthenticator } from '../../models/i-authenticator';
import { HttpVerb } from '../../models/http-verb';
import { Sha256 } from '@aws-crypto/sha256-browser';
import { SignatureV4 } from '@aws-sdk/signature-v4';
import { Auth } from 'aws-amplify';
import { environment } from '../../../environments/environment';
import {
  CognitoIdentity,
  GetIdCommandOutput,
} from '@aws-sdk/client-cognito-identity';

@Injectable({
  providedIn: 'root',
})
export class IamAuthenticatorService implements IAuthenticator {
  // How to solve proper signing: https://github.com/aws/aws-sdk-js-v3/issues/3590
  sigV4?: SignatureV4;
  idToken?: string;

  async getCredentials(): Promise<any> {
    const savedDate = localStorage.getItem('ITU.credentialsExpiration');
    if (
      !this.sigV4 ||
      (savedDate && new Date(savedDate).getTime() < new Date().getTime())
    ) {
      const cognitoIdentity = new CognitoIdentity({
        region: 'eu-central-1',
      });

      const logins: { [key: string]: string } = {};
      const currentSession = await Auth.currentSession();
      const idToken = currentSession.getIdToken();
      this.idToken = idToken.getJwtToken();
      logins[
        `cognito-idp.eu-central-1.amazonaws.com/${environment.cognito.userPoolId}`
      ] = idToken.getJwtToken();
      // 1) Get an identity from the identity pool of Cognito based on the grant given by the id token of the external IdP.
      const getIdCommandOutput: GetIdCommandOutput =
        await cognitoIdentity?.getId({
          // eslint-disable-next-line @typescript-eslint/naming-convention
          IdentityPoolId: environment.identityPoolId,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Logins: logins,
        });

      // 2) Based on the identity generate a set of temporary credentials.
      const getCredentialsForIdentityCommandOutput =
        await cognitoIdentity?.getCredentialsForIdentity({
          // eslint-disable-next-line @typescript-eslint/naming-convention
          IdentityId: getIdCommandOutput.IdentityId,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Logins: logins,
        });
      localStorage.setItem(
        'ITU.credentialsExpiration',
        (
          getCredentialsForIdentityCommandOutput.Credentials?.Expiration ??
          new Date()
        ).toISOString()
      );
      // 3) Given the credentials, set up the Signature V4 library to execute apis in a particular region using those credentials.
      this.sigV4 = new SignatureV4({
        service: 'execute-api',
        region: 'eu-central-1',
        credentials: {
          accessKeyId:
            getCredentialsForIdentityCommandOutput.Credentials?.AccessKeyId ??
            '',
          secretAccessKey:
            getCredentialsForIdentityCommandOutput.Credentials?.SecretKey ?? '',
          sessionToken:
            getCredentialsForIdentityCommandOutput.Credentials?.SessionToken ??
            '',
        },
        sha256: Sha256,
      });
    }
  }

  async getRequest(
    path: string,
    method: HttpVerb,
    headers: { [key: string]: string },
    body?: string,
    params?: { [key: string]: any }
  ): Promise<any> {
    await this.getCredentials();

    const apiUrl = new URL(path);
    headers['host'] = apiUrl.hostname;
    headers['X-Id-Token'] = this.idToken ?? '';

    const paramsToSend: { [key: string]: string } = {};

    if (params) {
      for (const paramKey of Object.keys(params)) {
        if (params[paramKey] !== undefined) {
          paramsToSend[paramKey] = params[paramKey].toString();
        }
      }
    }

    const objectToSign = {
      method: method.toUpperCase(),
      hostname: apiUrl.host,
      path: apiUrl.pathname,
      protocol: apiUrl.protocol,
      query: paramsToSend,
      body,
      headers,
    };

    return await this?.sigV4?.sign(objectToSign, { signingDate: new Date() });
  }

  isAuthenticationError(statusCode: number): boolean {
    return statusCode === 403;
  }

  manageError(response: any): Promise<any> {
    return Promise.reject(
      `Status code: ${response.statusCode} - error: ${response.body}`
    );
  }
}
