import {
  Component,
  ElementRef,
  Inject,
  QueryList,
  ViewChildren,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormArray,
  Validators,
  FormControl,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CountriesListsModel } from '../../update-country-table/models/country-filters-model';
import { MatChipInputEvent } from '@angular/material/chips';

export enum ConditionalFormattingOperation {
  GREATER = 'CONDITIONAL_FORMATTING.GREATER',
  GREATER_OR_EQUAL = 'CONDITIONAL_FORMATTING.GREATER_OR_EQUAL',
  LESS = 'CONDITIONAL_FORMATTING.LESS',
  LESS_OR_EQUAL = 'CONDITIONAL_FORMATTING.LESS_OR_EQUAL',
  EQUAL = 'CONDITIONAL_FORMATTING.EQUAL',
  NOT_EQUAL = 'CONDITIONAL_FORMATTING.NOT_EQUAL',
}

export enum LogicalOperators {
  AND = 'AND',
  OR = 'OR',
}

@Component({
  selector: 'app-conditional-formatting',
  templateUrl: './conditional-formatting.component.html',
  styleUrl: './conditional-formatting.component.scss',
})
export class ConditionalFormattingComponent {
  eConditionalFormattingOperations = ConditionalFormattingOperation;
  formGroup!: FormGroup;
  logicalOperatorsList: LogicalOperators[] = [];
  eLogicalOperators = LogicalOperators;
  color = '#ffd580';
  allCountries!: any;
  @ViewChildren('inputElement{{i}}')
  inputElements!: QueryList<ElementRef>;
  @ViewChildren('inputCodesElement{{i}}')
  inputCodesElements!: QueryList<ElementRef>;
  filterFormGroup = new FormGroup({
    autocompleteRegion: new FormControl(''),
    autocompleteCountry: new FormControl(''),
  });
  allCodes!: string[][];
  separatorKeysCodes: number[] = [13, 32, 188];
  addOnBlur = true;

  constructor(
    public dialogRef: MatDialogRef<ConditionalFormattingComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (!data.conditions) {
      this.formGroup = this.formBuilder.group({
        conditionsArray: this.formBuilder.array([this.createConditionGroup()]),
      });
      this.allCodes = [[]];
    }

    if (data.conditions) {
      this.formGroup = this.formBuilder.group({
        conditionsArray: this.formBuilder.array([]),
      });
      const conditionsArray = this.formGroup.get(
        'conditionsArray'
      ) as FormArray;
      this.allCodes = [];
      data.conditions.forEach((item: any) => {
        conditionsArray.push(
          this.createConditionGroupWithValues(
            item.conditionOperation,
            item.conditionThresholdValue,
            item.conditionCountries
          )
        );
        this.allCodes.push(item.conditionCodes);
      });
      this.logicalOperatorsList = data.logicalOperators;
      this.color = data.color;
    }
    this.allCountries = data.allCountries;
    dialogRef.disableClose = true;
  }

  createConditionGroup(): FormGroup {
    return this.formBuilder.group({
      conditionOperation: ['', Validators.required],
      inputValue: ['', Validators.required],
      codes: [[]],
      countries: [[]],
    });
  }

  createConditionGroupWithValues(
    operation: string,
    value: any,
    countries: any[]
  ): FormGroup {
    return this.formBuilder.group({
      conditionOperation: [operation, Validators.required],
      inputValue: [value, Validators.required],
      codes: [[]],
      countries: [countries],
    });
  }

  get conditionsArray(): FormArray {
    return this.formGroup.get('conditionsArray') as FormArray;
  }

  removeFormFieldRow(index: number): void {
    this.conditionsArray.removeAt(index);
    this.logicalOperatorsList.pop();
  }

  addCondition(logicalOperator: LogicalOperators): void {
    this.conditionsArray.push(this.createConditionGroup());
    this.logicalOperatorsList.push(logicalOperator);
    this.allCodes.push([]);
  }

  applyFilter(): void {
    this.formGroup.value.conditionsArray.forEach(
      (c: any, i: number) => (c.codes = this.allCodes[i])
    );
    this.dialogRef.close({
      values: this.formGroup.value.conditionsArray,
      logicalOperators: this.logicalOperatorsList,
      color: this.color,
    });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  onOpenedChange(isOpened: boolean, i: number): void {
    if (isOpened) {
      const inputElement = this.inputElements.toArray()[i].nativeElement;
      inputElement.focus();
    }
  }

  displayMultipleFnCountries(value: any): string {
    let result = '';
    let index = 0;
    for (const v of value.controls.countries.value) {
      index++ === 0
        ? (result = `${this.displayFnCountries(v, true)}`)
        : (result = `${result}, ${this.displayFnCountries(v, true)}`);
    }
    return result;
  }

  displayFnCountries(value: any, fromMultiple = false): string {
    let val;
    if (fromMultiple) {
      val = value;
    } else {
      val = value.controls?.countries?.value[0];
    }
    return value
      ? this.allCountries.find((country: any) => country.shortName === val)
          ?.shortName ?? ''
      : 'No filter';
  }

  visuallyFilterCountries(country: CountriesListsModel): boolean {
    const countryName = country.shortName.toLowerCase();
    const countryisoCode = (country.isoCode || '').toLowerCase();
    const input = (
      this.filterFormGroup.controls.autocompleteCountry.value || ''
    ).toLowerCase();
    return (
      input === '' ||
      countryName.includes(input) ||
      countryisoCode.includes(input)
    );
  }

  removeCountryFilter(event: any, conditionGroup: any, i: number): void {
    event.preventDefault();
    event.stopPropagation();
    conditionGroup.controls.countries?.setValue([]);
    this.filterFormGroup.controls.autocompleteCountry.setValue('');
    const inputElement = this.inputElements.toArray()[i].nativeElement;
    inputElement.focus();
  }

  conditionGroupLength(conditionGroup: any): number {
    return conditionGroup.controls.countries?.value.length;
  }

  changeSelection(i: number): void {
    const inputElement = this.inputElements.toArray()[i].nativeElement;
    inputElement.focus();
    this.filterFormGroup.controls.autocompleteCountry.setValue('');
  }

  addValue(event: MatChipInputEvent, i: number, conditionGroup: any): void {
    const value = event.value;
    if ((value || '').trim()) {
      this.addChip(event.value, i, conditionGroup);
    }
  }

  addChip(value: string, i: number, conditionGroup: any): void {
    if ((value || '').trim()) {
      if (value.includes(',')) {
        this.allCodes[i].push(...value.split(','));
        conditionGroup.controls.codes?.setValue(this.allCodes[i]);
      } else {
        this.allCodes[i].push(value.trim());
        conditionGroup.controls.codes?.setValue(this.allCodes[i]);
      }
    }
    const inputCodesElement =
      this.inputCodesElements.toArray()[i].nativeElement;
    inputCodesElement.value = '';
    inputCodesElement.focus();
  }

  removeChip(item: any, conditionGroup: any, i: number): void {
    const index = this.allCodes[i].indexOf(item);
    this.allCodes[i].splice(index, 1);
  }
}
