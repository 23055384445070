import { Component } from '@angular/core';
import { ProviderService } from '../../core/provider.service';
import { IUser, UserRoles } from '../../models/i-user';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  loading = false;
  user!: IUser;
  eUserRole = UserRoles;

  constructor(private providerService: ProviderService) {}

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.user = await this.providerService.authService.getUser();
    this.loading = false;
  }

  navigateToCountryView(): void {
    this.providerService.utilService.navigateTo('app/datasets/country-view');
  }

  navigateToHouseholdView(): void {
    this.providerService.utilService.navigateTo('app/datasets/household-view');
  }

  navigateToDownloadModule(): void {
    this.providerService.utilService.navigateTo('app/download-module');
  }

  navigateToUploadModule(): void {
    this.providerService.utilService.navigateTo('app/upload-module');
  }

  navigateToDataManagementRegions(): void {
    this.providerService.utilService.navigateTo('app/data-management-regions');
  }

  navigateToAdminRoute(_dataset: string): void {
    this.providerService.utilService.navigateTo('/app/admin-view');
  }

  navigateToDataManagementCodes(): void {
    this.providerService.utilService.navigateTo('/app/data-management-codes');
  }

  navigateToCountryValidation(): void {
    this.providerService.utilService.navigateTo('/app/country-validation');
  }

  navigateToValidation(): void {
    this.providerService.utilService.navigateTo('/app/validation-module');
  }
}
