<div class="navbar">
  <div class="logo-section">
    <a class="title" (click)="navigateToHome()">
      <div class="logo">
        <img src="/assets/images/logo.svg" alt="logo" title="logo" />
      </div>
    </a>
  </div>
  <span class="title-section">
    <h1>{{pageTitle}}</h1>
  </span>
  <div class="spacer"></div>
  <div class="user">
    @if(!this.currentRoute.includes('home')){
      <a (click)="showAdminForRoute()" matTooltip="{{'NAVBAR.ADMIN_VIEW' | translate}}"><i class="fa-solid fa fa-cog"></i></a>
      <a (click)="navigateToHome()" matTooltip="{{'NAVBAR.GO_HOME_TOOLTIP' | translate}}"><i class="fa-solid fa-house"></i></a>
      <mat-divider [vertical]="true" style="height: 32px;"></mat-divider>
    }
    <span>{{user.email}}</span>
    <span style="padding-left: 0">[{{userRole}}]</span>
    <a (click)="signOut()"><i class="fa-regular fa-right-from-bracket"></i></a>
  </div>
  <div class="user-mobile">
    <a class="hamburger-menu" mat-icon-button [matMenuTriggerFor]="mobileMenu"><i class="fa-solid fa-bars"></i></a>
    <mat-menu #mobileMenu="matMenu">
      <span mat-menu-item disabled>{{'NAVBAR.WELCOME' | translate}} {{user.email}}</span>
      <span style="padding-left: 0">[{{userRole}}]</span>
      <a (click)="signOut()" mat-menu-item>
        <i class="fa-regular fa-right-from-bracket"></i>
      </a>
    </mat-menu>
  </div>
</div>
