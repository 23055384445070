import { Component, OnInit } from '@angular/core';
import { IUser, UserRoles } from '../../models/i-user';
import { NavigationEnd, Router } from '@angular/router';
import { ProviderService } from '../../core/provider.service';
import { Constants } from '../../models/constants';
import { AntiMemLeak } from '../../core/form-utils/anti-mem-leak/anti-mem-leak';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent extends AntiMemLeak implements OnInit {
  user: IUser;
  userRole!: string;
  eConstants = Constants;
  currentRoute = '';
  pageTitle = '';
  constructor(
    private router: Router,
    private providerService: ProviderService
  ) {
    super();
    this.subscriptions.add(
      this.router.events.subscribe((e) => {
        if (e instanceof NavigationEnd) {
          this.currentRoute = e.url;
          this.setPageTitle();
        }
      })
    );
    this.user = {} as IUser;
  }

  ngOnInit(): void {
    this.getUser();
  }

  async getUser(): Promise<void> {
    const user = await this.providerService.authService.getUser();
    this.user = user.attributes ?? ({} as IUser);
    this.userRole = user.role;
  }

  showAdminForRoute(): void {
    if (this.currentRoute.includes('country-view')) {
      this.router.navigate(['/', 'app', 'admin-view']);
    }
  }
  setPageTitle(): void {
    if (this.currentRoute.includes('country-view')) {
      this.pageTitle = 'WTI Country View';
    }
    if (this.currentRoute.includes('home') || this.currentRoute === '/') {
      this.pageTitle = 'Home Menu';
    }
    if (this.currentRoute.includes('admin-view')) {
      this.pageTitle = 'Admin view';
    }
    if (this.currentRoute.includes('download-module')) {
      this.pageTitle = 'Download Module';
    }
    if (this.currentRoute.includes('upload-module')) {
      this.pageTitle = 'Upload Module';
    }
    if (this.currentRoute.includes('data-management-regions')) {
      this.pageTitle = 'Data Management - Regions';
    }
    if (this.currentRoute.includes('data-management-codes')) {
      this.pageTitle = 'Data Management - Codes';
    }
    if (this.currentRoute.includes('household-view')) {
      this.pageTitle = 'WTI Household View';
    }
    if (this.currentRoute.includes('country-validation')) {
      this.pageTitle = 'Country Validation';
    }
    if (this.currentRoute.includes('validation-module')) {
      this.pageTitle = 'Validation Module';
    }
  }
  signOut(): void {
    this.providerService.authService.signOut().then(() => {
      this.router.navigate(['/', 'auth', 'sign-in']);
    });
  }
  navigateToHome(): void {
    this.providerService.utilService.navigateTo('app/home');
  }
}
