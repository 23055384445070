import { Injectable } from '@angular/core';
import { NetworkService } from '../../../core/net-utils/network.service';
import {
  CountryValidationModel,
  CountryValidationResponseModel,
} from '../models/country-validation-model';
import { CountryValidationMapper } from '../mappers/country-validation-mapper';
import { environment } from 'src/environments/environment';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { SurveyModel } from '../../survey-table/models/survey-model';
import { CountrySurveyModel } from '../../survey-country-table/models/country-survey-model';
import { DataTypeEnum } from '../enum/data-type-enum';

@Injectable({
  providedIn: 'root',
})
export class CountryValidationTableService {
  private networkService!: NetworkService;
  private baseUrl = `${environment.backend.endpoint}/validation-module`;

  setNetworkService(networkService: NetworkService): void {
    this.networkService = networkService;
  }

  async getCountryValidationTableListAndCount(
    surveyID: string,
    countryID: string,
    fromYear: number,
    toYear: number,
    pageNumber: number,
    pageSize: number,
    code?: string[]
  ): Promise<CountryValidationResponseModel> {
    const mapper = new CountryValidationMapper();
    await this.networkService.get(
      `${this.baseUrl}/survey/${surveyID}/country/${countryID}/from_year/${fromYear}/to_year/${toYear}`,
      mapper,
      {
        code: code ? code.join(',') : undefined,
        offset: pageNumber,
        limit: pageSize,
      }
    );
    return mapper.countryList!;
  }

  async getListForPdfGeneration(
    surveyId: string,
    countryId: string,
    fromYear: string,
    toYear: string
  ): Promise<CountryValidationResponseModel> {
    const mapper = new CountryValidationMapper();
    await this.networkService.get(
      `${this.baseUrl}/survey/${surveyId}/country/${countryId}/from_year/${fromYear}/to_year/${toYear}/pdf`,
      mapper
    );
    return mapper.countryList!;
  }

  elaborateCountryValidationModel(
    backendData: CountryValidationModel[],
    yearsToDisplay: number[]
  ): { [key: string]: any }[] {
    const dataToShow = [];
    for (const backendElement of backendData) {
      let row: { [key: string]: any } = {};
      row['rowDescription'] = backendElement.codeName;
      for (const year of yearsToDisplay) {
        const backendDataYear = backendElement.years.find(
          (value) => value.year === year
        );
        if (backendDataYear?.dataID) row[`dataID`] = backendDataYear?.dataID;
        row[`${year}-old`] = this.formatDecimalDigits(
          backendDataYear?.originalValue,
          2
        );
        row[`${year}-new`] = this.formatDecimalDigits(
          backendDataYear?.newValue,
          2
        );
        if (backendDataYear?.originalValue !== backendDataYear?.newValue) {
          if (!backendDataYear?.originalValue && backendDataYear?.newValue) {
            row[`${year}-type`] = DataTypeEnum.NewData;
          } else if (
            !backendDataYear?.newValue &&
            backendDataYear?.originalValue
          ) {
            row[`${year}-type`] = DataTypeEnum.dataDeleted;
          } else {
            row[`${year}-type`] = DataTypeEnum.dataChanged;
          }
        } else {
          row[`${year}-type`] = DataTypeEnum.NoChange;
        }
      }
      dataToShow.push(row);
      row = {};
      row['rowDescription'] = 'notes';
      for (const year of yearsToDisplay) {
        const backendDataYear = backendElement.years.find(
          (value) => value.year === year
        );
        if (backendDataYear?.dataID) row[`dataID`] = backendDataYear?.dataID;
        row[`${year}-old`] = backendDataYear?.originalNotes;
        row[`${year}-new`] = backendDataYear?.newNotes;
        if (
          (backendDataYear?.originalNotes?.trim() ?? '') !==
          (backendDataYear?.newNotes?.trim() ?? '')
        ) {
          if (!backendDataYear?.originalNotes && backendDataYear?.newNotes) {
            row[`${year}-type`] = DataTypeEnum.NewData;
          } else if (
            !backendDataYear?.newNotes &&
            backendDataYear?.originalNotes
          ) {
            row[`${year}-type`] = DataTypeEnum.dataDeleted;
          } else {
            row[`${year}-type`] = DataTypeEnum.dataChanged;
          }
        } else {
          row[`${year}-type`] = DataTypeEnum.NoChange;
        }
      }
      dataToShow.push(row);
      row = {};
      row['rowDescription'] = 'sources';
      for (const year of yearsToDisplay) {
        const backendDataYear = backendElement.years.find(
          (value) => value.year === year
        );
        if (backendDataYear?.dataID) row[`dataID`] = backendDataYear?.dataID;
        row[`${year}-old`] = backendDataYear?.originalSource;
        row[`${year}-new`] = backendDataYear?.newSource;
        if (
          (backendDataYear?.originalSource?.trim() ?? '') !==
          (backendDataYear?.newSource?.trim() ?? '')
        ) {
          if (!backendDataYear?.originalSource && backendDataYear?.newSource) {
            row[`${year}-type`] = DataTypeEnum.NewData;
          } else if (
            !backendDataYear?.newSource &&
            backendDataYear?.originalSource
          ) {
            row[`${year}-type`] = DataTypeEnum.dataDeleted;
          } else {
            row[`${year}-type`] = DataTypeEnum.dataChanged;
          }
        } else {
          row[`${year}-type`] = DataTypeEnum.NoChange;
        }
      }
      dataToShow.push(row);
    }
    return dataToShow;
  }

  formatDecimalDigits(value: number | undefined, numDigits: number): string {
    if (value) {
      const cleanValue = value.toString().replace(/,/g, '');
      const fixedValue = parseFloat(cleanValue).toFixed(numDigits);
      const parts = fixedValue.split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return parts.join('.');
    } else {
      return '';
    }
  }

  generatePDF(
    pdfData: { [key: string]: any }[],
    selectedSurvey: SurveyModel,
    selectedCountry: CountrySurveyModel,
    yearsToDisplay: number[]
  ): Promise<{
    fileName: string;
    blob: Blob;
  }> {
    const docDefinition = {
      content: [],
    } as any;

    if (pdfData.length > 0) {
      // Add the header for the survey and country
      docDefinition.content.push({
        text: `${selectedSurvey.surveyName}: ${selectedCountry.countryName}`,
        style: 'header',
        margin: [0, 0, 0, 25],
      });

      // Add the table header
      const tableHeader = [
        {
          text: 'Code',
          style: 'tableHeader',
          width: '20%',
        },
      ];

      // Add column headers for each year
      yearsToDisplay.forEach((year) => {
        tableHeader.push({
          text: `Old ${year}`,
          style: 'tableHeader',
          width: `${80 / (yearsToDisplay.length * 2)}%`,
        });
        tableHeader.push({
          text: `New ${year}`,
          style: 'tableHeader',
          width: `${80 / (yearsToDisplay.length * 2)}%`,
        });
      });

      // Push table header to content
      docDefinition.content.push({
        table: {
          headerRows: 1,
          widths: [
            '20%',
            ...Array(yearsToDisplay.length * 2).fill(
              `${80 / (yearsToDisplay.length * 2)}%`
            ),
          ],
          body: [
            tableHeader,
            ...pdfData
              .map((data) => {
                const row = [
                  {
                    text: data['rowDescription'],
                    style: ['tableCell'],
                  },
                ];

                yearsToDisplay.forEach((year) => {
                  row.push({
                    text: data[year + '-old'] || '',
                    style: [data[year + '-type'], 'tableCell'],
                  });
                  row.push({
                    text: data[year + '-new'] || '',
                    style: [data[year + '-type'], 'tableCell'],
                  });
                });
                return [row];
              })
              .flat(),
          ],
        },
        layout: 'mainTableLayout',
      });
      docDefinition.content.push({
        table: {
          headerRows: 1,
          widths: [150, 25, 150, 25, 150, 25, 150],
          body: [
            [
              {
                text: '\n',
                style: '#ffeb7d',
              },
              {
                text: '\n',
              },
              {
                text: '\n',
                style: '#a0ff7b',
              },
              {
                text: '\n',
              },
              {
                text: '\n',
                style: '#ffac6a',
              },
              {
                text: '\n',
              },
              {
                text: '\n',
                style: '#ff7878',
              },
            ],
            [
              {
                text: 'New Data',
                style: 'indexDesc',
              },
              {
                text: '',
                style: 'indexDesc',
              },
              {
                text: 'No Change',
                style: 'indexDesc',
              },
              {
                text: '',
                style: 'indexDesc',
              },
              {
                text: 'Data Changed',
                style: 'indexDesc',
              },
              {
                text: '',
                style: 'indexDesc',
              },
              {
                text: 'Data Deleted',
                style: 'indexDesc',
              },
            ],
          ],
        },
        layout: 'indexStyle',
        margin: [0, 50, 0, 0],
      });
    }

    // Define styles for header, table headers, and cells
    docDefinition.styles = {
      header: {
        bold: true,
        fontSize: 24,
        alignment: 'center',
      },
      tableHeader: {
        bold: true,
        fontSize: 16,
        alignment: 'center',
        fillColor: '#f2f2f2',
      },
      tableCell: {
        alignment: 'center',
        fontSize: 14,
        padding: 5,
      },
      '#ffeb7d': {
        fillColor: '#ffeb7d',
      },
      '#a0ff7b': {
        fillColor: '#a0ff7b',
      },
      '#ffac6a': {
        fillColor: '#ffac6a',
      },
      '#ff7878': {
        fillColor: '#ff7878',
      },
      indexDesc: {
        alignment: 'center',
        fontSize: 14,
      },
    };

    docDefinition.pageSize = {
      width: 1920,
      height: 1080,
    };

    docDefinition.pageOrientation = 'landscape';

    return new Promise((resolve, reject) => {
      pdfMake
        .createPdf(
          docDefinition,
          {
            mainTableLayout: {
              hLineWidth: function (i) {
                return i === 0 || (i + 2) % 3 === 0 ? 2 : 1;
              },
              hLineColor: function (i) {
                return i === 0 || (i + 2) % 3 === 0 ? '#8A8A8A' : '#aaa';
              },
              vLineWidth: function (i) {
                return i === 0 || (i + 1) % 2 === 0 ? 2 : 1;
              },
              vLineColor: function (i) {
                return i === 0 || (i + 1) % 2 === 0 ? '#8A8A8A' : '#aaa';
              },
            },
            indexStyle: {
              hLineWidth: function () {
                return 0;
              },
              vLineWidth: function () {
                return 0;
              },
              paddingLeft: function () {
                return 0;
              },
              paddingRight: function () {
                return 0;
              },
              paddingTop: function () {
                return 10;
              },
              paddingBottom: function () {
                return 0;
              },
            },
          },
          undefined,
          pdfFonts.vfs
        )
        .getBlob((result) => {
          try {
            resolve({
              fileName: `${selectedSurvey.surveyName.trim()}_${selectedCountry.countryName.trim()}.pdf`,
              blob: result,
            });
          } catch (e) {
            reject(e);
          }
        });
    });
  }
}
