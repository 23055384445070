import { IMapper } from '../../../models/i-mapper';
import { SurveyStatusEnum } from '../enums/survey-status-enum';
import { SurveyValidationStatusEnum } from '../enums/survey-validation-status-enum';
import {
  CountrySurveyModel,
  CountrySurveyResponseModel,
} from '../models/country-survey-model';

export class CountrySurveyMapper implements IMapper {
  countrySurveyList?: CountrySurveyResponseModel;
  fillFromJson(json: any): void {
    const data: CountrySurveyModel[] = [];
    json.data.forEach((countrySurvey: any) => {
      data.push({
        countryID: countrySurvey.countryId,
        countryName: countrySurvey.country,
        isoCode: countrySurvey.isoCode,
        surveyStatus:
          SurveyStatusEnum[
            countrySurvey.surveyStatus as keyof typeof SurveyStatusEnum
          ],
        validationStatus:
          SurveyValidationStatusEnum[
            countrySurvey.validationStatus as keyof typeof SurveyValidationStatusEnum
          ],
      });
    });
    this.countrySurveyList = {
      data,
      total: json.total,
    };
  }
  fillToJson(): string {
    return '';
  }
}
