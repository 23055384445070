import { IMapper } from '../../../models/i-mapper';
import { CollectionsModel } from '../models/collections-model';

export class CollectionsListMapper implements IMapper {
  collectionsList: CollectionsModel[] = [];
  fillFromJson(json: any): void {
    json.forEach((classList: any) => {
      this.collectionsList.push(classList);
    });
  }
  fillToJson(): string {
    return '';
  }
}
