<div #rectangularSelection id="rectangular-div" hidden></div>
<div class="container">
  @if (loading && !yearValueChanged) {
    <app-loader></app-loader>
  }
  <div class="filters-class">
    <ng-container>
      <div class="filters-bar" [formGroup]="countryFiltersFormGroup">
        <div class="first-column">
          <span class="mat-drop-down">
            <mat-label>Region: </mat-label>
            <mat-form-field>
              <mat-select #matSelectFixRegion id="css-matselect-fix-region" [formControl]="regionsFormControl"
                          (openedChange)="onOpenedRegionChange($event)" multiple >
                <mat-select-trigger>
                  <span class="label" [matTooltip]="displayMultipleRegionFn(regionsFormControl.value)">
                    {{displayRegionFn(regionsFormControl.value?.[0]) || ''}}
                    @if ((regionsFormControl.value?.length || 0) > 1) {
                      <span class="example-additional-selection">
                        (+{{(regionsFormControl.value?.length || 0) - 1}} {{regionsFormControl.value?.length === 2 ? 'other' : 'others'}})
                      </span>
                    }
                  </span>
                  @if ((regionsFormControl.value?.length || 0) > 0) {
                    <i (click)="removeCountryOrRegionFilter($event)" class="fa fa-x clear-filter"
                       [matTooltip]="'Clear filter'"></i>
                  }
                </mat-select-trigger>
                <input matInput type="text" formControlName="autocompleteRegionForm"
                       class="autocomplete-input"
                       (keydown)="$event.stopPropagation()"
                       #regionInputElement>
                @if (countryFiltersFormGroup.controls.autocompleteRegionForm.value === '') {
                  <span class="autocomplete-placeholder">Search...</span>
                }
                <div class="select-deselect-all-container">
                  <button color="primary" mat-raised-button type="button" class="select-deselect-buttons"
                          (click)="deselectAllOptions('regions')">Deselect All
                  </button>
                  <button color="primary" mat-raised-button type="button" class="select-deselect-buttons"
                          (click)="selectAllOptions('regions')">Select All
                  </button>
                </div>
                @for (region of regions; track region.regionID) {
                  <mat-option [ngClass]="visuallyFilterRegions(region) ? '' : 'hidden'" [value]="region.regionID">
                    {{ region.regionCode }} - {{ region.regionName }}
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>
          </span>
          <span class="mat-drop-down">
            <mat-label>Country: </mat-label>
            <mat-form-field>
              <mat-select id="css-matselect-fix" [formControl]="countriesFormControl"
                          (openedChange)="onOpenedChange($event)" multiple >
                <mat-select-trigger>
                  <span class="label" [matTooltip]="displayMultipleFn(countriesFormControl.value)">
                    {{displayFn(countriesFormControl.value?.[0]) || ''}}
                    @if ((countriesFormControl.value?.length || 0) > 1) {
                      <span class="example-additional-selection">
                        (+{{(countriesFormControl.value?.length || 0) - 1}} {{countriesFormControl.value?.length === 2 ? 'other' : 'others'}})
                      </span>
                    }
                  </span>
                  @if ((countriesFormControl.value?.length || 0) > 0) {
                    <i (click)="removeCountryOrRegionFilter($event)" class="fa fa-x clear-filter"
                       [matTooltip]="'Clear filter'"></i>
                  }
                </mat-select-trigger>
                <input matInput type="text" formControlName="autocompleteForm"
                       class="autocomplete-input"
                       (keydown)="$event.stopPropagation()"
                       #inputElement>
                @if (countryFiltersFormGroup.controls.autocompleteForm.value === '') {
                  <span class="autocomplete-placeholder">Search...</span>
                }
                <div class="select-deselect-all-container">
                  <button color="primary" mat-raised-button type="button" class="select-deselect-buttons"
                          (click)="deselectAllOptions('countries')">Deselect All
                  </button>
                  <button color="primary" mat-raised-button type="button" class="select-deselect-buttons"
                          (click)="selectAllOptions('countries')">Select All
                  </button>
                </div>
                @for (country of countriesFromSelectedRegions; track country) {
                  <mat-option [ngClass]="visuallyFilterCountries(country) ? '' : 'hidden'" [value]="country.isoCode">
                    {{ country.isoCode }} - {{ country.shortName }}
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>
          </span>
          <span class="mat-drop-down">
            <mat-label>Code: </mat-label>
            <i class="fa fa-circle-info" [matTooltip]="'Press ENTER or COMMA to enter a code, you can use * to define a wildcard'"></i>
            <mat-form-field>
              <mat-chip-grid #chipGrid>
                @for(filter of filters; track filter) {
                  <mat-chip-row
                    (removed)="removeChip(filter)"
                    [removable]="true"
                    [editable]="false"
                    class="standard"
                  >
                    {{filter}}
                    <button matChipRemove class="mat-chip-remove-code">
                      <mat-icon>cancel</mat-icon>
                    </button>
                  </mat-chip-row>
                }
                <input
                  class="code-filter-input"
                  matInput type="text" formControlName="code"
                  [matChipInputFor]="chipGrid"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  (matChipInputTokenEnd)="addValue($event)"
                  #inputFilterElement
                  [disabled]="loading"
                />
              </mat-chip-grid>
            </mat-form-field>
          </span>
          <span class="mat-drop-down">
            <mat-label>Description: </mat-label>
            <input matInput type="text" formControlName="description">
          </span>
          <span class="mat-drop-down last-item">
            <div class="conditional-formatting-container">
              @if (isConditionalFormattingFilterOn) {
                <i class="fa-solid fa fa-trash" style="cursor: pointer" (click)="clearConditionalFormattingFilter()" matTooltip="Clear"></i>
              }
              <button color="primary" mat-raised-button type="button" onclick="this.blur()"
                        class="conditional-formatting-button" (click)="openConditionalFormattingDialog()">
                Conditional formatting
              </button>
              <button id="clipboard-button" color="primary" mat-raised-button type="button" onclick="this.blur()"
                                    class="conditional-formatting-button" (click)="openCopyToClipboardDialog(copyToClipboardOptionsDialog)">
                Copy to clipboard
              </button>
              <div class="divider"></div>
                <div class="button-container">
                  <button mat-raised-button color="primary" type="button" class="selected-download-button" onclick="this.blur()"
                          [matMenuTriggerFor]="downloadMenu">
                    Download<mat-icon iconPositionEnd>arrow_drop_down</mat-icon>
                  </button>
                  <mat-menu #downloadMenu="matMenu">
                    <button class="mat-menu-item-download" mat-menu-item
                            (click)="downloadShowedTableData(downloadDialog, true)" onclick="this.blur()">
                      Download Raw
                    </button>
                    <button class="mat-menu-item-download" mat-menu-item
                            (click)="downloadShowedTableData(downloadDialog, false)" onclick="this.blur()">
                      Download Years as Columns
                    </button>
                  </mat-menu>
                </div>
            </div>
          </span>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="table-holder">
    <form class="table-scroller" matSort autocomplete="off" #formElement>
      <ng-container>
        @if (countriesDataBE.length !== 0) {
          <table
            mat-table
            [dataSource]="tableDataSource"
            class="mat-elevation-z8"
            id="country-table"
            [trackBy]="tableTrackBy"
          >
            <caption></caption>
            @for (column of columns; track column; let i = $index) {
              @if (column.field !== 'actions') {
                @if (stickyColumns.includes(column.field)) {
                  @if (column.readOnly) {
                    <ng-container matColumnDef="{{column.field}}" sticky>
                      <th mat-sort-header [style.width]="column.width" [columnModel]="column" [disabled]="resizing" appResizeColumn
                          (resizing)="this.resizingChanged($event)" mat-header-cell
                          *matHeaderCellDef>{{ column.title }}
                      </th>
                      <td
                        mat-cell
                        class="read-only"
                        *matCellDef="let element; let i = index"
                        [formGroup]="element.formGroup"
                        [style.width]="column.width"
                      >
                        <input
                          matInput
                          type="text"
                          class="form-control"
                          readonly
                          formControlName="{{column.field}}"
                          (keydown.enter)="$event.preventDefault()"
                        />
                      </td>
                    </ng-container>
                  }
                } @else {
                  <ng-container matColumnDef="{{column.field}}">
                    <th appResizeColumn mat-sort-header [columnModel]="column" [style.width]="column.width" [disabled]="resizing"
                        (resizing)="this.resizingChanged($event)" mat-header-cell *matHeaderCellDef>{{ column.title }}</th>
                    <td
                      mat-cell
                      *matCellDef="let cell; let i = index"
                      [formGroup]="cell.formGroup"
                      class="{{selectedCell === cell && selectedColumn === column ? 'selectedCell': ''}} selectable-cell"
                      #currentTD
                      [style.width]="column.width"
                      [ngStyle]="{'background-color': checkConditions(cell.formGroup.controls[column.field].value, cell) ? color : 'inherit'}"
                      (click)="setSelectedCell(cell, column, currentTD); getCellInfo(cell, column.field);"
                      (mousedown)=" downEventOnCell($event, i, column.field);"
                      (mouseup)="upEventOnCell($event, i, column.field)"
                      (contextmenu)="openContextualMenu($event,
                      areCellsSelectedWithRectangularSelection ? tableContextualMenuWithSelection : tableContextualMenu,
                      cell, column, currentTD, column.field, i)"
                    >
                      <div class="editable-cell-input-group">
                        @if(cell === this.selectedCell) {
                          <input
                            matInput
                            type="text"
                            class="form-control"
                            [ngStyle]="{'background-color': checkConditions(cell.formGroup.controls[column.field].value, cell) ? color : 'inherit'}"
                            [formControlName]="column.field"
                            [ngClass]="''"
                            (keydown.enter)="$event.preventDefault();updateCell(cell, column.field, true);"
                            (focusout)="updateCell(cell, column.field)"
                          />
                        } @else {
                          <div class="span-input-substitute">
                            <span>
                              {{formatDecimalDigits(cell.formGroup.controls[column.field].value, 2)}}
                            </span>
                          </div>
                        }
                      </div>
                      @if (cell.formGroup.controls[column.field].invalid) {
                        <div class="error-message">Only number accepted!</div>
                      }
                    </td>
                  </ng-container>
                }
              }
              @if (column.field === 'actions') {
                <ng-container matColumnDef="{{column.field}}" sticky>
                  <th mat-header-cell [style.width]="column.width" *matHeaderCellDef>{{ column.title }}</th>
                  <td
                    mat-cell
                    *matCellDef="let element; let i = index"
                    [formGroup]="element.formGroup"
                    [style.width]="column.width"
                    class="sticky-border"
                  >
                    <button
                      mat-icon-button
                      class="material-icons app-toolbar-menu save-button"
                      (click)="openModificationDialog(element.formGroup)"
                    >
                      <mat-icon class="edit-icon">edit</mat-icon>
                    </button>
                  </td>
                </ng-container>
              }
            }
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        }
      </ng-container>
    </form>
  </div>
  @if (countriesDataBE.length === 0 && !loading && !showTableError) {
    <div class="no-data-found">No data found for your search</div>
  }
  @if (showTableError && !loading) {
    <div class="no-data-found">Something went wrong</div>
  }
  <div class="paginator">
    @if (fetchingTableDataCount) {
      <div class="spinner-container">
        <i class="fa-sharp-duotone fa-solid fa-spinner-third spinning-animation"></i>
      </div>
    }
    <mat-paginator [length]="tableDataCount" [pageSizeOptions]="[25, 50, 100, 150, 250]">
    </mat-paginator>
  </div>
  <div class="data-info-container">
    @if (!cellInfoPresent && selectedCell) {
      <div class="no-cell-warning">
        <span>No value for selected year, insert a value to modify details</span>
      </div>
    }
    <div class="data-info">
      @if (cellInfoLoading) {
        <mat-spinner></mat-spinner>
      }
      <div class="notes-group">
        <div class="notes-container">
          <div class="notes-row">
            <span class="first">Internal notes: </span>
            @if (cellInfoPresent && !cellInfo) {
              <div class="loader-container">
                <i class="fa-solid fa-ellipsis ellipses-loader"></i>
              </div>
            }
            <input #publishedNote matInput [disabled]="cellInfoLoading || !cellInfoPresent"
                   (input)="internalNotesSelectionChanged($event)"
                   (focusout)="publishedNote.value.length > 0 ? updateInternalNotes() : updateInternalNotes('')"
                   (keydown.enter)="publishedNote.value.length > 0 ? updateInternalNotes() : updateInternalNotes('')"
                   type="text" [value]="cellInfo === undefined ? '' : cellInfo.internalNotes">

          </div>
          <mat-divider></mat-divider>
          <div class="notes-row">
            <span>Published notes: </span>
            @if (cellInfoPresent && !cellInfo) {
              <div class="loader-container">
                <i class="fa-solid fa-ellipsis ellipses-loader"></i>
              </div>
            }
            <input #internalNote matInput [disabled]="cellInfoLoading || !cellInfoPresent"
                   (input)="notesSelectionChanged($event)"
                   (focusout)="internalNote.value.length > 0 ? updatePublishedNotes() : updatePublishedNotes('')"
                   (keydown.enter)="internalNote.value.length > 0 ? updatePublishedNotes() : updatePublishedNotes('')"
                   type="text" [value]="cellInfo === undefined ? '' : cellInfo.publishNotes">
          </div>
        </div>
        <div class="notes-states" [formGroup]="checkboxFormGroup">
          <div class="flex-row">
            <span>Published</span>
            <div>
              <mat-checkbox [disabled]="cellInfoLoading || !cellInfoPresent"
                            [formControl]="checkboxFormGroup.controls.published"
                            (change)="publishedChanged($event)"></mat-checkbox>
            </div>
          </div>
          <div class="flex-row">
            <span>Estimated</span>
            <div>
              <mat-checkbox [disabled]="cellInfoLoading || !cellInfoPresent"
                            [formControl]="checkboxFormGroup.controls.estimated"
                            (change)="estimatedChanged($event)"></mat-checkbox>
            </div>
          </div>
        </div>
      </div>
      <div class="data-history">
        <span>Data history</span>
        <div>
          @for (comment of cellHistory; track comment) {
            @if (selectedCell) {
              @if (comment.version === 0) {
                <p>First value for the cell is {{ comment.value }}, inserted on {{ comment.lastModifiedOn }} by
                  "{{ comment.user }}"</p>
              } @else {
                @if (comment.value != null) {
                  <p>user '{{ comment.user }}' changed value to '{{ comment.value }}' on '{{ comment.lastModifiedOn }}
                    '</p>
                } @else {
                  <p>user '{{ comment.user }}' deleted the value on '{{ comment.lastModifiedOn }}'</p>
                }
              }
            }
          }
        </div>
        <app-upload-retrieve-file
          [cell]="selectedCell"
          [table]="'country-view'"
          [column]="selectedColumn"
          [cellInfo]="cellInfo"
          [disabled]="cellInfoPresent && !cellInfo">
        </app-upload-retrieve-file>
      </div>
      <div class="data-source">
        <span>Source</span>
        <input #sourceInput [disabled]="cellInfoLoading || !cellInfoPresent" (input)="sourceSelectionChanged($event)"
               (focusout)="updateSource();deleteSource( false,sourceInput.value)" type="text"
               (keydown.enter)="updateSource();deleteSource( false,sourceInput.value)"
               [value]="cellInfo === undefined ? '' : cellInfo.source">
      </div>
    </div>
  </div>
</div>

<ng-template #downloadDialog let-data>
  <div class="container-dialog-select-columns">
    @if (data.isRaw && downloadRawStep === 0) {
      <h2 mat-dialog-title class="mat-dialog-title">Select columns to download</h2>
      <mat-dialog-content [formGroup]="countryFiltersFormGroup">
        <mat-form-field appearance="fill">
          <mat-label>CSV Columns</mat-label>
          <mat-select #columnFilter [formControl]="csvColumnsFormControl" class="alt-select"
                      (selectionChange)="this.countryFiltersFormGroup.controls.autocompleteColumn.setValue('')"
                      (openedChange)="onOpenedColumnChange($event)" multiple >
            <mat-select-trigger>
                  <span class="label" [matTooltip]="displayMultipleColumnFn(csvColumnsFormControl.value)">
                    {{csvColumnsFormControl.value?.[0] || ''}}
                    @if ((csvColumnsFormControl.value?.length || 0) > 1) {
                      <span class="example-additional-selection">
                        (+{{(csvColumnsFormControl.value?.length || 0) - 1}} {{csvColumnsFormControl.value?.length === 2 ? 'other' : 'others'}})
                      </span>
                    }
                  </span>
              @if ((csvColumnsFormControl.value?.length || 0) > 0) {
                <i (click)="removeColumnFilter($event)" class="fa fa-x clear-filter"
                   [matTooltip]="'Clear filter'"></i>
              }
            </mat-select-trigger>
            <input matInput type="text" formControlName="autocompleteColumn"
                   class="autocomplete-input"
                   (keydown)="$event.stopPropagation()"
                   #columnInputElement>
            @if (countryFiltersFormGroup.controls.autocompleteColumn.value === '') {
              <span class="autocomplete-placeholder">Search...</span>
            }
            @for (column of rawCountryViewDataColumns; track column) {
              @if (column.value === 'All columns') {
                <mat-option [ngClass]=" visuallyFilterColumns(column.value) ? '' : 'hidden'"
                            (click)="allColumnsSelected()" [value]="column.value">
                  {{ column.name | translate }}
                </mat-option>
              }
              @else {
                <mat-option [ngClass]="visuallyFilterColumns(column.value) ? '' : 'hidden'"
                            (click)="otherColumnSelected()" [value]="column.value">
                  {{ column.name | translate }}
                </mat-option>
              }
            }
          </mat-select>
        </mat-form-field>
      </mat-dialog-content>
    } @else if(!data.isRaw || downloadRawStep === 1) {
      <h2 mat-dialog-title class="mat-dialog-title">{{ 'DIALOG.DOWNLOAD_TITLE' | translate }}</h2>
      <mat-dialog-content>
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        <p>{{ 'DIALOG.DOWNLOAD_MESSAGE' | translate }}</p>
      </mat-dialog-content>
    }
    <mat-dialog-actions>
      <button mat-raised-button mat-dialog-close (click)="cancelDownload()">{{ 'DIALOG.CANCEL' | translate }}</button>
      @if(data.isRaw && downloadRawStep === 0) {
        <button class="select-columns-button" mat-raised-button color="primary"
                [disabled]="!csvColumnsFormControl.value.length" (click)="downloadShowedRawTableData()">
          Next
        </button>
      }
    </mat-dialog-actions>
  </div>
</ng-template>

<ng-template #copyToClipboardOptionsDialog>
  <div class="copy-to-clipboard-dialog">
    <h2 mat-dialog-title class="mat-dialog-title">Select copy options</h2>
    <mat-dialog-content>
      <div class="checkbox-container">
        <mat-checkbox [checked]="columnCopyOption"
                      (change)="columnCopyOption = $event.checked">
          Include column names in the copy
        </mat-checkbox>
        <mat-checkbox [checked]="codeCopyOption"
                      (change)="codeCopyOption = $event.checked">
          Include codes in the copy
        </mat-checkbox>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions style="display:flex; justify-content: space-between">
      <button mat-raised-button mat-dialog-close>{{ 'DIALOG.CANCEL' | translate }}</button>
      <button class="select-columns-button" mat-raised-button color="primary"
              (click)="copyToClipboardDialog?.close(); copyToClipboard()">
        Copy
      </button>
    </mat-dialog-actions>
  </div>
</ng-template>

<ng-template #tableContextualMenu>
  <div class="table-contextual-menu">
    <button class="table-contextual-menu-item" (contextmenu)="$event.preventDefault()"
            (click)="copyToClipboard(); closeContextualMenu()">
      Copy
    </button>
    <button class="table-contextual-menu-item" (contextmenu)="$event.preventDefault()"
            (click)="openCopyToClipboardDialog(copyToClipboardOptionsDialog); closeContextualMenu()">
      Copy Options
    </button>
<!--    <button class="table-contextual-menu-item" (contextmenu)="$event.preventDefault()">-->
<!--      Import File-->
<!--    </button>-->
<!--    <button class="table-contextual-menu-item" (contextmenu)="$event.preventDefault()"-->
<!--            (click)="copyToClipboard()">-->
<!--      Retrieve File-->
<!--    </button>-->
    <button class="table-contextual-menu-item" (contextmenu)="$event.preventDefault()"
            (click)="openConditionalFormattingDialog(); closeContextualMenu()">
      Conditional Formatting
    </button>
  </div>
</ng-template>

<ng-template #tableContextualMenuWithSelection>
  <div class="table-contextual-menu">
    <button class="table-contextual-menu-item" (contextmenu)="$event.preventDefault()"
            (click)="copyToClipboard(); closeContextualMenu()">
      Copy
    </button>
    <button class="table-contextual-menu-item" (contextmenu)="$event.preventDefault()"
            (click)="openCopyToClipboardDialog(copyToClipboardOptionsDialog); closeContextualMenu()">
      Copy Options
    </button>
<!--    <button class="table-contextual-menu-item disabled" (contextmenu)="$event.preventDefault()">-->
<!--      Import File-->
<!--    </button>-->
<!--    <button class="table-contextual-menu-item disabled" (contextmenu)="$event.preventDefault()">-->
<!--      Retrieve File-->
<!--    </button>-->
    <button class="table-contextual-menu-item" (contextmenu)="$event.preventDefault()"
            (click)="openConditionalFormattingDialog(); closeContextualMenu()">
      Conditional Formatting
    </button>
  </div>
</ng-template>
