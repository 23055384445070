import { IMapper } from '../../../models/i-mapper';
export class RawDataCountMapper implements IMapper {
  rawDataCount = 0;
  fillFromJson(json: any): void {
    this.rawDataCount = json;
  }
  fillToJson(): string {
    return '';
  }
}
