import { Injectable } from '@angular/core';
import { NetworkService } from 'src/app/core/net-utils/network.service';
import { ValidationModuleCodeModel, ValidationModuleModel, ValidationResponseModuleModel } from '../models/validation-module-model';
import { environment } from 'src/environments/environment';
import { ValidationModuleYearModel } from '../models/validation-module-year-model';

@Injectable({
  providedIn: 'root',
})
export class ValidationModuleService {
  private networkService!: NetworkService;
  baseUrl = `${environment.backend.endpoint}/validation-module`;

  setNetworkService(networkService: NetworkService): void {
    this.networkService = networkService;
  }

  async getValidationModuleTableListAndCount(
    _surveyID: string,
    _countryID: string[],
    _code: string[],
    _fromYear: number,
    _toYear: number,
    _sortCol: string,
    _sortType: string,
    _pageNumber: number,
    _pageSize: number
  ): Promise<ValidationResponseModuleModel> {
    // Sample validation module data with 20 items
    return this.generateMockData();
  }

  generateMockData(): ValidationResponseModuleModel {
    const countries = [
      { id: 'US', name: 'United States', iso: 'USA' },
      { id: 'IN', name: 'India', iso: 'IND' },
      { id: 'CN', name: 'China', iso: 'CHN' },
      { id: 'DE', name: 'Germany', iso: 'DEU' },
      { id: 'FR', name: 'France', iso: 'FRA' },
    ];

    const codeNames = ['CodeA', 'CodeB', 'CodeC', 'CodeD', 'CodeE'];

    const generateRandomYearData = (): ValidationModuleYearModel[] => {
      return Array.from({ length: 5 }, (_, index) => ({
        dataId: `data-${Math.random().toString(36).substring(2, 10)}`,
        year: 2022 + index,
        value: (Math.random() * 1000).toFixed(2),
        percentageChange: (Math.random() * 10 - 5).toFixed(2),
      }));
    };

    const generateCodes = (): ValidationModuleCodeModel[] => {
      return codeNames.map((codeName) => ({
        codeId: `code-${Math.random().toString(36).substring(2, 10)}`,
        codeName,
        years: generateRandomYearData(),
      }));
    };

    const data: ValidationModuleModel[] = Array.from({ length: 50 }, () => {
      const country = countries[Math.floor(Math.random() * countries.length)];
      return {
        countryId: country.id,
        countryName: country.name,
        countryISO: country.iso,
        toStart: Math.random() < 0.5,
        codes: generateCodes(),
      };
    });

    return {
      data,
      total: data.length,
    };
  }

  async updateValidationModuleCell(
    _cellID: string,
    _value: number
  ): Promise<void> {
    return;
  }
}
