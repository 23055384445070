export enum UserRoles {
  ADMIN = 'ADMIN',
  INTERNAL = 'INTERNAL',
  EXTERNAL = 'EXTERNAL',
}

export interface IUser {
  email: string;
  password: string;
  showPassword: boolean;
  code: string;
  name: string;
  role: UserRoles;
}
