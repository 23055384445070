import {
  AfterViewInit,
  Component,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { SurveyModel } from './models/survey-model';
import { ProviderService } from '../../core/provider.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AntiMemLeak } from 'src/app/core/form-utils/anti-mem-leak/anti-mem-leak';
import { debounceTime, startWith } from 'rxjs';

@Component({
  selector: 'app-survey-table',
  templateUrl: './survey-table.component.html',
  styleUrl: './survey-table.component.scss',
})
export class SurveyTableComponent extends AntiMemLeak implements AfterViewInit {
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false })
  sort!: MatSort;

  @Output() surveySelected: EventEmitter<SurveyModel> =
    new EventEmitter<SurveyModel>();
  loading = true;
  countLoading = true;
  filterSurveyFormGroup = new FormGroup({
    surveyName: new FormControl(''),
    surveyDescription: new FormControl(''),
    surveyYear: new FormControl(null),
  });
  dataSource!: MatTableDataSource<SurveyModel>;
  displayedColumns = ['surveyName', 'surveyDescription', 'surveyYear'];
  tableCount = 0;

  constructor(
    private providerService: ProviderService,
    private snackBar: MatSnackBar
  ) {
    super();
    this.dataSource = new MatTableDataSource<SurveyModel>();
  }

  ngAfterViewInit(): void {
    this.subscriptions.add(
      this.filterSurveyFormGroup.controls.surveyName.valueChanges
        .pipe(debounceTime(500))
        .subscribe((_value) => {
          this.paginator.pageIndex = 0;
          this.getTableData();
        })
    );
    this.subscriptions.add(
      this.filterSurveyFormGroup.controls.surveyDescription.valueChanges
        .pipe(debounceTime(500))
        .subscribe((_value) => {
          this.paginator.pageIndex = 0;
          this.getTableData();
        })
    );
    this.subscriptions.add(
      this.filterSurveyFormGroup.controls.surveyYear.valueChanges
        .pipe(debounceTime(500))
        .subscribe((_value) => {
          this.paginator.pageIndex = 0;
          this.getTableData();
        })
    );
    this.subscriptions.add(
      this.paginator.page.pipe(startWith(null)).subscribe((value) => {
        if (value) {
          this.getTableData();
        }
      })
    );
    this.subscriptions.add(
      this.sort.sortChange.subscribe(() => {
        this.paginator.pageIndex = 0;
        this.getTableData();
      })
    );
    this.getTableData();
  }

  selectSurvey(element: SurveyModel): void {
    this.surveySelected.emit(element);
  }

  private async getTableData(): Promise<void> {
    this.loading = true;
    try {
      let sortColumn = 'survey_name';
      switch (this.sort.active) {
        case 'surveyName':
          sortColumn = 'survey_name';
          break;
        case 'surveyDescription':
          sortColumn = 'survey_desc';
          break;
        case 'surveyYear':
          sortColumn = 'survey_year';
          break;
      }
      const backendResponse =
        await this.providerService.surveyTableService.getSurveyListAndCount(
          this.paginator.pageIndex,
          this.paginator.pageSize,
          sortColumn,
          this.sort.direction,
          this.filterSurveyFormGroup.controls.surveyName.value &&
            this.filterSurveyFormGroup.controls.surveyName.value.length > 0
            ? this.filterSurveyFormGroup.controls.surveyName.value
            : undefined,
          this.filterSurveyFormGroup.controls.surveyDescription.value &&
            this.filterSurveyFormGroup.controls.surveyDescription.value.length >
              0
            ? this.filterSurveyFormGroup.controls.surveyDescription.value
            : undefined,
          this.filterSurveyFormGroup.controls.surveyYear.value ?? undefined
        );
      this.tableCount = backendResponse.total;
      this.dataSource.data = backendResponse.data;
    } catch (e) {
      console.error(e);
      this.snackBar.open(
        'An error occured while retrieving the survey list',
        'X',
        {
          duration: 3000,
          panelClass: ['error-snackbar'],
        }
      );
    } finally {
      this.loading = false;
    }
  }
}
