<mat-form-field appearance="fill">
  <mat-label>{{ label }}</mat-label>
  <mat-select #filter id="css-matselect-fix-region" [formControl]="inputFormControl"
              (openedChange)="onOpenedChange($event)" [multiple]="multiple">
    <mat-select-trigger>
                  <span class="label" [matTooltip]="displayMultipleFn(inputFormControl.value)">
                    {{displayFn(inputFormControl.value?.[0]) || ''}}
                    @if ((inputFormControl.value?.length || 0) > 1) {
                      <span class="example-additional-selection">
                        (+{{(inputFormControl.value?.length || 0) - 1}} {{inputFormControl.value?.length === 2 ? 'other' : 'others'}})
                      </span>
                    }
                  </span>
      @if ((inputFormControl.value?.length || 0) > 0) {
        <i (click)="removeFilter($event)" class="fa fa-x clear-filter"
           [matTooltip]="'Clear filter'"></i>
      }
    </mat-select-trigger>
    <input matInput type="text" [formControl]="autocompleteFormControl"
           class="autocomplete-input"
           (keydown)="$event.stopPropagation()"
           #inputElement>
    @if (autocompleteFormControl.value === '') {
      <span class="autocomplete-placeholder">Search...</span>
    }
    @for (option of optionsData; track option) {
      <mat-option [ngClass]="visuallyFilter(option) ? '' : 'hidden'" [value]="option[uniqueField]">
        @if (singleValue) {
          {{ option[visualizedField] }}
        } @else {
          {{ option[uniqueField] }} - {{ option[visualizedField] }}
        }
      </mat-option>
    }
  </mat-select>
</mat-form-field>
