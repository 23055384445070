import {
  AfterViewInit,
  ApplicationRef,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  Renderer2,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import {
  CellHistoryModel,
  CellInfoModel,
  cellUpdatePayloadModel,
  ColumnsModel,
  CountryYearsInfoModel,
  DataModel,
  getIdPayload,
} from './models/update-country-table-models';
import { ModifyOneRowComponent } from '../dialogues/modify-one-row/modify-one-row.component';
import { ProviderService } from '../../core/provider.service';
import { CellStatus } from './enums/enums';
import { MatPaginator } from '@angular/material/paginator';
import { CountriesListsModel } from './models/country-filters-model';
import { AntiMemLeak } from '../../core/form-utils/anti-mem-leak/anti-mem-leak';
import { MatSort } from '@angular/material/sort';
import { distinctUntilChanged, skip, startWith } from 'rxjs/operators';
import { debounceTime } from 'rxjs';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import {
  ConditionalFormattingComponent,
  ConditionalFormattingOperation,
  LogicalOperators,
} from '../dialogues/conditional-formatting/conditional-formatting.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSelect } from '@angular/material/select';
import { MatChipInputEvent } from '@angular/material/chips';
import { RegionsListsModel } from '../regions-management/models/region-filters-model';
import { Constants } from '../../models/constants';
import { environment } from '../../../environments/environment';
import { CustomDownloadSnackbarComponent } from '../dialogues/snackbars/custom-download-snackbar/custom-download-snackbar.component';

interface DataSourceModel {
  dataModel: DataModel;
  formGroup: FormGroup;
}

@Component({
  selector: 'app-update-country-table',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './update-country-table.component.html',
  styleUrls: ['./update-country-table.component.scss'],
})
// eslint-disable-next-line prettier/prettier
export class UpdateCountryTableComponent extends AntiMemLeak implements OnInit, AfterViewInit {
  @ViewChild(MatTable)
  table!: MatTable<any>;
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false })
  sort!: MatSort;
  @ViewChild(MatTable, { read: ElementRef })
  matTableRef!: ElementRef;
  @ViewChild('formElement', { read: ElementRef })
  formRef!: ElementRef;
  @ViewChild('inputElement')
  inputElement!: ElementRef;
  @ViewChild(MatSelect)
  matSelect!: MatSelect;
  @ViewChild('columnInputElement')
  columnInputElement!: ElementRef;
  @ViewChild('regionInputElement')
  regionInputElement!: ElementRef;
  columns: ColumnsModel[] = [];
  stickyColumns = ['actions', 'description', 'countryName', 'code'];
  displayedColumns: string[] = [];
  tableDataSource = new MatTableDataSource<DataSourceModel>();
  countriesDataBE: DataModel[] = [];
  form: FormGroup = {} as FormGroup;
  countryFiltersFormGroup = new FormGroup({
    region: new FormControl([]),
    country: new FormControl([]),
    code: new FormControl(''),
    description: new FormControl(''),
    csvColumns: new FormControl([]),
    autocompleteForm: new FormControl(''),
    autocompleteColumn: new FormControl(''),
    autocompleteRegionForm: new FormControl(''),
  });
  countries: CountriesListsModel[] = [];
  regions: RegionsListsModel[] = [];
  yearValueChanged = false;
  loading = false;
  startYear = 2010;
  endYear = 2023;
  yearsArray: number[] = [];
  tableDataCount = 0;
  descriptionFilterEntered = '';
  codeFilterEntered: any[] = [];
  cellInfo?: CellInfoModel;
  cellHistory: CellHistoryModel[] = [];
  cellInfoLoading = false;
  cellInfoPresent = false;
  previousCell?: DataSourceModel;
  selectedCell?: DataSourceModel;
  previousColumn: any;
  selectedColumn: any;
  showTableError = false;
  selectedFileName = '';
  checkboxFormGroup = new FormGroup({
    published: new FormControl(false),
    estimated: new FormControl(false),
  });
  filteredCountries: CountriesListsModel[] = [];
  resizing = false;
  conditionThresholdValue = '';
  conditionOperation!: ConditionalFormattingOperation;
  conditions: any[] = [];
  isConditionalFormattingFilterOn = false;
  downloadTableDataLoader = false;
  logicalOperators: LogicalOperators[] = [];
  color!: string;
  downloadRawStep = 0;
  rawCountryViewDataColumns = Constants.downloadRawColumns;
  @ViewChild('inputFilterElement')
  inputFilterElement!: ElementRef;
  @ViewChild('matSelectFixRegion')
  regionSelect!: MatSelect;
  @ViewChild('publishedNote')
  publishedNoteInput!: ElementRef;
  @ViewChild('internalNote')
  internalNoteInput!: ElementRef;
  @ViewChild('sourceInput')
  sourceInput!: ElementRef;
  filters: string[] = [];
  separatorKeysCodes: number[] = [13, 32, 188];
  addOnBlur = true;
  hasCompletedFilters = false;
  countriesFromSelectedRegions: any[] = [];
  fetchingTableDataCount = true;
  isRemovingChip = false;
  x1 = 0;
  y1 = 0;
  x2 = 0;
  y2 = 0;
  isDoingRectangularSelection = false;
  selectedStartingRow!: number;
  selectedStartingYear = this.startYear;
  selectedEndRow!: number;
  selectedEndYear = this.endYear;
  @ViewChild('rectangularSelection')
  rectangularSelection!: ElementRef;
  areCellsSelectedWithRectangularSelection = false;
  copyToClipboardDialog: any;
  columnCopyOption = false;
  codeCopyOption = false;
  @ViewChild('tableContextualMenu', { static: true })
  menuTemplate!: TemplateRef<any>;
  contextualMenuVisible = false;
  hostElem!: any;
  contextualMenuElem!: any;

  @HostListener('document:mousedown', ['$event'])
  onMouseDown(e: MouseEvent): void {
    if (e.button !== 2) {
      const countryViewTable = document.querySelector('table');
      const buttons = document.querySelectorAll('button');
      const dataInfo = document.querySelectorAll('.data-info');
      const copyDialog = document.querySelectorAll('.copy-to-clipboard-dialog');
      const contextualMenu = document.querySelectorAll(
        '.table-contextual-menu'
      );
      if (
        !(countryViewTable && countryViewTable.contains(e.target as Node)) &&
        !(
          copyDialog &&
          Array.from(copyDialog).some((c) => c.contains(e.target as Node))
        ) &&
        !(
          buttons &&
          Array.from(buttons).some((c) => c.contains(e.target as Node))
        ) &&
        !(
          dataInfo &&
          Array.from(dataInfo).some((c) => c.contains(e.target as Node))
        ) &&
        !(
          contextualMenu &&
          Array.from(contextualMenu).some((c) => c.contains(e.target as Node))
        )
      ) {
        this.resetStartingEndRowYear();
        this.unselectCell();
      }
      if (
        !(
          contextualMenu &&
          Array.from(contextualMenu).some((c) => c.contains(e.target as Node))
        )
      ) {
        this.closeContextualMenu();
      }
    }
  }

  @HostListener('document:mouseup', ['$event'])
  onMouseUp(e: MouseEvent): void {
    if (e.button !== 2) {
      const countryViewTable = document.querySelectorAll('.selectable-cell');
      const buttons = document.querySelectorAll('button');
      const dataInfo = document.querySelectorAll('.data-info');
      const copyDialog = document.querySelectorAll('.copy-to-clipboard-dialog');
      const contextualMenu = document.querySelectorAll(
        '.table-contextual-menu'
      );
      if (
        !(
          countryViewTable &&
          Array.from(countryViewTable).some((c) => c.contains(e.target as Node))
        ) &&
        !(
          copyDialog &&
          Array.from(copyDialog).some((c) => c.contains(e.target as Node))
        ) &&
        !(
          buttons &&
          Array.from(buttons).some((c) => c.contains(e.target as Node))
        ) &&
        !(
          dataInfo &&
          Array.from(dataInfo).some((c) => c.contains(e.target as Node))
        ) &&
        !(
          contextualMenu &&
          Array.from(contextualMenu).some((c) => c.contains(e.target as Node))
        )
      ) {
        this.resetStartingEndRowYear();
        this.rectangularSelection.nativeElement.hidden = true;
        this.x1 = 0;
        this.y1 = 0;
        this.x2 = 0;
        this.y2 = 0;
        this.isDoingRectangularSelection = false;
        this.highlightCellsBehindRectangularSelection();
      }
    }
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(e: MouseEvent): void {
    if (this.isDoingRectangularSelection) {
      if (e.buttons === 1) {
        this.rectangularSelection.nativeElement.hidden = false;
        this.x2 = e.clientX;
        this.y2 = e.clientY;
        this.reCalculateRectangle();
      }
    }
  }

  @HostListener('window:keydown.control.c', ['$event'])
  @HostListener('window:keydown.meta.c', ['$event'])
  async copyToClipboardShortcut(e: KeyboardEvent): Promise<void> {
    if (this.areCellsSelectedWithRectangularSelection) {
      e.preventDefault();
      await this.copyToClipboard();
    }
  }

  private currentCell?: CountryYearsInfoModel;
  private internalNote?: string;
  private note?: string;
  private columnUnderModification = '';
  private source?: string;
  private downloadDialog: any;
  private removeChipInterval?: any;
  private removeChipTimeout?: any;

  constructor(
    private formBuilder: FormBuilder,
    private _formBuilder: FormBuilder,
    private providerService: ProviderService,
    private changeDetectorRef: ChangeDetectorRef,
    private applicationRef: ApplicationRef,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private renderer: Renderer2,
    private el: ElementRef
  ) {
    super();
  }

  get countriesFormControl(): FormControl {
    return this.countryFiltersFormGroup.controls.country;
  }

  get csvColumnsFormControl(): FormControl {
    return this.countryFiltersFormGroup.controls.csvColumns;
  }

  get regionsFormControl(): FormControl {
    return this.countryFiltersFormGroup.controls.region;
  }

  ngOnInit(): void {
    this.initTableWithItuClassCountries();
  }

  ngAfterViewInit(): void {
    this.subscriptions.add(
      this.countryFiltersFormGroup.controls.autocompleteForm.valueChanges
        .pipe(distinctUntilChanged(), debounceTime(100))
        .subscribe((countryCode) => {
          this.paginator.pageIndex = 0;
          this._filter(countryCode);
        })
    );
    this.subscriptions.add(
      this.countryFiltersFormGroup.controls.description.valueChanges
        .pipe(debounceTime(500), distinctUntilChanged())
        .subscribe((value) => {
          this.paginator.pageIndex = 0;
          this.descriptionFilterEntered = value ?? '';
          this.getTableData();
        })
    );
    this.subscriptions.add(
      this.paginator.page.pipe(startWith(null)).subscribe((value) => {
        if (value) {
          this.getTableData(true);
        }
      })
    );
    this.subscriptions.add(
      this.sort.sortChange.subscribe(() => {
        this.paginator.pageIndex = 0;
        this.getTableData(true);
      })
    );
    this.subscriptions.add(
      this.regionsFormControl.valueChanges
        .pipe(skip(1), debounceTime(1000))
        .subscribe(() => {
          this.paginator.pageIndex = 0;
          this.regionFilterSelectionChange();
        })
    );
    this.subscriptions.add(
      this.countriesFormControl.valueChanges
        .pipe(skip(1), debounceTime(1000))
        .subscribe(() => {
          this.paginator.pageIndex = 0;
          this.getTableData();
        })
    );
  }

  tableTrackBy(index: number, data: DataSourceModel): string {
    return data.dataModel.countryId + data.dataModel.codeId;
  }

  displayFn(value: any): string {
    return value
      ? this.countries.find((country) => country.isoCode === value)
          ?.shortName ?? ''
      : '';
  }

  displayRegionFn(value: any): string {
    return value
      ? this.regions.find((region) => region.regionID === value)?.regionName ??
          ''
      : '';
  }

  displayMultipleRegionFn(value: any): string {
    let result = '';
    let index = 0;
    for (const v of value) {
      index++ === 0
        ? (result = `${this.displayRegionFn(v)}`)
        : (result = `${result}, ${this.displayRegionFn(v)}`);
    }
    return result;
  }

  displayMultipleFn(value: any): string {
    let result = '';
    let index = 0;
    for (const v of value) {
      index++ === 0
        ? (result = `${this.displayFn(v)}`)
        : (result = `${result}, ${this.displayFn(v)}`);
    }
    return result;
  }

  async getCellInfo(
    cellDataSource: DataSourceModel,
    selectedYear: string
  ): Promise<void> {
    if (
      this.selectedCell !== this.previousCell ||
      this.selectedColumn !== this.previousColumn
    ) {
      this.cellInfo = undefined;
      this.cellInfoPresent = true;
      const cellID =
        cellDataSource.formGroup.controls[`id-${selectedYear}`].value;
      if (cellID && cellID.length > 0) {
        this.getCellHistory(cellID);
        this.cellInfoLoading = true;
        try {
          this.currentCell = {
            id: cellDataSource.formGroup.controls[`id-${selectedYear}`].value,
            year: selectedYear,
            value: cellDataSource.formGroup.controls[selectedYear].value,
          };
          this.cellInfo =
            await this.providerService.countryTableService.getCellInfo(
              cellID,
              'country-view'
            );
          this.note = this.cellInfo.publishNotes;
          this.internalNote = this.cellInfo.internalNotes;
          this.source = this.cellInfo.source;
        } catch (e) {
          console.error(e);
        } finally {
          this.cellInfoLoading = false;
        }
        this.checkboxFormGroup.controls.estimated.setValue(
          this.cellInfo?.estimated ?? false
        );
        this.checkboxFormGroup.controls.published.setValue(
          this.cellInfo?.published ?? false
        );
      } else {
        this.currentCell = {
          id: '',
          year: selectedYear,
          value: '',
        };
        this.checkboxFormGroup.controls.estimated.setValue(false);
        this.checkboxFormGroup.controls.published.setValue(false);
        this.cellInfo = {
          internalNotes: '',
          publishNotes: '',
          published: false,
          estimated: false,
          source: '',
          sourceId: '',
        };
        this.cellHistory = [];
        this.cellInfoPresent = false;
      }
    }
    this.changeDetectorRef.detectChanges();
  }

  async deleteSource(
    cellIsEmpty: boolean,
    sourceValue?: string
  ): Promise<void> {
    const deleteSourceByUser =
      sourceValue !== undefined && sourceValue.length === 0 && !cellIsEmpty;
    if (
      deleteSourceByUser &&
      this.cellInfo &&
      this.cellInfo.sourceId !== null
    ) {
      this.cellInfoLoading = true;
      const sourceId = [this.cellInfo.sourceId];
      await this.providerService.countryTableService.deleteSourceById(sourceId);
      this.cellInfoLoading = false;
    }
    if (cellIsEmpty && this.cellInfo && this.cellInfo.sourceId !== null) {
      const sourceId = [this.cellInfo.sourceId];
      await this.providerService.countryTableService.deleteSourceById(sourceId);
    }
  }

  async getCellHistory(cellId: string): Promise<void> {
    this.cellInfoLoading = true;
    try {
      this.cellHistory = (
        await this.providerService.countryTableService.getCellHistory(cellId)
      ).sort((one, two) => two.version - one.version);
      this.cellHistory.map((el) => {
        el.lastModifiedOn = this.providerService.utilService.humanReadableDate(
          el.lastModifiedOn,
          true
        );
      });
    } catch (e) {
      console.error(e);
    } finally {
      this.cellInfoLoading = false;
    }
    this.changeDetectorRef.detectChanges();
  }

  buildFormGroupsArrayDynamicallyUsingBEData(
    countriesDataBE: DataModel[]
  ): DataSourceModel[] {
    const result: DataSourceModel[] = [];
    this.form = this.formBuilder.group({
      formGroups: this.formBuilder.array(
        countriesDataBE.map((BEData: DataModel) => {
          const group = this.formBuilder.group({});
          group.addControl('code', new FormControl(BEData.code));
          group.addControl('description', new FormControl(BEData.description));
          group.addControl('codeId', new FormControl(BEData.codeId));
          group.addControl('countryId', new FormControl(BEData.countryId));
          group.addControl('countryName', new FormControl(BEData.countryName));
          for (const year of BEData.years) {
            group.addControl('id-' + year.year, new FormControl(year.id));
            group.addControl(
              year.year,
              new FormControl(
                year.value !== null
                  ? this.changeValueVisualization(year.value ?? '')
                  : '',
                [Validators.pattern(/^[0-9,.-]+$/)]
              )
            );
          }
          const yearsToAdd = this.yearsArray.filter(
            (value) =>
              !BEData.years.map((year) => year.year).includes(value.toString())
          );
          for (const notFoundYear of yearsToAdd) {
            group.addControl('id-' + notFoundYear, new FormControl(null));
            group.addControl(
              notFoundYear.toString(),
              new FormControl(null, [Validators.pattern(/^[0-9,.-]+$/)])
            );
          }
          result.push({
            dataModel: BEData,
            formGroup: group,
          });
          return group;
        })
      ),
    });
    return result;
  }
  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.setColumnSizeOnResize(this.matTableRef.nativeElement.clientWidth);
  }
  onFileSelected(event: Event): void {
    const target = event.target as HTMLInputElement;
    if (target !== null && target.files) {
      // noinspection TypeScriptValidateTypes
      this.selectedFileName =
        target.files && target.files.length > 0 ? target.files[0].name : '';
    }
  }
  changeValueVisualization(cellValue: string): string {
    const beforeDot = cellValue.toString().split('.')[0];
    const afterDot = cellValue.toString().split('.')[1];
    const newString =
      beforeDot.length > 3
        ? beforeDot.replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
          (afterDot !== undefined ? `.${afterDot}` : '.00')
        : cellValue;
    return newString.toString();
  }
  buildArrayForYears(startYear: number, endYear: number): void {
    const array: number[] = [];
    for (let i = startYear; i <= endYear; i++) {
      array.push(i);
    }
    this.yearsArray = array;
  }

  async getCountriesList(): Promise<void> {
    try {
      this.countries = this.countries
        .concat(
          await this.providerService.regionsManagementService.getCountriesByRegions(
            this.regions.map((value) => value.regionID)
          )
        )
        .sort((one, two) => (one.isoCode < two.isoCode ? -1 : 1));
      this.filteredCountries = this.countries;
    } catch (error) {
      console.error(error);
    }
  }

  async getRegionList(): Promise<void> {
    try {
      this.regions = this.regions
        .concat(
          await this.providerService.regionsManagementService.getRegionsList(
            environment.ituClassId
          )
        )
        .sort((one, two) => (one.regionCode < two.regionCode ? -1 : 1));
    } catch (error) {
      console.error(error);
    }
  }

  setDisplayedColumns(): void {
    if (this.displayedColumns.length > 0) {
      return;
    }
    this.displayedColumns = [];
    new Set(this.columns.map((value) => value.field)).forEach((c) => {
      this.displayedColumns.push(c);
    });
    this.displayedColumns =
      this.providerService.countryTableService.reorderColumns(
        this.displayedColumns
      );
  }

  fillTableColumns(): void {
    if (this.columns.length > 0) {
      return;
    }
    this.columns = [
      {
        title: 'Actions',
        field: 'actions',
        width: 50,
        sortable: false,
        readOnly: true,
        status: CellStatus.running,
      },
    ];
    Object.keys(this.countriesDataBE[0]).map((k: any) => {
      switch (k) {
        case 'code': {
          this.columns.push({
            title: 'Code',
            field: k,
            width: 50,
            sortable: false,
            readOnly: true,
            status: CellStatus.running,
          });
          break;
        }
        case 'description': {
          this.columns.push({
            title: 'Description',
            field: k,
            width: 800,
            sortable: false,
            readOnly: true,
            status: CellStatus.running,
          });
          break;
        }
        case 'countryName': {
          this.columns.push({
            title: 'Country Name',
            field: k,
            width: 50,
            sortable: true,
            readOnly: true,
            status: CellStatus.running,
          });
          break;
        }
        case 'countryId': {
          this.columns.push({
            title: 'Country Id',
            field: k,
            width: 800,
            sortable: false,
            readOnly: true,
            status: CellStatus.running,
          });
          break;
        }
        case 'codeId': {
          this.columns.push({
            title: 'Code Id',
            field: k,
            width: 80,
            sortable: false,
            readOnly: true,
            status: CellStatus.running,
          });
          break;
        }
        default: {
          if (k === 'years') {
            this.yearsArray.forEach((y) => {
              this.columns.push({
                title: y.toString(),
                field: y.toString(),
                width: 300,
                sortable: false,
                readOnly: true,
                status: CellStatus.running,
              });
            });
          }
          break;
        }
      }
    });
  }

  async getTableCount(): Promise<void> {
    const filters = {
      codeFilter:
        this.codeFilterEntered.length === 0 ? '' : this.codeFilterEntered,
      countryCodeFilter: this.countriesFormControl.value,
      codeDescriptionFilter:
        this.countryFiltersFormGroup.controls.description.value,
    };
    try {
      this.fetchingTableDataCount = true;
      this.tableDataCount =
        await this.providerService.countryTableService.tableCount(
          this.startYear,
          this.endYear,
          btoa(JSON.stringify(filters))
        );
      this.fetchingTableDataCount = false;
    } catch (error) {
      console.error(error);
    }
    this.changeDetectorRef.detectChanges();
  }

  async updateCell(
    cellDataSource: DataSourceModel,
    selectedYear: string,
    getCellInfo?: boolean
  ): Promise<void> {
    this.yearValueChanged = true;
    this.columnUnderModification = selectedYear;

    if (cellDataSource.formGroup.controls[selectedYear].valid) {
      const isCellIdNull =
        cellDataSource.formGroup.controls[`id-${this.columnUnderModification}`]
          .value === null;
      const unmodifiedCountryBE = this.findUnmodifiedData(cellDataSource);
      const modifiedValueFE =
        cellDataSource.formGroup.controls[this.columnUnderModification].value;
      const canInsert = unmodifiedCountryBE.length === 0;
      const canUpdateCell =
        modifiedValueFE !== null &&
        modifiedValueFE.length >= 0 &&
        this.checkIfYearValueChanged(
          cellDataSource,
          selectedYear,
          unmodifiedCountryBE
        );
      const isFEValueEmpty =
        cellDataSource.formGroup.controls[selectedYear].value === '';
      const isBEValueNull =
        unmodifiedCountryBE.length !== 0
          ? unmodifiedCountryBE[0][0].value === null
          : true;
      if (isCellIdNull && modifiedValueFE && canInsert) {
        const payloadForInsertID =
          this.preparePayLoadForGettingId(cellDataSource);
        const returnedId =
          await this.providerService.countryTableService.insertID(
            payloadForInsertID
          );
        cellDataSource.formGroup.controls[
          `id-${this.columnUnderModification}`
        ].setValue(returnedId);
        cellDataSource.formGroup.controls[
          this.columnUnderModification
        ].setValue(this.changeValueVisualization(modifiedValueFE));
        const rowFound = this.countriesDataBE.find(
          (dataModel) =>
            dataModel.countryId == cellDataSource.dataModel.countryId &&
            dataModel.codeId == cellDataSource.dataModel.codeId
        );
        if (rowFound) {
          const foundYear = rowFound.years.find(
            (year) => year.year === selectedYear
          );
          if (!foundYear) {
            rowFound.years.push({
              id: returnedId,
              year: selectedYear,
              value:
                cellDataSource.formGroup.controls[this.columnUnderModification]
                  .value,
            });
          } else {
            foundYear.id = returnedId;
          }
        }
      } else if (canUpdateCell) {
        try {
          if (isFEValueEmpty && this.cellInfo && isBEValueNull !== null) {
            this.deleteSource(true);
            this.updatePublishedNotes('');
            this.updateInternalNotes('');
            this.estimatedChanged({ checked: false } as MatCheckboxChange);
            this.publishedChanged({ checked: false } as MatCheckboxChange);
          }
          const payload = this.prepareUpdateCellPayload(cellDataSource);
          await this.providerService.countryTableService.updateCell(payload);
          this.updateCountriesDataForUser(modifiedValueFE, cellDataSource);
        } catch (e: any) {
          // this.providerService.utilService.showMessage(e, LogLevel.error);
        }
      }
    } else {
      const unmodifiedCountryBE = this.findUnmodifiedData(cellDataSource);
      if (
        unmodifiedCountryBE &&
        unmodifiedCountryBE.length > 0 &&
        unmodifiedCountryBE[0][0]
      ) {
        cellDataSource.formGroup.controls[selectedYear].setValue(
          unmodifiedCountryBE[0][0].value,
          { emitEvent: false }
        );
      }
    }
    if (getCellInfo) {
      this.getCellInfo(cellDataSource, selectedYear);
    }
    this.changeDetectorRef.detectChanges();
  }

  getCellIds(selectedRow: FormGroup): string[] {
    return Object.keys(selectedRow.controls).map((k: any) => {
      if (k.includes('id')) {
        return selectedRow.controls[k].value;
      }
    });
  }

  prepareUpdateCellPayload(
    modifiedCountryFE: DataSourceModel
  ): cellUpdatePayloadModel {
    const isFEValueEmpty =
      modifiedCountryFE.formGroup.controls[this.columnUnderModification].value
        .length === 0;
    const FEValue =
      modifiedCountryFE.formGroup.controls[this.columnUnderModification].value;
    let payload: cellUpdatePayloadModel;
    !isFEValueEmpty
      ? (payload = {
          id: modifiedCountryFE.formGroup.controls[
            `id-${this.columnUnderModification}`
          ].value,
          value: this.removeCommasAndDots(FEValue),
        })
      : (payload = {
          id: modifiedCountryFE.formGroup.controls[
            `id-${this.columnUnderModification}`
          ].value,
        });
    return payload;
  }

  updateCountriesDataForUser(
    modifiedValueFE: string,
    modifiedCountryFE: DataSourceModel
  ): void {
    const unmodifiedBE = this.findUnmodifiedData(modifiedCountryFE);
    const formattedModifiedValue = this.removeCommasAndDots(modifiedValueFE);
    if (unmodifiedBE) {
      this.countriesDataBE.map((el) => {
        let year = el.years.find((y) => y.id === unmodifiedBE[0][0].id);
        const yearIndex = el.years.findIndex(
          (y) => y.id === unmodifiedBE[0][0].id
        );
        if (year) {
          year = {
            id: year.id,
            year: year.year,
            value: modifiedValueFE !== '' ? formattedModifiedValue : null,
          };
          el.years[yearIndex] = year;
        }
      });
      modifiedCountryFE.formGroup.controls[
        this.columnUnderModification
      ].setValue(this.changeValueVisualization(formattedModifiedValue));
    }
  }

  preparePayLoadForGettingId(cellDataSource: DataSourceModel): getIdPayload {
    return {
      codeId: cellDataSource.formGroup.controls['codeId'].value,
      countryId: cellDataSource.formGroup.controls['countryId'].value,
      year: Number(this.columnUnderModification),
      value: Number(
        cellDataSource.formGroup.controls[this.columnUnderModification].value
      ),
    };
  }

  findUnmodifiedData(
    cellDataSource: DataSourceModel
  ): CountryYearsInfoModel[][] {
    return this.countriesDataBE
      .map((el) =>
        el.years.filter(
          (el: any) =>
            el.id ===
            cellDataSource.formGroup.controls[
              `id-${this.columnUnderModification}`
            ].value
        )
      )
      .filter((el) => el.length !== 0);
  }

  checkIfYearValueChanged(
    cellDataSource: DataSourceModel,
    column: string,
    unmodifiedCountryBE: CountryYearsInfoModel[][]
  ): boolean {
    const valueWithNoCommas = this.removeCommasAndDots(
      cellDataSource.formGroup.controls[column].value
    );
    if (
      cellDataSource.formGroup.controls[column].value.length === 0 &&
      unmodifiedCountryBE[0][0].value === null
    ) {
      return false;
    }
    if (
      cellDataSource.formGroup.controls[column].value.length === 0 &&
      unmodifiedCountryBE[0][0].value !== null
    ) {
      return true;
    } else {
      return unmodifiedCountryBE[0] &&
        unmodifiedCountryBE[0].length !== 0 &&
        cellDataSource.formGroup.controls[column].value !== ''
        ? Number(unmodifiedCountryBE[0][0].value) !== Number(valueWithNoCommas)
        : false;
    }
  }

  removeCommasAndDots(numberString: string): string {
    if (numberString) {
      return numberString
        .toString()
        .replace(/,|\.00$/g, '')
        .trim();
    }
    return '';
  }

  setColumnSizeOnResize(tableWidth: number): void {
    this.providerService.countryTableService.setTableResize(
      this.columns,
      this.displayedColumns,
      tableWidth,
      'mat-column-'
    );
  }

  openModificationDialog(formGroup: FormGroup): void {
    this.providerService.utilService.openDialog(ModifyOneRowComponent, {
      data: formGroup,
      cellIds: this.getCellIds(formGroup),
      columns: this.columns,
      callback: () => {
        this.getTableData();
      },
    });
  }

  openConditionalFormattingDialog(): void {
    let config = {};
    if (this.isConditionalFormattingFilterOn) {
      config = {
        conditions: this.conditions,
        logicalOperators: this.logicalOperators,
        allCountries: this.countries,
        color: this.color,
      };
    }
    this.dialog
      .open(
        ConditionalFormattingComponent,
        this.isConditionalFormattingFilterOn
          ? { data: config }
          : {
              data: {
                allCountries: this.countries,
              },
            }
      )
      .afterClosed()
      .subscribe((response) => {
        if (response) {
          this.isConditionalFormattingFilterOn = true;
          this.conditions = [];
          for (const res of response.values) {
            this.conditions.push({
              conditionThresholdValue: res.inputValue,
              conditionOperation: res.conditionOperation,
              conditionCodes: res.codes,
              conditionCountries: res.countries,
            });
          }
          this.logicalOperators = response.logicalOperators;
          this.color = response.color;
          this.changeDetectorRef.detectChanges();
        }
      });
  }

  checkConditions(value: string, cell: DataSourceModel): boolean {
    const code = cell.dataModel.code;
    const country = cell.dataModel.countryName;
    const conditionResults = [];
    const valueNum = parseFloat(this.removeCommasAndDots(value));
    for (const condition of this.conditions) {
      const conditionThresholdValue = parseFloat(
        condition.conditionThresholdValue
      );
      if (
        condition.conditionOperation === ConditionalFormattingOperation.EQUAL
      ) {
        conditionResults.push(
          valueNum === conditionThresholdValue &&
            (condition.conditionCountries.length !== 0
              ? condition.conditionCountries.includes(country)
              : true) &&
            (condition.conditionCodes.length !== 0
              ? this.checkConditionCode(condition.conditionCodes, code)
              : true)
        );
      } else if (
        condition.conditionOperation ===
        ConditionalFormattingOperation.NOT_EQUAL
      ) {
        conditionResults.push(
          valueNum !== conditionThresholdValue &&
            (condition.conditionCountries.length !== 0
              ? condition.conditionCountries.includes(country)
              : true) &&
            (condition.conditionCodes.length !== 0
              ? this.checkConditionCode(condition.conditionCodes, code)
              : true)
        );
      } else if (
        condition.conditionOperation === ConditionalFormattingOperation.GREATER
      ) {
        conditionResults.push(
          valueNum > conditionThresholdValue &&
            (condition.conditionCountries.length !== 0
              ? condition.conditionCountries.includes(country)
              : true) &&
            (condition.conditionCodes.length !== 0
              ? this.checkConditionCode(condition.conditionCodes, code)
              : true)
        );
      } else if (
        condition.conditionOperation ===
        ConditionalFormattingOperation.GREATER_OR_EQUAL
      ) {
        conditionResults.push(
          valueNum >= conditionThresholdValue &&
            (condition.conditionCountries.length !== 0
              ? condition.conditionCountries.includes(country)
              : true) &&
            (condition.conditionCodes.length !== 0
              ? this.checkConditionCode(condition.conditionCodes, code)
              : true)
        );
      } else if (
        condition.conditionOperation === ConditionalFormattingOperation.LESS
      ) {
        conditionResults.push(
          valueNum < conditionThresholdValue &&
            (condition.conditionCountries.length !== 0
              ? condition.conditionCountries.includes(country)
              : true) &&
            (condition.conditionCodes.length !== 0
              ? this.checkConditionCode(condition.conditionCodes, code)
              : true)
        );
      } else if (
        condition.conditionOperation ===
        ConditionalFormattingOperation.LESS_OR_EQUAL
      ) {
        conditionResults.push(
          valueNum <= conditionThresholdValue &&
            (condition.conditionCountries.length !== 0
              ? condition.conditionCountries.includes(country)
              : true) &&
            (condition.conditionCodes.length !== 0
              ? this.checkConditionCode(condition.conditionCodes, code)
              : true)
        );
      }
    }
    let result = conditionResults[0];
    for (let i = 0; i < this.logicalOperators.length; i++) {
      switch (this.logicalOperators[i]) {
        case LogicalOperators.AND:
          result = result && conditionResults[i + 1];
          break;
        case LogicalOperators.OR:
          result = result || conditionResults[i + 1];
          break;
        default:
          throw new Error(`Invalid operator: ${this.logicalOperators[i]}`);
      }
    }
    return (
      value !== '' &&
      value !== undefined &&
      value !== null &&
      result &&
      this.isConditionalFormattingFilterOn
    );
  }

  checkConditionCode(conditionCodes: any, code: any): boolean {
    return conditionCodes.some((conditionCode: any) => {
      if (conditionCode.includes('*')) {
        const regex = new RegExp(
          '^' + conditionCode.replace(/\*/g, '.*') + '$'
        );
        return regex.test(code);
      } else {
        return conditionCode === code;
      }
    });
  }

  clearConditionalFormattingFilter(): void {
    this.logicalOperators = [];
    this.conditions = [];
    this.color = '#ffd580';
    this.isConditionalFormattingFilterOn = false;
  }

  async updateInternalNotes(emptyNote?: string): Promise<void> {
    if (
      this.currentCell &&
      this.internalNote &&
      emptyNote === undefined &&
      this.cellInfo?.internalNotes !== this.internalNote
    ) {
      await this.providerService.countryTableService.updateInternalNotes(
        this.currentCell.id,
        this.internalNote
      );
    }
    if (
      emptyNote !== undefined &&
      this.currentCell &&
      this.cellInfo?.internalNotes !== this.internalNote
    ) {
      await this.providerService.countryTableService.updateInternalNotes(
        this.currentCell.id,
        emptyNote
      );
    }
    this.changeDetectorRef.detectChanges();
  }

  async updatePublishedNotes(emptyNote?: string): Promise<void> {
    if (
      this.currentCell &&
      this.note &&
      emptyNote === undefined &&
      this.cellInfo?.publishNotes !== this.note
    ) {
      await this.providerService.countryTableService.updatePublishedNotes(
        this.currentCell.id,
        this.note
      );
    }
    if (
      emptyNote !== undefined &&
      this.currentCell &&
      this.cellInfo?.publishNotes !== this.note
    ) {
      await this.providerService.countryTableService.updatePublishedNotes(
        this.currentCell.id,
        emptyNote
      );
    }
    this.changeDetectorRef.detectChanges();
  }

  internalNotesSelectionChanged($event: any): void {
    this.internalNote = $event.target.value;
  }

  notesSelectionChanged($event: any): void {
    this.note = $event.target.value;
  }

  sourceSelectionChanged($event: any): void {
    this.source = $event.target.value;
  }

  async updateSource(): Promise<void> {
    if (
      this.currentCell &&
      this.source &&
      this.source.length > 0 &&
      this.cellInfo?.source !== this.source
    ) {
      await this.providerService.countryTableService.updateSource(
        this.currentCell.id,
        this.source,
        this.cellInfo?.sourceId
      );
    }
  }

  async publishedChanged($event: MatCheckboxChange): Promise<void> {
    if (this.currentCell) {
      await this.providerService.countryTableService.updatePublished(
        this.currentCell.id,
        $event.checked
      );
      this.checkboxFormGroup.controls.published.setValue($event.checked);
    }
  }

  async estimatedChanged($event: MatCheckboxChange): Promise<void> {
    if (this.currentCell) {
      await this.providerService.countryTableService.updateEstimated(
        this.currentCell.id,
        $event.checked
      );
      this.checkboxFormGroup.controls.estimated.setValue($event.checked);
    }
  }

  setSelectedCell(element: DataSourceModel, column: any, currentTD: any): void {
    this.previousCell = this.selectedCell;
    this.previousColumn = this.selectedColumn;
    this.selectedCell = element;
    this.selectedColumn = column;
    this.changeDetectorRef.detectChanges();
    currentTD.children[0].children[0].focus();
  }

  async initTableWithItuClassCountries(): Promise<void> {
    this.loading = true;
    await this.getRegionList();
    await this.getCountriesList();
    const uniqueCountries = this.countries.filter(
      (value, index, self) =>
        index === self.findIndex((t) => t.countryID === value.countryID)
    );
    this.countriesFromSelectedRegions = uniqueCountries;
    this.countriesFormControl.setValue(
      uniqueCountries.map((c: any) => c.isoCode)
    );
    this.regionsFormControl.setValue(this.regions.map((i) => i.regionID));
    // CSS fix
    document.getElementById('css-matselect-fix')?.click();
    document.getElementById('css-matselect-fix-region')?.click();
    this.matSelect.close();
    await this.getTableData();
  }

  async getTableData(skipCount?: boolean): Promise<void> {
    this.loading = true;
    this.showTableError = false;
    this.changeDetectorRef.detectChanges();
    try {
      this.countryFiltersFormGroup.controls.autocompleteForm.setValue('');
      this.countryFiltersFormGroup.controls.autocompleteRegionForm.setValue('');
      this.inputElement.nativeElement.focus();
      const filters = {
        codeFilter:
          this.codeFilterEntered.length === 0 ? '' : this.codeFilterEntered,
        countryCodeFilter: this.countriesFormControl.value,
        codeDescriptionFilter:
          this.countryFiltersFormGroup.controls.description.value,
      };
      if (!skipCount) {
        this.getTableCount();
      }
      this.countriesDataBE =
        await this.providerService.countryTableService.getTableData(
          this.startYear,
          this.endYear,
          this.sort.direction.toUpperCase() !== ''
            ? this.sort.active ?? 'code'
            : 'code',
          btoa(JSON.stringify(filters)),
          this.sort.direction.toUpperCase() !== ''
            ? this.sort.direction.toUpperCase()
            : 'ASC',
          this.paginator.pageIndex,
          this.paginator.pageSize
        );
      if (this.countriesDataBE.length !== 0) {
        this.buildArrayForYears(this.startYear, this.endYear);
        this.form = this._formBuilder.group({
          formGroups: this._formBuilder.array([]),
        });
        this.fillTableColumns();
        this.setDisplayedColumns();
        this.tableDataSource.data =
          this.buildFormGroupsArrayDynamicallyUsingBEData(this.countriesDataBE);
        this.selectedEndRow = this.tableDataSource.filteredData.length;
      }
    } catch (e) {
      console.error(e);
      this.showTableError = true;
    } finally {
      this.loading = false;
      this.changeDetectorRef.detectChanges();
      this.setFormScroll();
    }
  }

  resizingChanged(event: boolean): void {
    this.resizing = event;
    this.table.updateStickyColumnStyles();
    this.changeDetectorRef.detectChanges();
  }

  async getResultTableData(isRaw: boolean): Promise<any> {
    const filters = {
      codeFilter:
        this.codeFilterEntered.length === 0 ? '' : this.codeFilterEntered,
      countryCodeFilter: this.countriesFormControl.value,
      codeDescriptionFilter:
        this.countryFiltersFormGroup.controls.description.value,
    };
    const downloadDataParams: any = {
      filters: Object.keys(filters).length ? btoa(JSON.stringify(filters)) : '',
    };
    downloadDataParams.fromYear = this.startYear.toString();
    downloadDataParams.toYear = this.endYear.toString();
    if (isRaw) {
      if (this.csvColumnsFormControl.value.includes('All columns')) {
        downloadDataParams.columns = JSON.stringify(
          this.rawCountryViewDataColumns
            .map((v: any) => v.value)
            .filter((v: string) => v !== 'All columns')
        );
      } else {
        downloadDataParams.columns = JSON.stringify(
          this.csvColumnsFormControl.value
        );
      }
      const count =
        await this.providerService.countryTableService.getRawDataCountCountryUpdate(
          downloadDataParams
        );
      if (count > environment.maxRawDownloadCount) {
        throw new Error('Surpassed max count');
      }
      return await this.providerService.downloadDataService.downloadRawTableData(
        downloadDataParams
      );
    } else {
      downloadDataParams.sortCol = 'code';
      downloadDataParams.sortType = 'ASC';
      if (this.tableDataCount > environment.maxDownloadCount) {
        throw new Error('Surpassed max count');
      }
      return await this.providerService.downloadDataService.downloadTableData(
        downloadDataParams
      );
    }
  }

  async downloadShowedRawTableData(): Promise<void> {
    try {
      this.downloadRawStep = 1;
      try {
        await this.getResultTableData(true);
      } catch (e) {
        this.downloadDialog?.close();
        this.downloadTableDataLoader = false;
        this.snackBar.open(
          'You are trying to download more data than is permitted, you might have too many filters on',
          'X',
          {
            duration: 10000,
            panelClass: ['error-snackbar'],
          }
        );
        return;
      }
      this.downloadDialog.close();
      if (this.downloadTableDataLoader) {
        this.snackBar.openFromComponent(CustomDownloadSnackbarComponent, {
          duration: 10000,
          panelClass: ['custom-download-snackbar'],
        });
        this.downloadDialog?.close();
      }
      this.downloadTableDataLoader = false;
    } catch (e) {
      this.downloadDialog?.close();
      this.downloadTableDataLoader = false;
      this.snackBar.open('An error occured while downloading the file', 'X', {
        duration: 3000,
        panelClass: ['error-snackbar'],
      });
    } finally {
      this.downloadRawStep = 0;
    }
  }

  async downloadShowedTableData(dialogRef: any, isRaw: boolean): Promise<any> {
    try {
      this.downloadTableDataLoader = true;
      this.downloadDialog = this.dialog.open(dialogRef, {
        width: '500px',
        disableClose: true,
        autoFocus: false,
        data: { isRaw },
      });
      if (!isRaw) {
        try {
          await this.getResultTableData(isRaw);
        } catch (e) {
          this.downloadDialog?.close();
          this.downloadTableDataLoader = false;
          this.snackBar.open(
            'You are trying to download more data than is permitted, you might have too many filters on',
            'X',
            {
              duration: 10000,
              panelClass: ['error-snackbar'],
            }
          );
          return;
        }
        this.snackBar.openFromComponent(CustomDownloadSnackbarComponent, {
          duration: 10000,
          panelClass: ['custom-download-snackbar'],
        });
        this.downloadDialog?.close();
        this.downloadTableDataLoader = false;
      }
    } catch (e) {
      this.downloadDialog?.close();
      this.downloadTableDataLoader = false;
      this.snackBar.open('An error occured while downloading the file', 'X', {
        duration: 3000,
        panelClass: ['error-snackbar'],
      });
    }
  }

  cancelDownload(): void {
    this.downloadTableDataLoader = false;
  }

  removeCountryOrRegionFilter(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.regionsFormControl.setValue([], { emitEvent: false });
    this.countriesFormControl.setValue([]);
    this.countriesFromSelectedRegions = [];
  }

  onOpenedChange(isOpened: boolean): void {
    if (isOpened) {
      this.inputElement.nativeElement.focus();
    }
  }

  visuallyFilterCountries(country: CountriesListsModel): boolean {
    const countryName = country.shortName.toLowerCase();
    const countryIsoCode = (country.isoCode || '').toLowerCase();
    const input = (
      this.countryFiltersFormGroup.controls.autocompleteForm.value || ''
    ).toLowerCase();
    return (
      input === '' ||
      countryName.includes(input) ||
      countryIsoCode.includes(input)
    );
  }

  visuallyFilterColumns(column: string): boolean {
    const countryName = column.toLowerCase();
    const input = (
      this.countryFiltersFormGroup.controls.autocompleteColumn.value || ''
    ).toLowerCase();
    return input === '' || countryName.includes(input);
  }

  visuallyFilterRegions(region: any): boolean {
    const regionName = region.regionName.toLowerCase();
    const regionCode = region.regionCode.toLowerCase();
    const input = (
      this.countryFiltersFormGroup.controls.autocompleteRegionForm.value || ''
    ).toLowerCase();
    return (
      input === '' || regionName.includes(input) || regionCode.includes(input)
    );
  }

  allColumnsSelected(): void {
    this.csvColumnsFormControl.setValue(
      this.csvColumnsFormControl.value.filter(
        (v: string) => v === 'All columns'
      )
    );
  }

  otherColumnSelected(): void {
    this.csvColumnsFormControl.setValue(
      this.csvColumnsFormControl.value.filter(
        (v: string) => v !== 'All columns'
      )
    );
  }

  onOpenedColumnChange(isOpened: boolean): void {
    if (isOpened) {
      this.columnInputElement.nativeElement.focus();
    }
  }

  onOpenedRegionChange(isOpened: boolean): void {
    if (isOpened) {
      this.regionInputElement.nativeElement.focus();
    }
  }

  removeColumnFilter(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.csvColumnsFormControl.setValue([]);
    this.countryFiltersFormGroup.controls.autocompleteColumn.setValue('');
    this.columnInputElement.nativeElement.focus();
  }

  displayMultipleColumnFn(value: any): string {
    let result = '';
    let index = 0;
    for (const v of value) {
      index++ === 0 ? (result = `${v}`) : (result = `${result}, ${v}`);
    }
    return result;
  }

  async regionFilterSelectionChange(): Promise<void> {
    this.loading = true;
    const regions = this.regionsFormControl.value;
    const countries =
      await this.providerService.regionsManagementService.getCountriesByRegions(
        regions
      );
    const uniqueCountries = countries.filter(
      (value, index, self) =>
        index === self.findIndex((t) => t.countryID === value.countryID)
    );
    this.countriesFromSelectedRegions = uniqueCountries;
    this.countriesFormControl.setValue(
      uniqueCountries.map((c: any) => c.isoCode)
    );
    this.getTableData();
  }

  addValue(event: MatChipInputEvent): void {
    const value = event.value;
    if ((value || '').trim()) {
      this.addChip(event.value);
    }
  }

  addChip(value: string): void {
    if ((value || '').trim()) {
      if (value.includes(',')) {
        this.filters.push(...value.split(','));
        this.codeFilterEntered.push(...value.split(','));
      } else {
        this.filters.push(value.trim());
        this.codeFilterEntered.push(value.trim());
      }
    }
    this.inputFilterElement.nativeElement.value = '';
    this.inputFilterElement.nativeElement.focus();
    this.hasCompletedFilters = true;
    this.getTableData();
  }

  removeChip(item: any): void {
    this.isRemovingChip = true;
    if (this.removeChipTimeout) {
      clearTimeout(this.removeChipTimeout);
    }
    const index = this.filters.indexOf(item);
    const codeFilterIndex = this.codeFilterEntered.indexOf(item);
    if (index >= 0) {
      this.filters.splice(index, 1);
    }
    if (codeFilterIndex >= 0) {
      this.codeFilterEntered.splice(codeFilterIndex, 1);
    }
    if (this.removeChipInterval === undefined) {
      this.removeChipInterval = setInterval(() => {
        if (!this.isRemovingChip) {
          this.getTableData();
          clearInterval(this.removeChipInterval);
          this.removeChipInterval = undefined;
        }
      }, 550);
    }
    this.removeChipTimeout = setTimeout(() => {
      this.isRemovingChip = false;
    }, 500);
  }

  private setFormScroll(): void {
    this.formRef.nativeElement.scrollLeft =
      this.formRef.nativeElement.scrollWidth -
      this.formRef.nativeElement.clientWidth;
  }

  private _filter(value: any): void {
    if (typeof value === 'string' && value.length > 0) {
      const toSearch = value.toLowerCase();
      this.filteredCountries = this.countries.filter(
        (country) =>
          country.isoCode.toLowerCase().includes(toSearch) ||
          country.shortName.toLowerCase().includes(toSearch)
      );
    } else {
      this.filteredCountries = this.countries;
    }
  }

  reCalculateRectangle(): void {
    const x3 = Math.min(this.x1, this.x2);
    const x4 = Math.max(this.x1, this.x2);
    const y3 = Math.min(this.y1, this.y2);
    const y4 = Math.max(this.y1, this.y2);
    this.rectangularSelection.nativeElement.style.left = x3 + 'px';
    this.rectangularSelection.nativeElement.style.top = y3 + 'px';
    this.rectangularSelection.nativeElement.style.width = x4 - x3 + 'px';
    this.rectangularSelection.nativeElement.style.height = y4 - y3 + 'px';
  }

  resetStartingEndRowYear(): void {
    this.selectedStartingRow = 0;
    this.selectedStartingYear = this.startYear;
    this.selectedEndRow = this.tableDataSource.filteredData.length || 25;
    this.selectedEndYear = this.endYear;
    this.areCellsSelectedWithRectangularSelection = false;
  }

  openCopyToClipboardDialog(dialogRef: any): void {
    this.copyToClipboardDialog = this.dialog.open(dialogRef, {
      width: '400px',
      disableClose: true,
      autoFocus: false,
    });
  }

  async copyToClipboard(): Promise<void> {
    if (this.selectedStartingRow > this.selectedEndRow) {
      const oldStartingRow = this.selectedStartingRow;
      this.selectedStartingRow = this.selectedEndRow;
      this.selectedEndRow = oldStartingRow;
    }
    let copiedCliboardString = '';
    const rowsNum = this.tableDataSource.filteredData.length;
    // Displayed columns includes 4 static columns (actions, code, description, country name) that are not needed
    const columnsNum = this.displayedColumns.length - 4;
    // Add the columns names
    if (this.columnCopyOption) {
      if (this.codeCopyOption) {
        copiedCliboardString = copiedCliboardString.concat('\t\t');
      }
      for (let column = 0; column < columnsNum; column++) {
        if (
          column + this.startYear >= this.selectedStartingYear &&
          column + this.startYear < this.selectedEndYear
        ) {
          copiedCliboardString = copiedCliboardString.concat(
            `${column + this.startYear}\t`
          );
        } else if (column + this.startYear === this.selectedEndYear) {
          copiedCliboardString = copiedCliboardString.concat(
            `${column + this.startYear}`
          );
        }
      }
      copiedCliboardString = copiedCliboardString.concat('\n');
    }
    for (let row = 0; row < rowsNum; row++) {
      if (row >= this.selectedStartingRow && row <= this.selectedEndRow) {
        if (this.codeCopyOption) {
          copiedCliboardString = copiedCliboardString.concat(
            `${this.tableDataSource.filteredData[row].dataModel.code}\t${this.tableDataSource.filteredData[row].dataModel.countryName}\t`
          );
        }
        const yearsWithValues =
          this.tableDataSource.filteredData[row].dataModel.years;
        let currentYear = this.startYear;
        for (let column = 0; column < columnsNum; column++) {
          if (
            column + this.startYear >= this.selectedStartingYear &&
            column + this.startYear <= this.selectedEndYear
          ) {
            const currentYearCellInfo = yearsWithValues.find(
              (cellInfo) => parseInt(cellInfo.year) === currentYear
            );
            if (currentYearCellInfo) {
              if (currentYear !== this.selectedEndYear) {
                copiedCliboardString = copiedCliboardString.concat(
                  `${currentYearCellInfo.value}\t`
                );
              } else {
                copiedCliboardString = copiedCliboardString.concat(
                  `${currentYearCellInfo.value}`
                );
              }
            } else {
              copiedCliboardString = copiedCliboardString.concat('\t');
            }
          }
          currentYear++;
        }
        if (row !== this.selectedEndRow) {
          copiedCliboardString = copiedCliboardString.concat('\n');
        }
      }
    }
    await navigator.clipboard.writeText(copiedCliboardString);
    this.snackBar.open('Content copied to clipboard', 'X', {
      duration: 3000,
      panelClass: ['success-snackbar'],
    });
  }

  downEventOnCell(e: any, row: any, column: any): void {
    if (e.button !== 2) {
      this.isDoingRectangularSelection = true;
      this.x1 = e.clientX;
      this.y1 = e.clientY;
      this.reCalculateRectangle();
      this.selectedStartingRow = row;
      this.selectedStartingYear = parseInt(column);
    }
  }

  upEventOnCell(e: any, row: any, column: any): void {
    if (e.button !== 2) {
      this.selectedEndRow = row;
      this.selectedEndYear = parseInt(column);
      this.highlightCellsBehindRectangularSelection();
      this.rectangularSelection.nativeElement.hidden = true;
      this.x1 = 0;
      this.y1 = 0;
      this.x2 = 0;
      this.y2 = 0;
      this.isDoingRectangularSelection = false;
      this.areCellsSelectedWithRectangularSelection = !(
        this.selectedStartingRow === this.selectedEndRow &&
        this.selectedStartingYear === this.selectedEndYear
      );
    }
  }

  isOverlapping(el1: HTMLElement, el2: HTMLElement): boolean {
    const rect1 = el1.getBoundingClientRect();
    const rect2 = el2.getBoundingClientRect();
    return !(
      rect1.top > rect2.bottom ||
      rect1.right < rect2.left ||
      rect1.bottom < rect2.top ||
      rect1.left > rect2.right
    );
  }

  highlightCellsBehindRectangularSelection(): void {
    const overlay = document.querySelector('#rectangular-div') as HTMLElement;
    const cells = document.querySelectorAll('td');
    cells.forEach((cell) => {
      if (this.isOverlapping(overlay, cell as HTMLElement)) {
        this.unselectCell();
        cell.classList.add('rectangular-selected');
      } else {
        cell.classList.remove('rectangular-selected');
      }
    });
  }

  unselectCell(): void {
    this.selectedCell = undefined;
    this.cellInfoPresent = false;
    this.publishedNoteInput.nativeElement.value = '';
    this.internalNoteInput.nativeElement.value = '';
    this.sourceInput.nativeElement.value = '';
  }

  formatDecimalDigits(value: any, numDigits: number): string {
    if (value) {
      const cleanValue = value.toString().replace(/,/g, '');
      const fixedValue = parseFloat(cleanValue).toFixed(numDigits);
      const parts = fixedValue.split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return parts.join('.');
    } else {
      return '';
    }
  }

  deselectAllOptions(type: string): void {
    if (type === 'countries') {
      this.countriesFormControl.setValue([]);
      this.inputElement.nativeElement.focus();
    } else if (type === 'regions') {
      this.regionsFormControl.setValue([]);
      this.regionInputElement.nativeElement.focus();
    }
  }

  selectAllOptions(type: string): void {
    if (type === 'countries') {
      const allCountries = this.countriesFromSelectedRegions.map(
        (country) => country.isoCode
      );
      this.countriesFormControl.setValue(allCountries);
      this.inputElement.nativeElement.focus();
    } else if (type === 'regions') {
      this.regionsFormControl.setValue(
        this.regions.map((region) => region.regionID)
      );
      this.regionInputElement.nativeElement.focus();
    }
  }

  openContextualMenu(
    event: MouseEvent,
    menuTemplate: TemplateRef<any>,
    cell: any,
    column: any,
    currentTD: any,
    field: any,
    row: any
  ): void {
    event.preventDefault();
    this.closeContextualMenu();
    if (
      this.cellInfoPresent ||
      (!this.cellInfoPresent && !this.areCellsSelectedWithRectangularSelection)
    ) {
      this.setSelectedCell(cell, column, currentTD);
      this.getCellInfo(cell, field);
      this.selectedStartingRow = row;
      this.selectedStartingYear = parseInt(field);
      this.selectedEndRow = row;
      this.selectedEndYear = parseInt(field);
    }
    this.contextualMenuVisible = true;
    this.hostElem = this.el.nativeElement;
    this.contextualMenuElem = this.renderer.createElement('div');
    this.renderer.appendChild(this.hostElem, this.contextualMenuElem);
    const view = menuTemplate.createEmbeddedView(null);
    this.contextualMenuElem.appendChild(view.rootNodes[0]);
    this.renderer.setStyle(this.contextualMenuElem, 'position', 'absolute');
    this.renderer.setStyle(
      this.contextualMenuElem,
      'top',
      `${event.clientY}px`
    );
    this.renderer.setStyle(
      this.contextualMenuElem,
      'left',
      `${event.clientX}px`
    );
  }

  closeContextualMenu(): void {
    this.contextualMenuVisible = false;
    if (this.hostElem && this.contextualMenuElem) {
      this.renderer.removeChild(this.hostElem, this.contextualMenuElem);
    }
  }
}
