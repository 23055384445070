import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ProviderService } from '../../../core/provider.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-upload-retrieve-file',
  templateUrl: './upload-retrieve-file.component.html',
  styleUrl: './upload-retrieve-file.component.scss',
})
export class UploadRetrieveFileComponent {
  @Input()
  cell!: any;
  @Input()
  column!: any;
  @Input()
  table!: string;
  @Input()
  cellInfo!: any;
  @Input()
  disabled!: boolean;
  @ViewChild('fileInput', { static: false })
  fileInput!: ElementRef;
  downloading = false;
  uploading = false;

  constructor(
    private providerService: ProviderService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {}

  selectFile(inputElement: any): void {
    inputElement.click();
  }

  async onFileSelected(event: any): Promise<void> {
    const file: File = event.target.files[0];
    await this.uploadFile(file);
  }

  async convertFileToBase64(file: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  async uploadFile(file: File): Promise<void> {
    try {
      this.uploading = true;
      this.snackBar.open('Uploading file...', 'X', {
        duration: 3000,
        panelClass: ['success-snackbar'],
      });
      const fileName = file.name;
      const base64File = await this.convertFileToBase64(file);
      const cellID =
        this.cell.formGroup.controls[`id-${this.column.title}`].value;
      await this.providerService.manageDataService.uploadFile(
        cellID,
        base64File,
        fileName,
        this.table
      );
      this.uploading = false;
      this.cellInfo.hasFile = true;
      this.fileInput.nativeElement.value = '';
      this.snackBar.open('File linked to data point successfully', 'X', {
        duration: 3000,
        panelClass: ['success-snackbar'],
      });
    } catch (e) {
      this.uploading = false;
      this.snackBar.open('Error while uploading the file', 'X', {
        duration: 3000,
        panelClass: ['error-snackbar'],
      });
    }
  }

  async retrieveFile(): Promise<void> {
    try {
      this.downloading = true;
      const cellID =
        this.cell.formGroup.controls[`id-${this.column.title}`].value;
      const fileUrl = await this.providerService.manageDataService.retrieveFile(
        cellID,
        this.table
      );
      this.downloading = false;
      const presignedUrl = fileUrl.presignedUrl;
      const urlParts = presignedUrl.split('?')[0];
      const fileExtension = urlParts.split('.').pop()?.toLowerCase();
      if (
        fileExtension === 'jpeg' ||
        fileExtension === 'jpg' ||
        fileExtension === 'png' ||
        fileExtension === 'gif' ||
        fileExtension === 'pdf'
      ) {
        window.open(presignedUrl, '_blank');
      } else {
        const a = document.createElement('a');
        a.href = presignedUrl;
        a.download = 'downloaded-file';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    } catch (e) {
      this.downloading = false;
      this.snackBar.open('No associated file to retrieve', 'X', {
        duration: 3000,
        panelClass: ['warning-snackbar'],
      });
    }
  }
}
