import { Injectable } from '@angular/core';
import { NetworkService } from '../../../core/net-utils/network.service';
import { SurveyResponseModel } from '../models/survey-model';
import { SurveyMapper } from '../mappers/survey-mapper';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SurveyTableService {
  private networkService!: NetworkService;
  baseUrl = `${environment.backend.endpoint}/validation-module`;

  setNetworkService(networkService: NetworkService): void {
    this.networkService = networkService;
  }

  async getSurveyListAndCount(
    pageIndex: number,
    pageSize: number,
    sortCol: string,
    sortType: string,
    surveyName?: string,
    surveyDescription?: string,
    surveyYear?: number
  ): Promise<SurveyResponseModel> {
    const mapper = new SurveyMapper();
    await this.networkService?.get(`${this.baseUrl}/survey/list`, mapper, {
      name: surveyName,
      description: surveyDescription,
      year: surveyYear,
      orderBy: sortCol,
      order: sortType,
      limit: pageSize,
      offset: pageIndex,
    });
    return mapper.surveyList!;
  }
}
