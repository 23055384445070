@if (loading) {
<app-loader></app-loader>
}

<div class="download-list-container">
  <div class="table-holder mat-elevation-z8">
    <table mat-table matSort [dataSource]="dataSource" [matSortActive]="'createdAt'" [matSortDirection]="'desc'">
      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef appResizeColumn>
          <div mat-sort-header>Created at</div>
        </th>
        <td mat-cell *matCellDef="let element">
          <span class="row-line">
            {{ getFormattedDate(element.createdAt) }}
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="filePath">
        <th mat-header-cell *matHeaderCellDef appResizeColumn>
          <div>File path</div>
        </th>
        <td mat-cell *matCellDef="let element">
          <span class="row-line">
            {{ element.filePath }}
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef appResizeColumn>
          <div mat-sort-header>Status</div>
        </th>
        <td mat-cell *matCellDef="let element">
          @if (element.status === 'PROCESSING') {
          <i style="color: #6cb4ff" [matTooltip]="'processing'" class="fa-spin fa-solid fa-spinner"></i>
          } @else if (element.status === 'COMPLETED') {
          <i style="color: #46a35e" [matTooltip]="'completed'" class="fa-solid fa-file-check"></i>
          } @else if (element.status === 'ERROR') {
          <i style="color: #b71c1c" class="fa-solid fa-triangle-exclamation" [matTooltip]="element.errorMessage"></i>
          }
        </td>
      </ng-container>
      <ng-container matColumnDef="downloadFile">
        <th mat-header-cell *matHeaderCellDef appResizeColumn>
          <div>Actions</div>
        </th>
        <td mat-cell *matCellDef="let element">
          @if (element.status === 'COMPLETED') {
          <button style="color: #6cb4ff; font-size: 20px" mat-icon-button (click)="downloadFile(element.filePath)"
            class="fa-solid fa-download" [matTooltip]="'Download file'"></button>
          }
        </td>
      </ng-container>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">@if(!loading) {No data found}</td>
      </tr>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="mat-header-row"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="table-row"></tr>
    </table>
  </div>
  <button mat-icon-button class="fa-solid fa-rotate-right" [matTooltip]="'Refresh table'"
    (click)="this.getTableData()"></button>
</div>