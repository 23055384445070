<div class="country-validation-container">
  <div class="select-container {{currentStep !== CountryValidationSteps.selectSurvey ? 'hidden' : ''}}">
    <span class="step-label">{{ 'COUNTRY_VALIDATION.SELECT_SURVEY' | translate }}</span>
    <app-survey-table class="step-selection" (surveySelected)="this.selectSurvey($event)"></app-survey-table>
  </div>
  @if (currentStep === CountryValidationSteps.selectCountry || currentStep ===
  CountryValidationSteps.showValidationTable) {
  <div class="select-container {{currentStep === CountryValidationSteps.showValidationTable ? 'hidden' : ''}}">
    <div class="back-button-container">
      <button mat-button mat-raised-button color="primary" (click)="goBackTo(CountryValidationSteps.selectSurvey)"><i
          class="fa-solid fa-arrow-left"></i> {{ 'COUNTRY_VALIDATION.BACK' | translate }}
      </button>
    </div>
    <span class="step-label">{{ 'COUNTRY_VALIDATION.SELECT_COUNTRY_FOR' | translate }} {{ selectedSurvey?.surveyName
      }}</span>
    <app-survey-country-table class="step-selection" [selectedSurvey]="selectedSurvey!"
      (countrySelected)="this.selectCountry($event)"></app-survey-country-table>
  </div>
  }
  @if (currentStep === CountryValidationSteps.showValidationTable) {
  <div class="select-container">
    <div class="back-button-container">
      <button mat-button mat-raised-button color="primary" (click)="goBackTo(CountryValidationSteps.selectCountry)"><i
          class="fa-solid fa-arrow-left"></i> {{ 'COUNTRY_VALIDATION.BACK' | translate }}
      </button>
    </div>
    <span class="step-country-label">
      <span>{{ 'COUNTRY_VALIDATION.SELECTED_SURVEY' | translate }} {{ selectedSurvey?.surveyName }}</span>
      <span>
        {{ 'COUNTRY_VALIDATION.COUNTRY_NAME' | translate }} {{ selectedCountry?.countryName }}
      </span>
      <span>
        {{ 'COUNTRY_VALIDATION.ISO_CODE' | translate }} {{ selectedCountry?.isoCode }}
      </span>
    </span>
    <app-country-validation-table class="step-selection" [selectedSurvey]="selectedSurvey!"
      [selectedCountry]="selectedCountry!"></app-country-validation-table>
  </div>
  }
</div>