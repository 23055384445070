import { Injectable } from '@angular/core';
import {
  CellHistoryModel,
  CellInfoModel,
  DataModel,
  ModifyRowModel,
  cellUpdatePayloadModel,
  getIdPayload,
} from '../models/update-country-table-models';
import { BehaviorSubject } from 'rxjs';
import { NetworkService } from '../../../core/net-utils/network.service';
import {
  DeleteSourceMapper,
  GetCellHistoryMapper,
  GetCellInfoMapper,
  insertIdMapper,
  ModifyRowMapper,
  updateCellMapper,
  UpdateEstimatedMapper,
  UpdateInternalNotesMapper,
  UpdateNotesMapper,
  UpdatePublishMapper,
  UpdateResponseMapper,
  UpdateSourceMapper,
} from '../mappers/update-cell-mapper';
import { TableDataMapper } from '../mappers/table-data-mapper';
import { TableDataCountMapper } from '../mappers/table-data-count-mapper';
import { environment } from '../../../../environments/environment';
import { RawDataCountMapper } from '../mappers/raw-data-count-mapper';

@Injectable({
  providedIn: 'root',
})
export class UpdateCountryTableService {
  private dataSubject = new BehaviorSubject<DataModel[]>([]);
  public data$ = this.dataSubject.asObservable();
  networkService?: NetworkService;
  baseUrl = `${environment.backend.endpoint}/country-update/`;

  private emptyMapper = {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    fillFromJson(_json: any): void {},
    fillToJson(): string {
      return '';
    },
    toString(): string {
      return '';
    },
  };

  setNetworkService(networkService: NetworkService): void {
    this.networkService = networkService;
  }

  reorderColumns(columns: string[]): string[] {
    let reordered = ['actions', 'code', 'description', 'countryName'];
    const filtered = columns.filter((c: string) => !reordered.includes(c));
    filtered.map((c: string) => {
      reordered.push(c);
    });
    reordered = reordered.filter(
      (value) => !['countryId', 'codeId'].includes(value)
    );
    return reordered;
  }
  async insertID(payload: getIdPayload): Promise<string> {
    const mapper = new insertIdMapper();
    mapper.payload = payload;
    await this.networkService?.post(`${this.baseUrl}insert`, mapper, mapper);
    return mapper.id;
  }
  setTableResize(
    columns: any,
    displayedColumns: any,
    tableWidth: number,
    prefix: string
  ): void {
    let totWidth = 0;
    columns.forEach((column: any) => {
      if (displayedColumns.includes(column.field)) {
        totWidth += column.width;
      }
    });
    const scale = (tableWidth - 5) / totWidth;
    columns.forEach((column: any) => {
      if (displayedColumns.includes(column.field)) {
        column.width *= scale;
        this.setColumnWidth(column, prefix);
      }
    });
  }

  setColumnWidth(column: any, prefix: string): void {
    const columnEls = Array.from(
      document.getElementsByClassName(`${prefix}${column.field}`)
    );
    columnEls.forEach((el: any) => {
      el.style.width = column.width + 'px';
    });
  }

  async getTableData(
    fromYear: number,
    toYear: number,
    sortCol: string,
    filters: string,
    sortType: string,
    pageNumber: number,
    pageSize: number
  ): Promise<DataModel[]> {
    const tableDataMapper = new TableDataMapper();
    await this.networkService?.get(`${this.baseUrl}list`, tableDataMapper, {
      fromYear: fromYear.toString(),
      toYear: toYear.toString(),
      sortCol: sortCol ?? '',
      filters: filters,
      sortType: sortType,
      pageNumber: pageNumber.toString(),
      pageSize: pageSize.toString(),
    });
    return tableDataMapper.countriesData;
  }

  async getHouseholdTableData(
    fromYear: number,
    toYear: number,
    sortCol: string,
    filters: string,
    sortType: string,
    pageNumber: number,
    pageSize: number
  ): Promise<any> {
    const tableDataMapper = new TableDataMapper();
    await this.networkService?.get(
      `${environment.backend.endpoint}/household/list`,
      tableDataMapper,
      {
        fromYear: fromYear.toString(),
        toYear: toYear.toString(),
        sortCol: sortCol ?? '',
        filters: filters,
        sortType: sortType,
        pageNumber: pageNumber.toString(),
        pageSize: pageSize.toString(),
      }
    );
    return tableDataMapper.countriesData;
  }

  async householdTableCount(
    fromYear: number,
    toYear: number,
    filters: string
  ): Promise<number> {
    const tableDataCountMapper = new TableDataCountMapper();
    await this.networkService?.get(
      `${environment.backend.endpoint}/household/count`,
      tableDataCountMapper,
      {
        fromYear: fromYear.toString(),
        toYear: toYear.toString(),
        filters: filters,
      }
    );
    return tableDataCountMapper.tableDataCount;
  }

  async tableCount(
    fromYear: number,
    toYear: number,
    filters: string
  ): Promise<number> {
    const tableDataCountMapper = new TableDataCountMapper();
    await this.networkService?.get(
      `${this.baseUrl}count`,
      tableDataCountMapper,
      {
        fromYear: fromYear.toString(),
        toYear: toYear.toString(),
        filters: filters,
      }
    );
    return tableDataCountMapper.tableDataCount;
  }

  async getRawDataCountCountryUpdate(params: any): Promise<number> {
    try {
      const mapper = new RawDataCountMapper();
      await this.networkService?.get(
        `${this.baseUrl}raw-count`,
        mapper,
        params
      );
      return mapper.rawDataCount;
    } catch (e) {
      throw new Error();
    }
  }

  async updateCell(cellModification: cellUpdatePayloadModel): Promise<void> {
    const mapper = new updateCellMapper();
    const responseMapper = new UpdateResponseMapper();
    mapper.cellModification = [cellModification];
    await this.networkService?.post(
      `${this.baseUrl}update`,
      mapper,
      responseMapper
    );
  }
  async getCellInfo(cellId: string, table: string): Promise<CellInfoModel> {
    const mapper = new GetCellInfoMapper();
    await this.networkService?.get(
      `${this.baseUrl}cell-info/${cellId}`,
      mapper,
      { table }
    );
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return mapper.cellInfo!;
  }

  async getCellHistory(cellId: string): Promise<CellHistoryModel[]> {
    const mapper = new GetCellHistoryMapper();
    await this.networkService?.get(`${this.baseUrl}history`, mapper, {
      cellId,
    });
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return mapper.cellHistory!;
  }

  async updateInternalNotes(
    cellId: string,
    internalNote: string
  ): Promise<void> {
    const mapper = new UpdateInternalNotesMapper();
    mapper.cellId = cellId;
    mapper.internalNote = internalNote;
    const responseMapper = new UpdateResponseMapper();
    await this.networkService?.post(
      `${this.baseUrl}internal-notes`,
      mapper,
      responseMapper
    );
  }
  async deleteSourceById(sourceIds: string[]): Promise<void> {
    const mapper = new DeleteSourceMapper();
    mapper.sourceIds = sourceIds;
    await this.networkService?.post(
      `${this.baseUrl}delete-source`,
      mapper,
      mapper
    );
  }
  async updatePublishedNotes(cellId: string, note: string): Promise<void> {
    const mapper = new UpdateNotesMapper();
    mapper.cellId = cellId;
    mapper.note = note;
    const responseMapper = new UpdateResponseMapper();
    await this.networkService?.post(
      `${this.baseUrl}notes`,
      mapper,
      responseMapper
    );
  }

  async updateSource(
    cellId: string,
    source: string,
    sourceId: string | undefined
  ): Promise<void> {
    const mapper = new UpdateSourceMapper();
    mapper.dataId = cellId;
    mapper.source = source;
    mapper.sourceId = sourceId;
    const responseMapper = new UpdateResponseMapper();
    await this.networkService?.post(
      `${this.baseUrl}update-sources`,
      mapper,
      responseMapper
    );
  }

  async modifyRow(row: ModifyRowModel[]): Promise<void> {
    const mapper = new ModifyRowMapper();
    mapper.row = row;
    await this.networkService?.post(
      `${this.baseUrl}update`,
      mapper,
      this.emptyMapper
    );
  }

  async updatePublished(id: string, published: boolean): Promise<void> {
    const mapper = new UpdatePublishMapper();
    mapper.published = published;
    mapper.cellId = id;
    await this.networkService?.post(
      `${this.baseUrl}update-published`,
      mapper,
      this.emptyMapper
    );
  }

  async updateEstimated(id: string, estimated: boolean): Promise<void> {
    const mapper = new UpdateEstimatedMapper();
    mapper.estimated = estimated;
    mapper.cellId = id;
    await this.networkService?.post(
      `${this.baseUrl}update-estimated`,
      mapper,
      this.emptyMapper
    );
  }
}
