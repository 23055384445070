<div class="container">
  <div mat-dialog-title class="header">
    <div class="title">{{ 'CONDITIONAL_FORMATTING.TITLE' | translate }}</div>
    <i (click)="closeDialog()" matTooltip="Close" class="fa fa-times" aria-hidden="true"></i>
  </div>

  <mat-dialog-content>
    <div class="filter-container" [formGroup]="formGroup">
      <div formArrayName="conditionsArray">
        @for (conditionGroup of conditionsArray.controls; let i = $index; track conditionGroup) {
          @if (i > 0) {
            <div class="logical-operator-label">--- {{ logicalOperatorsList[i - 1] }} ---</div>
          }
          <div [formGroupName]="i" class="form-array">
            <mat-form-field appearance="fill">
              <mat-label>{{ 'CONDITIONAL_FORMATTING.SELECT_CONDITION_LABEL' | translate }}</mat-label>
              <mat-select formControlName="conditionOperation">
                @for (condition of eConditionalFormattingOperations | keyvalue; track condition) {
                  <mat-option [value]="condition.value">
                    {{ condition.value | translate }}
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>
            @if (i == 0) {
              <mat-form-field appearance="fill">
                <mat-label>{{ 'CONDITIONAL_FORMATTING.INSERT_VALUE_LABEL' | translate }}</mat-label>
                <input matInput type="number" formControlName="inputValue"/>
              </mat-form-field>
            }
            @else {
              <div class="form-array-with-bin">
                <mat-form-field appearance="fill">
                  <mat-label>{{ 'CONDITIONAL_FORMATTING.INSERT_VALUE_LABEL' | translate }}</mat-label>
                  <input matInput type="number" formControlName="inputValue"/>
                </mat-form-field>
                <i class="fa fa-solid fa-trash" (click)="removeFormFieldRow(i)"></i>
              </div>
            }
          </div>
          <div [formGroupName]="i" class="form-array">
            <mat-form-field appearance="fill">
              <mat-label>For codes</mat-label>
              <mat-chip-grid #chipGrid>
                @for(code of allCodes[i]; track code) {
                  <mat-chip-row
                    (removed)="removeChip(code, conditionGroup, i)"
                    [removable]="true"
                    [editable]="false"
                    class="standard"
                  >
                    {{code}}
                    <button matChipRemove class="mat-chip-remove-code">
                      <mat-icon>cancel</mat-icon>
                    </button>
                  </mat-chip-row>
                }
                <input
                  class="code-filter-input"
                  matInput type="text" formControlName="codes"
                  [matChipInputFor]="chipGrid"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  (matChipInputTokenEnd)="addValue($event, i, conditionGroup)"
                  #inputCodesElement{{i}}
                />
              </mat-chip-grid>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>For countries</mat-label>
              <mat-select formControlName="countries"
                          (selectionChange)="changeSelection(i)"
                          (openedChange)="onOpenedChange($event, i)" multiple>
                <mat-select-trigger>
                <span class="label" [matTooltip]="displayMultipleFnCountries(conditionGroup)">
                  {{ displayFnCountries(conditionGroup) || '' }}
                  @if ((conditionGroupLength(conditionGroup) || 0) > 1) {
                    <span class="example-additional-selection">
                    (+{{ (conditionGroupLength(conditionGroup) || 0) - 1 }} {{ conditionGroupLength(conditionGroup) === 2 ?
                      ('FILTERS.OTHER' | translate) : ('FILTERS.OTHERS' | translate) }}
                      )
                  </span>
                  }
                </span>
                  @if ((conditionGroupLength(conditionGroup) || 0) > 0) {
                    <i (click)="removeCountryFilter($event, conditionGroup, i)" class="fa fa-x clear-filter"
                       matTooltip="{{'FILTERS.CLEAR_FILTERS' | translate}}"></i>
                  }
                </mat-select-trigger>
                <input matInput type="text" [formControl]="filterFormGroup.controls.autocompleteCountry" class="autocomplete-input"
                       (keydown)="$event.stopPropagation()" #inputElement{{i}}>
                @if (filterFormGroup.controls.autocompleteCountry.value === '') {
                  <span class="autocomplete-placeholder">{{ 'FILTERS.AUTOCOMPLETE_PLACEHOLDER' | translate }}</span>
                }
                @for (country of allCountries; track country) {
                  <mat-option [ngClass]="visuallyFilterCountries(country) ? '' : 'hidden'" [value]="country.shortName">
                    {{ country.isoCode }} - {{ country.shortName }}
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>
        }
      </div>
    </div>
    <div class="add-filter">
      <div class="add-filter-button-group">
        <button mat-raised-button color="primary" (click)="addCondition(eLogicalOperators.AND)">
          <i class="fa fa-plus"></i>
          {{ 'CONDITIONAL_FORMATTING.ADD_AND_CONDITION' | translate }}
        </button>
        <button mat-raised-button color="primary" (click)="addCondition(eLogicalOperators.OR)">
          <i class="fa fa-plus"></i>
          {{ 'CONDITIONAL_FORMATTING.ADD_OR_CONDITION' | translate }}
        </button>
      </div>
      <div class="color-picker-container" onclick="this.blur()">
        <div class="color-picker-button" [(colorPicker)]="color">
          <div class="bucket-and-color-container">
            <i class="fa fa-fill-drip"></i>
            <div class="picked-color" [style.background]="color"></div>
          </div>
          <i class="fa fa-caret-down"></i>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <mat-divider></mat-divider>

  <mat-dialog-actions>
    <button mat-raised-button class="cancel-button" (click)="closeDialog()">
      {{ 'CONDITIONAL_FORMATTING.CANCEL' | translate }}
    </button>
    <button [disabled]="!formGroup.valid" mat-raised-button (click)="applyFilter()" [color]="formGroup.valid ? 'primary' : ''">
      <span [ngClass]="formGroup.valid ? 'active-button' : ''">{{ 'CONDITIONAL_FORMATTING.APPLY' | translate }}</span>
    </button>
  </mat-dialog-actions>
</div>

