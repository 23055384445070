import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Constants } from '../../models/constants';
import { MatSelect } from '@angular/material/select';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProviderService } from '../../core/provider.service';
import { distinctUntilChanged } from 'rxjs/operators';
import { debounceTime } from 'rxjs';
import { AntiMemLeak } from '../../core/form-utils/anti-mem-leak/anti-mem-leak';
import { MatChipInputEvent } from '@angular/material/chips';
import { environment } from '../../../environments/environment';
import { CountriesListsModel } from '../update-country-table/models/country-filters-model';

@Component({
  selector: 'app-country-view-download',
  templateUrl: './country-view-download.component.html',
  styleUrl: './country-view-download.component.scss',
})
// eslint-disable-next-line prettier/prettier
export class CountryViewDownloadComponent extends AntiMemLeak implements AfterViewInit {
  downloadFormGroup = new FormGroup({
    fromYear: new FormControl('', [Validators.min(1900), Validators.max(2999)]),
    toYear: new FormControl('', [Validators.min(1900), Validators.max(2999)]),
    codeFilter: new FormControl(''),
    descriptionFilter: new FormControl(''),
    dataFormat: new FormControl('', Validators.required),
    sortColumn: new FormControl(''),
    sortType: new FormControl(''),
    csvColumns: new FormControl([], Validators.required),
    autocompleteColumn: new FormControl(''),
  });
  showYearsAsColumnsOptions = false;
  showError = false;
  downloadTableDataLoader = false;
  rawCountryViewDataColumns = Constants.downloadRawColumns;
  filters: string[] = [];
  codeFilterEntered: any[] = [];
  separatorKeysCodes: number[] = [13, 32, 188];
  @ViewChild('columnInputElement')
  columnInputElement!: ElementRef;
  @ViewChild(MatSelect)
  matSelect!: MatSelect;
  @ViewChild('inputFilterElement')
  inputFilterElement!: ElementRef;
  loading = true;
  private selectedCountries: string[] = [];

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    public providerService: ProviderService
  ) {
    super();
  }
  ngAfterViewInit(): void {
    this.subscriptions.add(
      this.downloadFormGroup.controls.dataFormat.valueChanges
        .pipe(distinctUntilChanged(), debounceTime(100))
        .subscribe((value) => {
          this.onDataFormatChange(value);
        })
    );
  }

  onDataFormatChange(value: any): void {
    this.downloadFormGroup.markAsUntouched();
    if (value === 'yearsAsColumns') {
      this.downloadFormGroup.controls.fromYear.setValidators([
        Validators.required,
        Validators.max(new Date().getFullYear()),
      ]);
      this.downloadFormGroup.controls.toYear.setValidators([
        Validators.required,
        Validators.max(new Date().getFullYear()),
      ]);
      this.downloadFormGroup.controls.sortColumn.setValidators([
        Validators.required,
      ]);
      this.downloadFormGroup.controls.sortType.setValidators([
        Validators.required,
      ]);
      this.downloadFormGroup.controls.csvColumns.clearValidators();
    } else {
      this.downloadFormGroup.controls.csvColumns.setValidators([
        Validators.required,
      ]);
      this.downloadFormGroup.controls.fromYear.clearValidators();
      this.downloadFormGroup.controls.toYear.clearValidators();
      this.downloadFormGroup.controls.sortColumn.clearValidators();
      this.downloadFormGroup.controls.sortType.clearValidators();
    }

    this.downloadFormGroup.controls.csvColumns.updateValueAndValidity();
    this.downloadFormGroup.controls.fromYear.updateValueAndValidity();
    this.downloadFormGroup.controls.toYear.updateValueAndValidity();
    this.downloadFormGroup.controls.sortColumn.updateValueAndValidity();
    this.downloadFormGroup.controls.sortType.updateValueAndValidity();
    this.showYearsAsColumnsOptions = value === 'yearsAsColumns';
  }

  async downloadData(dialogRef: any): Promise<void> {
    this.downloadFormGroup.markAllAsTouched();
    if (this.downloadFormGroup.valid) {
      const filters: any = {};
      if (this.codeFilterEntered) {
        filters.codeFilter = this.codeFilterEntered;
      }
      const descriptionFilter =
        this.downloadFormGroup.controls.descriptionFilter.value;
      if (descriptionFilter) {
        filters.codeDescriptionFilter = descriptionFilter;
      }
      if (this.selectedCountries) {
        filters.countryCodeFilter = this.selectedCountries;
      }
      const downloadDataParams: any = {
        filters: Object.keys(filters).length
          ? btoa(JSON.stringify(filters))
          : '',
      };
      const fromYear = this.downloadFormGroup.controls.fromYear.value;
      if (fromYear) {
        downloadDataParams.fromYear = fromYear.toString();
      }
      const toYear = this.downloadFormGroup.controls.toYear.value;
      if (toYear) {
        downloadDataParams.toYear = toYear.toString();
      }
      const dataFormat = this.downloadFormGroup.controls.dataFormat.value;
      if (dataFormat === 'yearsAsColumns') {
        const sortCol = this.downloadFormGroup.controls.sortColumn.value;
        if (sortCol) {
          downloadDataParams.sortCol = sortCol;
        }
        const sortType = this.downloadFormGroup.controls.sortType.value;
        if (sortType) {
          downloadDataParams.sortType = sortType;
        }
      }
      if (dataFormat === 'rawData') {
        if (this.csvColumnsFormControl.value.includes('All columns')) {
          downloadDataParams.columns = JSON.stringify(
            this.rawCountryViewDataColumns
              .map((v: any) => v.value)
              .filter((v: string) => v !== 'All columns')
          );
        } else {
          downloadDataParams.columns = JSON.stringify(
            this.csvColumnsFormControl.value
          );
        }
      }
      let dialog;
      try {
        this.downloadTableDataLoader = true;
        dialog = this.dialog.open(dialogRef, {
          width: '500px',
          disableClose: true,
          autoFocus: false,
        });
        if (dataFormat === 'yearsAsColumns') {
          const count =
            await this.providerService.countryTableService.tableCount(
              downloadDataParams.fromYear,
              downloadDataParams.toYear,
              downloadDataParams.filters
            );
          if (count > environment.maxDownloadCount) {
            this.snackBar.open(
              'You are trying to download more data than is permitted, you might have too many filters on',
              'X',
              {
                duration: 10000,
                panelClass: ['error-snackbar'],
              }
            );
            dialog.close();
            return;
          } else {
            await this.providerService.downloadDataService.downloadTableData(
              downloadDataParams
            );
          }
        } else if (dataFormat === 'rawData') {
          const count =
            await this.providerService.countryTableService.getRawDataCountCountryUpdate(
              downloadDataParams
            );
          if (count > environment.maxRawDownloadCount) {
            this.snackBar.open(
              'You are trying to download more data than is permitted, you might have too many filters on',
              'X',
              {
                duration: 10000,
                panelClass: ['error-snackbar'],
              }
            );
            dialog.close();
            return;
          } else {
            await this.providerService.downloadDataService.downloadRawTableData(
              downloadDataParams
            );
          }
        }
        dialog.close();
        if (this.downloadTableDataLoader) {
          this.snackBar.open(
            'Download initated, go back to downloads to see the file status',
            'X',
            {
              duration: 10000,
              panelClass: ['custom-download-snackbar'],
            }
          );
        }
      } catch (e) {
        dialog?.close();
        this.snackBar.open('An error occured while downloading the file', 'X', {
          duration: 3000,
          panelClass: ['error-snackbar'],
        });
        this.showError = true;
      } finally {
        this.downloadTableDataLoader = false;
      }
    }
  }

  cancelDownload(): void {
    this.downloadTableDataLoader = false;
  }

  displayMultipleColumnFn(value: any): string {
    let result = '';
    let index = 0;
    for (const v of value) {
      index++ === 0 ? (result = `${v}`) : (result = `${result}, ${v}`);
    }
    return result;
  }

  removeColumnFilter(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.csvColumnsFormControl.setValue([]);
    this.downloadFormGroup.controls.autocompleteColumn.setValue('');
    this.columnInputElement.nativeElement.focus();
  }

  onOpenedColumnChange(isOpened: boolean): void {
    if (isOpened) {
      this.columnInputElement.nativeElement.focus();
    }
  }

  visuallyFilterColumns(column: string): boolean {
    const columnName = column.toLowerCase();
    const input = (
      this.downloadFormGroup.controls.autocompleteColumn.value || ''
    ).toLowerCase();
    return input === '' || columnName.includes(input);
  }

  allColumnsSelected(): void {
    this.csvColumnsFormControl.setValue(
      this.csvColumnsFormControl.value.filter(
        (v: string) => v === 'All columns'
      )
    );
  }

  otherColumnSelected(): void {
    this.csvColumnsFormControl.setValue(
      this.csvColumnsFormControl.value.filter(
        (v: string) => v !== 'All columns'
      )
    );
  }

  get csvColumnsFormControl(): FormControl {
    return this.downloadFormGroup.controls.csvColumns;
  }

  addValue(event: MatChipInputEvent): void {
    const value = event.value;
    if ((value || '').trim()) {
      this.addChip(event.value);
    }
  }

  addChip(value: string): void {
    if ((value || '').trim()) {
      if (value.includes(',')) {
        this.filters.push(...value.split(','));
        this.codeFilterEntered.push(...value.split(','));
      } else {
        this.filters.push(value.trim());
        this.codeFilterEntered.push(value.trim());
      }
    }
    this.inputFilterElement.nativeElement.value = '';
    this.inputFilterElement.nativeElement.focus();
  }

  removeChip(item: any): void {
    const index = this.filters.indexOf(item);
    const codeFilterIndex = this.codeFilterEntered.indexOf(item);
    if (index >= 0) {
      this.filters.splice(index, 1);
    }
    if (codeFilterIndex >= 0) {
      this.codeFilterEntered.splice(codeFilterIndex, 1);
    }
  }

  countriesSelectionChanged($event: CountriesListsModel[]): void {
    this.selectedCountries = $event.map((value) => value.isoCode);
  }
}
