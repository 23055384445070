import { Component, OnInit } from '@angular/core';
import { AntiMemLeak } from '../../core/form-utils/anti-mem-leak/anti-mem-leak';

export enum DownloadViewType {
  CountryView = 'countryView',
  HouseholdView = 'householdView',
  DownloadList = 'downloadList',
}

@Component({
  selector: 'app-download-module',
  templateUrl: './download-module.component.html',
  styleUrls: ['./download-module.component.scss'],
})
// eslint-disable-next-line prettier/prettier
export class DownloadModuleComponent extends AntiMemLeak implements OnInit {
  eDownloadViewType = DownloadViewType;
  selectedDownloadViewType: DownloadViewType | undefined;

  ngOnInit(): void {
    this.selectedDownloadViewType = undefined;
  }

  selectView(view: DownloadViewType | undefined): void {
    this.selectedDownloadViewType = view;
  }
}
