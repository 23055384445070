import { IMapper } from '../../../models/i-mapper';
export class PresignedUrlMapper implements IMapper {
  presignedUrl = '';
  fillFromJson(json: any): void {
    this.presignedUrl = json.preSignedUrl;
  }
  fillToJson(): string {
    return '';
  }
}
